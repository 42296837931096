import React, { useEffect, useState } from "react";
import { useWindowSize } from "../../functions/functions";
import { IoIosArrowForward } from "react-icons/io";
import { List_API } from "../../services/userservices";
import { AiOutlinePlus, AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const ListTab = ({ lists, setActiveTab, setFromList }) => {
  const navigate = useNavigate();
  const size = useWindowSize();
  const [listsgrp, setListsgrp] = useState(lists);
  useEffect(() => {
    let ELpayload = {
      exhibitor_id: JSON.parse(localStorage?.getItem("exhibitor_id")),
    };

    List_API(ELpayload).then((res) => {
      if (res.data !== null && res.data !== "") {
        localStorage.setItem("Lists", JSON.stringify(res));
        setListsgrp(JSON.parse(JSON.stringify(res)))
      } else {
        localStorage.setItem("Lists", JSON.stringify([]));
      }
    });
  }, []);

  return (
    <>
      <div
        className="d-flex flex-column w-100 align-items-center justify-content-start bg-white py-3"
        style={{ height: size.height, overflowY: "auto" }}
      >
        <div
          className="d-flex align-items-center justify-content-center gap-2 btn btn-primary mb-2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/list", { state: { action: "add" } });
          }}
        >
          {t('contactsMenu.newList')}
          <AiOutlinePlus />
        </div>

        <div
          className="row d-flex w-100 px-3 justify-content-start"
          style={{ gap: "15px" }}
        >
          {listsgrp.length > 0 ? (
            listsgrp.map((list) => {
              return (
                <div
                  className="d-flex cols-lg-2 flex-column rounded-2 shadow px-3 py-3 border btn cursor-pointer"
                  id={`${list?.list_name}_${list?.list_id}`}
                  key={list?.list_id}
                  style={{ backgroundColor: list?.list_color }}
                  onClick={() => {
                    setFromList({ filter: true, listId: list?.list_id });
                    setActiveTab("contacts");
                  }}
                >
                  <div className="text-start" style={{ gap: "10px" }}>
                    <p className="mb-0 fw-semibold fs-9 text-white">
                      {list?.list_name}
                    </p>
                    <p className="mb-0 fw-normal fs-11 fst-italic text-light">
                      {list?.list_description}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0 fw-normal fs-11 fst-italic text-light">
                      {list?.no_of_contacts} contacts
                    </p>
                    <AiOutlineEdit
                      size={25}
                      className="text-light border m-2 p-1 rounded-circle btn btn-primary"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the parent div's onClick
                        navigate("/list", {
                          state: { action: "update", initialValues: list },
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="d-flex p-3">
              <p className="mb-0 fw-semibold fs-10">No lists created</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ListTab;
