import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft, FaPlus, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
//API SERVICES
import {
  EditLeads_API,
  DisplayLeads_API,
  CreateNewLead_API,
} from "../services/userservices";
import { Menu } from "../functions/menu";
import { useWindowSize } from "../functions/functions";
import { PhoneInput } from "react-international-phone";
import { t } from "i18next";

const Editleads = () => {
  const navigate = useNavigate();
  const size = useWindowSize();
  const refSuccessDiv = useRef();
  const refWarningDiv = useRef();

  const reffirst_name = useRef();
  const reflast_name = useRef();
  const refcompany = useRef();
  const refphone_no = useRef();
  const refemail_address = useRef();
  const refdesigination = useRef();
  let Editleads_data = JSON.parse(localStorage.getItem("EditLeads"));
  const [phoneNumbers, setPhoneNumbers] = useState([""]); // State to store phone numbers as an array
  const [emailAddresses, setEmailAddresses] = useState([""]); // State to store email addresses as an array
  console.log(phoneNumbers, "phoneNumbers");
  console.log(emailAddresses, "emailAddresses");
  const addPhoneNumberInput = () => {
    setPhoneNumbers([...phoneNumbers, ""]); // Add one more empty string to the phoneNumbers array
  };

  const addEmailAddressInput = () => {
    setEmailAddresses([...emailAddresses, ""]); // Add one more empty string to the emailAddresses array
  };

  const removePhoneNumberInput = (index) => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers.splice(index, 1); // Remove the phone number at the specified index
    setPhoneNumbers(updatedPhoneNumbers);
  };

  const removeEmailAddressInput = (index) => {
    const updatedEmailAddresses = [...emailAddresses];
    updatedEmailAddresses.splice(index, 1); // Remove the email address at the specified index
    setEmailAddresses(updatedEmailAddresses);
  };

  const handlePhoneNumberChange = (index, value) => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers[index] = value;
    setPhoneNumbers(updatedPhoneNumbers);
  };

  const handleEmailAddressChange = (index, value) => {
    const updatedEmailAddresses = [...emailAddresses];
    updatedEmailAddresses[index] = value;
    setEmailAddresses(updatedEmailAddresses);
  };
  const Goback_fn = () => {
    navigate(-1);
  };

  let TOAST_CONFIG = {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  useEffect(() => {
    Editleads_data = JSON.parse(localStorage.getItem("EditLeads"));
    reffirst_name.current.value = Editleads_data.data.first_name;
    reflast_name.current.value = Editleads_data.data.last_name;
    refcompany.current.value = Editleads_data.data.company;
    // refphone_no.current.value = Editleads_data.data.phone_no;
    setPhoneNumbers(Editleads_data.data.phone_no);
    // refemail_address.current.value = Editleads_data.data.email_address;
    setEmailAddresses(Editleads_data.data.email_address);
    refdesigination.current.value = Editleads_data.data.desigination;
  }, []);

  const AlertDisplay_fn = (type, message) => {
    console.log(type);
    if (type.localeCompare("success") == 0) {
      refSuccessDiv.current.style.display = "block";
      const timer = setTimeout(() => {
        refSuccessDiv.current.style.display = "none";
        clearTimeout(timer);
        navigate("/showleads");
      }, 3000);
    } else if (type.localeCompare("warning") == 0) {
      refWarningDiv.current.style.display = "block";
      const timer = setTimeout(() => {
        refWarningDiv.current.style.display = "none";
        clearTimeout(timer);
      }, 5000);
    } else if (type.localeCompare("email_phone_mandatory") == 0) {
      toast.warning(message, TOAST_CONFIG);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (phoneNumbers[0].length <= 4 && emailAddresses[0] == "") {
      AlertDisplay_fn(
        "email_phone_mandatory",
        "Either email or phone number is mandatory",
      );
    } else {
      //---------------------------------Duplicates Avoid-----------------------------------------//
      let _leadsdata = "",
        Duplicateflg = false;
      if (JSON.parse(localStorage.getItem("DisplayLeads")) != null) {
        _leadsdata = JSON.parse(localStorage.getItem("DisplayLeads"));
        // console.log(Editleads_data);
        // if (
        //   Editleads_data.refId.localeCompare(refemail_address.current.value) != 0
        // ) {
        //   Duplicateflg = _leadsdata.hasOwnProperty(
        //     refemail_address.current.value
        //   );
        // }
      }
      //---------------------------------Duplicates Avoid-----------------------------------------//

      //--------------------------------------------------Online Work---------------------------------------------------------//
      if (window.navigator.onLine) {
        if (Duplicateflg == false) {
          if (Editleads_data.editsystem.localeCompare("edit") == 0) {
            // let payload =
            //   `id=${JSON.parse(
            //     localStorage.getItem('exhibitor_id')
            //   )}&contact_id=` +
            //   Editleads_data.data.contact_id +
            //   '&first_name=' +
            //   reffirst_name.current.value +
            //   '&last_name=' +
            //   reflast_name.current.value +
            //   '&company=' +
            //   refcompany.current.value +
            //   '&phone_no=' +
            //   phoneNumbers +
            //   '&email_address=' +
            //   emailAddresses +
            //   '&country=India&desigination=' +
            //   refdesigination.current.value +
            //   '&status=1' +
            //   '&source=leads-mobileapp';
            const serialize = (form) => {
              // console.log(form);
              let requestArray = {};
              form.querySelectorAll("[name]").forEach((elem) => {
                // console.log(elem);
                requestArray[elem.name] = elem.value;
              });
              requestArray["country"] = "india";
              requestArray["status"] = "1";
              requestArray["key"] = "4";
              requestArray["source"] = "leads-mobileapp";
              requestArray["id"] = JSON.parse(
                localStorage.getItem("exhibitor_id"),
              );
              return requestArray;
            };
            let serialized = serialize(document.querySelector("#leadform"));
            serialized.email_address = emailAddresses;
            serialized.phone_no = phoneNumbers;
            serialized.contact_id = Editleads_data.data.contact_id;

            let payload = { user: [serialized] };
            CreateNewLead_API(payload).then((resp) => {
              // EditLeads_API(payload).then((res) => {
              if (Array.isArray(resp)) {
                resp.map(function (res, index) {
                  if (res.status.localeCompare("success") == 0) {
                    //------------------------------------------------Local storage-----------------------------------------------//
                    let _obj = "";
                    let keyv = res.data[0].email_address;
                    if (
                      JSON.parse(localStorage.getItem("DisplayLeads")) !=
                        null &&
                      Object.keys(
                        JSON.parse(localStorage.getItem("DisplayLeads")),
                      ).length != 0
                    ) {
                      _obj = JSON.parse(localStorage.getItem("DisplayLeads"));
                      delete _obj[Editleads_data.refId];

                      _obj[keyv] = {};
                      _obj[keyv].first_name = res.data[0].first_name;
                      _obj[keyv].last_name = res.data[0].last_name;
                      _obj[keyv].company = res.data[0].company;
                      _obj[keyv].phone_no = res.data[0].phone_no;
                      _obj[keyv].email_address = res.data[0].email_address;
                      _obj[keyv].desigination = res.data[0].desigination;

                      localStorage.setItem(
                        "DisplayLeads",
                        JSON.stringify(_obj),
                      );
                    } else {
                      _obj = JSON.parse(localStorage.getItem("InsertItems"));
                      delete _obj[Editleads_data.refId];

                      let keyv = res.data[0].email_address;
                      _obj[keyv] = {};
                      _obj[keyv].first_name = res.data[0].first_name;
                      _obj[keyv].last_name = res.data[0].last_name;
                      _obj[keyv].company = res.data[0].company;
                      _obj[keyv].phone_no = res.data[0].phone_no;
                      _obj[keyv].email_address = res.data[0].email_address;
                      _obj[keyv].desigination = res.data[0].desigination;

                      localStorage.setItem("InsertItems", JSON.stringify(_obj));
                    }

                    if (JSON.parse(localStorage.getItem("EditItems")) != null) {
                      let _Edititm = JSON.parse(
                        localStorage.getItem("EditItems"),
                      );
                      delete _Edititm[Editleads_data.refId];
                      localStorage.setItem(
                        "EditItems",
                        JSON.stringify(_Edititm),
                      );
                    }
                    //------------------------------------------------Local storage-----------------------------------------------//

                    //-------------------------------------------Update Display Leads-------------------------------------------//
                    let Dpayload = {
                      id: JSON.parse(localStorage.getItem("exhibitor_id")),
                    };

                    DisplayLeads_API(Dpayload).then((res) => {
                      if (res.status.localeCompare("success") == 0) {
                        let leads_data = {};
                        res.data.map((leads) => {
                          leads_data[leads.data.email_address] = leads.data;
                        });

                        localStorage.setItem(
                          "DisplayLeads",
                          JSON.stringify(leads_data),
                        );
                        document.querySelector("#leadform").reset();
                        AlertDisplay_fn("success");
                      } else if (res.status.localeCompare("error") == 0) {
                        document.querySelector("#leadform").reset();
                        AlertDisplay_fn("success");
                      }
                    });
                    //-------------------------------------------Update Display Leads-------------------------------------------//
                  } else {
                    // console.log(
                    //   'Some network problem araised. Please try again after few minutes.'
                    // );
                  }
                });
              }
            });
          } else if (Editleads_data.editsystem.localeCompare("new") == 0) {
            let _obj = JSON.parse(localStorage.getItem("InsertItems"));
            let _clone = _obj[Editleads_data.refId];

            _clone.first_name = reffirst_name.current.value;
            _clone.last_name = reflast_name.current.value;
            _clone.company = refcompany.current.value;
            _clone.phone_no = refphone_no.current.value;
            _clone.email_address = refemail_address.current.value;
            _clone.desigination = refdesigination.current.value;

            if (
              Editleads_data.refId.localeCompare(
                refemail_address.current.value,
              ) == 0
            ) {
              _obj[Editleads_data.refId] = _clone;
            } else {
              delete _obj[Editleads_data.refId];
              _obj[refemail_address.current.value] = _clone;
            }
            localStorage.setItem("InsertItems", JSON.stringify(_obj));
            document.querySelector("#leadform").reset();
            AlertDisplay_fn("success");
          }
        } else {
          AlertDisplay_fn("warning");
        }
      }
      //--------------------------------------------------Online Work---------------------------------------------------------//

      /*--------------------------------------------Offline Work-------------------------------------------------------*/
      if (window.navigator.onLine == false) {
        if (Duplicateflg == false) {
          if (Editleads_data.editsystem.localeCompare("new") == 0) {
            let _obj = JSON.parse(localStorage.getItem("InsertItems"));
            let _clone = _obj[Editleads_data.refId];

            _clone.first_name = reffirst_name.current.value;
            _clone.last_name = reflast_name.current.value;
            _clone.company = refcompany.current.value;
            _clone.phone_no = refphone_no.current.value;
            _clone.email_address = refemail_address.current.value;
            _clone.desigination = refdesigination.current.value;

            if (
              Editleads_data.refId.localeCompare(
                refemail_address.current.value,
              ) == 0
            ) {
              _obj[Editleads_data.refId] = _clone;
            } else {
              delete _obj[Editleads_data.refId];
              _obj[refemail_address.current.value] = _clone;
            }
            localStorage.setItem("InsertItems", JSON.stringify(_obj));
            document.querySelector("#leadform").reset();
            AlertDisplay_fn("success");
          } else if (Editleads_data.editsystem.localeCompare("edit") == 0) {
            let _obj = "";
            if (
              JSON.parse(localStorage.getItem("DisplayLeads")) != null &&
              Object.keys(JSON.parse(localStorage.getItem("DisplayLeads")))
                .length != 0
            ) {
              _obj = JSON.parse(localStorage.getItem("DisplayLeads"));

              _obj[Editleads_data.refId].first_name =
                reffirst_name.current.value;
              _obj[Editleads_data.refId].last_name = reflast_name.current.value;
              _obj[Editleads_data.refId].company = refcompany.current.value;
              _obj[Editleads_data.refId].phone_no = refphone_no.current.value;
              _obj[Editleads_data.refId].email_address =
                refemail_address.current.value;
              _obj[Editleads_data.refId].desigination =
                refdesigination.current.value;

              localStorage.setItem("DisplayLeads", JSON.stringify(_obj));
            } else {
              _obj = JSON.parse(localStorage.getItem("InsertItems"));

              _obj[Editleads_data.refId].first_name =
                reffirst_name.current.value;
              _obj[Editleads_data.refId].last_name = reflast_name.current.value;
              _obj[Editleads_data.refId].company = refcompany.current.value;
              _obj[Editleads_data.refId].phone_no = refphone_no.current.value;
              _obj[Editleads_data.refId].email_address =
                refemail_address.current.value;
              _obj[Editleads_data.refId].desigination =
                refdesigination.current.value;

              localStorage.setItem("InsertItems", JSON.stringify(_obj));
            }

            if (JSON.parse(localStorage.getItem("EditItems")) == null) {
              let _Edititm = {};
              _Edititm[Editleads_data.refId] = _obj[Editleads_data.refId];
              _Edititm[Editleads_data.refId]["edit_status"] = false;
              localStorage.setItem("EditItems", JSON.stringify(_Edititm));
            } else {
              let _Edititm = JSON.parse(localStorage.getItem("EditItems"));
              _Edititm[Editleads_data.refId] = _obj[Editleads_data.refId];
              _Edititm[Editleads_data.refId]["edit_status"] = false;
              localStorage.setItem("EditItems", JSON.stringify(_Edititm));
            }
            document.querySelector("#leadform").reset();
            AlertDisplay_fn("success");
          }
        } else {
          AlertDisplay_fn("warning");
        }
      }
      /*--------------------------------------------Offline Work-------------------------------------------------------*/
    }
  };

  return (
    <>
      {/*------------------------------------------Edit Leads---------------------------------------------------*/}
      <div
        className="d-flex flex-column p-0  mx-auto container-fluid min-vh-100 p-0 justify-content-between"
        style={{
          overflow: "hidden",
        }}
      >
        <div
          className="d-flex justify-content-between flex-column align-items-center "
          style={{ backgroundColor: "#5411dc" }}
        >
          <div
            className="d-flex w-100 justify-content-between align-items-center"
            style={{ color: "white", fontSize: "2em", padding: "1%" }}
          >
            <FaChevronLeft
              size={25}
              onClick={Goback_fn}
              style={{ cursor: "pointer" }}
            />
            <p className="m-0 fw-semibold fs-4">{t("contactsMenu.update")}</p>
            <p className="m-0 " style={{ width: "20px" }}></p>
          </div>

          <form
            id="leadform"
            className=" row row-cols-lg-2 d-flex flex-column justify-content-center align-items-center px-4 py-4 bg-white  w-100"
            style={{
              gap: "10px",
              overflowY: "auto",
            }}
            onSubmit={handleSubmit}
          >
            <div>
              <div
                className="alert alert-warning"
                ref={refWarningDiv}
                style={{ display: "none" }}
              >
                <strong>Email-ID already Exists!..</strong>
              </div>
              <div
                className="alert alert-success"
                ref={refSuccessDiv}
                style={{ display: "none" }}
              >
                <strong>Contacts updated successfully..</strong>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label w-100 text-start fw-semibold text-primary ">
              {t("contactsMenu.firstName")} *
              </label>
              <input
                type="text"
                className="form-control"
                ref={reffirst_name}
                name="first_name"
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label w-100 text-start fw-semibold text-primary ">{t("contactsMenu.lastName")}</label>
              <input
                type="text"
                className="form-control"
                ref={reflast_name}
                name="last_name"
              />
            </div>
            <div className="form-group">
              <label className="form-label w-100 text-start fw-semibold text-primary ">{t("contactsMenu.company")}</label>
              <input
                type="text"
                className="form-control"
                ref={refcompany}
                name="company"
              />
            </div>
           
            {phoneNumbers.map((phoneNumber, index) => (
              <div className="mb-3" key={`phone_${index}`}>
                <label className="form-label w-100 text-start fw-semibold text-primary">
                {t("contactsMenu.phone")}{index > 0 ? ` ${index + 1}` : ""}
                </label>
                <div className="d-flex">
                  <PhoneInput
                    disableDialCodePrefill
                    className="form-control"
                    inputStyle={{
                      border: "none",
                    }}
                    onChange={(value) => handlePhoneNumberChange(index, value)}
                    value={phoneNumber}
                  />
                  {index === phoneNumbers.length - 1 && index <= 1 ? (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={addPhoneNumberInput}
                    >
                      <FaPlus />{" "}
                      {/* Plus icon for adding more phone number inputs */}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => removePhoneNumberInput(index)}
                    >
                      <FaTrash />{" "}
                      {/* Plus icon for adding more phone number inputs */}
                    </button>
                  )}
                </div>
              </div>
            ))}

            {/* Email Address Inputs */}
            {emailAddresses.map((emailAddress, index) => (
              <div className="mb-3" key={`email_${index}`}>
                <label className="form-label w-100 text-start fw-semibold text-primary">
                {t("contactsMenu.email")}{index > 0 ? ` ${index + 1}` : ""}
                </label>
                <div className="d-flex">
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) =>
                      handleEmailAddressChange(index, e.target.value)
                    }
                    value={emailAddress}
                  />
                  {index === emailAddresses.length - 1 && index <= 1 ? (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={addEmailAddressInput}
                    >
                      <FaPlus /> {/* Plus icon for adding more email inputs */}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => removeEmailAddressInput(index)}
                    >
                      <FaTrash /> {/* Plus icon for adding more email inputs */}
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div className="form-group">
              <label className="form-label w-100 text-start fw-semibold text-primary">
              {t("contactsMenu.designation")}
              </label>
              <input
                type="text"
                className="form-control"
                name="desigination"
                ref={refdesigination}
              />
            </div>
            <br />
            <button type="submit" className="btn btn-primary">
            {t("contactsMenu.update")}
            </button>
          </form>
          <ToastContainer />
        </div>
        <Menu />
      </div>

      {/*------------------------------------------Edit Leads---------------------------------------------------*/}
    </>
  );
};
export default Editleads;
