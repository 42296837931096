import React, { useState } from "react";
import { FaBriefcase, FaBuilding, FaCalendarAlt, FaCheck, FaChevronLeft, FaEnvelope, FaFileCsv, FaGlobeAmericas, FaLink, FaMailBulk, FaPlus, FaShare, FaUserFriends } from "react-icons/fa";
import { Toast } from 'react-bootstrap';
import { Menu } from "../functions/menu";
import { useNavigate } from "react-router-dom";
import { BiCopy, BiDownload, BiMessage, BiQuestionMark, BiShare, BiShareAlt, BiStar } from "react-icons/bi";
import { FaPlusCircle } from 'react-icons/fa';
import { Modal, Button, Form, ListGroup, OverlayTrigger, Container, Row, Col, ToastContainer } from 'react-bootstrap';

import { FaRegCalendarAlt, FaTimes } from 'react-icons/fa';
import { FaCheckCircle, FaRedo, FaPhoneSlash, FaUsers, FaShareAlt } from "react-icons/fa";
import
{

  Popover,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  CircularProgress,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Card } from "react-bootstrap";
import { IoMdOptions } from "react-icons/io";
import FullScreenModal from "../functions/FullScreenModal";
import ShareEmail from "../screens/Emails/components/ShareEmail";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import zIndex from "@mui/material/styles/zIndex";
function GroupDetails ()
{
  const [ activeTab, setActiveTab ] = useState( "info" );

  // Dummy data
  const groupInfo = {
    name: "Study Group",
    type: "Private",
    description:
      "This is a group for discussing study materials and sharing knowledge.",
    createdDate: "January 1, 2022",
    url: "https://example.com/group",
    membersCount: 12,
    prompt: 12,
    question: 12,
  };

  const links = [
    {
      id: 1,
      url: "https://example.com/resource1",
      description: "https://meet.google.com/kkm-vsdfwyy-bxf",
    },
    {
      id: 2,
      url: "https://example.com/resource2",
      description: "https://meet.google.com/kkmsdf-vwyy-bxf",
    },
    {
      id: 3,
      url: "https://example.com/resource3",
      description: "https://meet.google.com/dfgkkm-vwyy-bxf",
    },
  ];

  const members = [
    { id: 1, name: "Alice Smith", role: "Joined", star: false },
    { id: 2, name: "Bob Johnson", role: "Invited", star: true },
    { id: 3, name: "Charlie Brown", role: "Declined", star: false },
    { id: 4, name: "David Williams", role: "Invited", star: false },
    { id: 5, name: "Eva Davis", role: "Joined", star: true },
    { id: 6, name: "Frank Miller", role: "Joined", star: true },
    { id: 7, name: "Grace Wilson", role: "Joined", star: false },
    { id: 8, name: "Hank Martinez", role: "Joined", star: true },
    { id: 9, name: "Ivy Anderson", role: "Joined", star: true },
    { id: 10, name: "Jack Thomas", role: "Invited", star: true },
    { id: 11, name: "Kara Lee", role: "Joined", star: true },
    { id: 12, name: "Leo White", role: "Joined", star: true },
    { id: 13, name: "Mia Harris", role: "Invited", star: true },
    { id: 14, name: "Noah Clark", role: "Declined", star: true },

  ];

  const activityLog = [
    { id: 1, action: "Joined Group", user: "Alice Smith", date: "2024-05-01" },
    { id: 2, action: "RSVP Accepted", user: "Bob Johnson", date: "2024-05-05" },
    { id: 3, action: "Left Group", user: "Charlie Brown", date: "2024-05-10" },
  ];

  const handleTabClick = ( tab ) =>
  {
    setActiveTab( tab );
  };

  const navigate = useNavigate();
  const [ showModal, setShowModal ] = useState( false );
  // const [selectedMethod, setSelectedMethod] = useState(null);

  const handleShow = () => setShowModal( true );
  const handleClose = () =>
  {
    setShowModal( false );
    setSelectedMethod( null );
  };

  const handleMethodSelect = ( method ) => setSelectedMethod( method );
  const [ selectedMethod, setSelectedMethod ] = useState( null );
  const [ checkedItems, setCheckedItems ] = useState( {} );
  const [ selectedCount, setSelectedCount ] = useState( 0 );
  const handleCheckboxChange = ( id, event ) =>
  {
    const newCheckedItems = { ...checkedItems, [ id ]: !checkedItems[ id ] };
    setCheckedItems( newCheckedItems );
    const newSelectedCount = Object.values( newCheckedItems ).filter( Boolean ).length;
    setSelectedCount( newSelectedCount );

    if ( newSelectedCount > 0 )
    {
      setPopoverAnchorEl( event.currentTarget );
      setPopoverOpen2( true );
    } else
    {
      setPopoverOpen2( false );
      setPopoverAnchorEl( null );
    }
  };

  // const selectedCount = Object.values( checkedItems ).filter( Boolean ).length;

  // const popover = (
  //   <Popover id="popover-positioned-bottom" style={{ width: '100vw !important',maxWidth:"none !important", left: 0, right: 0 }}>
  //     <Popover.Body style={{ width: '100%' }}>
  //       {selectedCount} selected
  //       <div className="d-flex flex-wrap">
  //         <Chip label=" Create Contacts" variant="outlined" className="m-1" />
  //         <Chip label="Enrich" variant="outlined" className="m-1" />
  //         <Chip label="Send RSVP" variant="outlined" className="m-1" />
  //         <Chip label="Shortlist" variant="outlined" className="m-1" />
  //         <Chip label="Remove" variant="outlined" className="m-1" />
  //         <Chip label="Meeting Status" variant="outlined" className="m-1" />
  //         <Chip label="share my date Status" variant="outlined" className="m-1" />
  //         <Chip label="Share my Calendar" variant="outlined" className="m-1" />
  //       </div>
  //     </Popover.Body>
  //   </Popover>
  // );
  const [ showModal1, setShowModal1 ] = useState( false );
  const [ selectedAction, setSelectedAction ] = useState( null );

  const handleShowModal = ( action ) =>
  {
    setSelectedAction( action );
    setShowModal1( true );
  };

  const handleCloseModal = () =>
  {
    setShowModal1( false );
    setSelectedAction( null );
  };

  const renderModalContent = () =>
  {
    switch ( selectedAction )
    {
      case "addLink":
        return (
          <Form.Group>
            <Form.Label>Enter Link URL</Form.Label>
            <Form.Control type="text" placeholder="Enter URL" />
          </Form.Group>
        );
      case "addPrompt":
        return (
          <Form.Group>
            <Form.Label>Enter Prompt</Form.Label>
            <Form.Control type="text" placeholder="Enter Prompt" />
          </Form.Group>
        );
      case "addQuestion":
        return (
          <Form.Group>
            <Form.Label>Enter Question</Form.Label>
            <Form.Control type="text" placeholder="Enter Question" />
          </Form.Group>
        );
      default:
        return null;
    }
  };
  const [membersin, setMembers] = useState([{ name: '', email: '' }]);

  const handleAddMember = () => {
    setMembers([...membersin, { name: '', email: '' }]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedMembers = [...membersin];
    updatedMembers[index][field] = value;
    setMembers(updatedMembers);
  };
  const renderForm = () =>
  {
    switch ( selectedMethod )
    {
      case "contacts":
        return (
          <Form.Group className="mb-3">
            <Form.Label>Add members from my contacts as a Guest</Form.Label>
            <Form.Control type="text" placeholder="Search contacts" />
            <div className="list-group h-100vh mt-2">
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">Mark</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">John</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">Roy</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">Devin</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>

            </div>
            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit" className="mt-2">
                Select
              </Button>
            </div>
          </Form.Group>
        );
      case "nameEmail":
        return (
          <>
          <Form>
          {membersin.map((member, index) => (
            <div key={index} className="mb-3 row">
           
            <Form.Label className="text-primary">Add Name Email as a Guest {index != "0" && index +1}</Form.Label>
<div className="row">
              <Form.Control
              
                type="text"
                className="mb-3 col-4"
                placeholder="Enter name"
                value={member.name}
                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
              />
              <Form.Control
                type="email"
                className="mb-3 col-4"
                placeholder="Enter email"
                value={member.email}
                onChange={(e) => handleInputChange(index, 'email', e.target.value)}
              />
              </div>
            </div>
          ))}
          <Button variant="outline-primary" onClick={handleAddMember}>
            <FaPlusCircle /> Add Member
          </Button>
         
        </Form>
        <div className=" d-flex justify-content-end">
         <Button variant="primary">
         Submit
      </Button>
      </div>
</>
        );
      case "publicEvent":
        return (
          <Form.Group className="mb-3">
           
{sliderClick == false &&  <> <Form.Label>
              Add members from Public Event Attendee to the group
            </Form.Label>
          
            <Form.Control type="text" placeholder="Enter event details" />
            {/* <Swiper
              slidesPerView={ 2 } // Show 3 slides per view by default
              spaceBetween={ 30 }
              modules={ [ Pagination ] }
              className="mySwiper"
              pagination={ {
                clickable: true,
              } }
              breakpoints={ {
                // Adjust slidesPerView for different screen widths
                576: { slidesPerView: 2 }, // Mobile screens (up to 576px)
                768: { slidesPerView: 3 }, // Tablets (up to 768px)
              } }
            > */}
               <div className="list-group">
            <div
              className="list-group-item list-group-item-action d-flex align-items-center mt-2"
              onClick={ () => setSliderClick(true) }
            >
              <img
                src="https://via.placeholder.com/50"
                alt="Group"
                className="rounded-circle me-3"
              />
              <div className="flex-grow-1">
                <div className="fw-bold">Group Name</div>
                <div className="text-muted">
                  213 members
                </div>
              </div>
              <button className="btn btn-primary" onClick={ () => setSliderClick(true) }>
                    View
                  </button>
            </div>
            <div
              className="list-group-item list-group-item-action d-flex align-items-center mt-2"
              onClick={ () => setSliderClick(true) }
            >
              <img
                src="https://via.placeholder.com/50"
                alt="Group"
                className="rounded-circle me-3"
              />
              <div className="flex-grow-1">
                <div className="fw-bold">Group Name</div>
                <div className="text-muted">
                  213 members
                </div>
              </div>
              <button className="btn btn-primary" onClick={ () => setSliderClick(true) }>
                    View
                  </button>
            </div>
            <div
              className="list-group-item list-group-item-action d-flex align-items-center mt-2"
              onClick={ () => setSliderClick(true) }
            >
              <img
                src="https://via.placeholder.com/50"
                alt="Group"
                className="rounded-circle me-3"
              />
              <div className="flex-grow-1">
                <div className="fw-bold">Group Name</div>
                <div className="text-muted">
                  213 members
                </div>
              </div>
              <button className="btn btn-primary" onClick={ () => setSliderClick(true) }>
                    View
                  </button>
            </div>
            <div
              className="list-group-item list-group-item-action d-flex align-items-center mt-2"
              onClick={ () => setSliderClick(true) }
            >
              <img
                src="https://via.placeholder.com/50"
                alt="Group"
                className="rounded-circle me-3"
              />
              <div className="flex-grow-1">
                <div className="fw-bold">Group Name</div>
                <div className="text-muted">
                  213 members
                </div>
              </div>
              <button className="btn btn-primary" onClick={ () => setSliderClick(true) }>
                    View
                  </button>
            </div>
            <div
              className="list-group-item list-group-item-action d-flex align-items-center mt-2"
              onClick={ () => setSliderClick(true) }
            >
              <img
                src="https://via.placeholder.com/50"
                alt="Group"
                className="rounded-circle me-3"
              />
              <div className="flex-grow-1">
                <div className="fw-bold">Group Name</div>
                <div className="text-muted">
                  213 members
                </div>
              </div>
              <button className="btn btn-primary" onClick={ () => setSliderClick(true) }>
                    View
                  </button>
            </div>
          
          </div>
          

            {/* </Swiper> */}
            </>}
            {sliderClick == true && <>
              <div className="border rounded shadow m-2 mb-5 py-5"> <div
                className="d-flex  flex-column list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle "
                />
                <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
                  <div className="fw-bold">company group</div>
                  <div className="text-muted">
                    213 members
                  </div>
                 
                </div>

              </div></div>
              <Form.Control type="text" placeholder="Search contact from group" />
            <div className="list-group h-100vh mt-2">
            <div className="list-group h-100vh mt-2">
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">Mark</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">John</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">Roy</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">Devin</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>

            </div>
              

            </div>
             <div className="d-flex justify-content-end">
             <Button variant="primary" type="submit" className="mt-2">
               Select
             </Button>
           </div></>}
           
          </Form.Group>
        );
      case "csv":
        return (
          <Form.Group className="mb-3">
            {sliderClick == false && <>
            <Form.Label>Add members from CSV: Bulk import</Form.Label>
            <Form.Text className="text-muted">
              (In this case, check if the contact already exists, ignore the
              contacts already created, just create that particular contact as a
              group member)
            </Form.Text>
            <Form.Control type="file" />
            <div className="d-flex justify-content-end">
             <Button variant="primary" type="submit" className="mt-2" onClick={ () => setSliderClick(true) }>
               View 
             </Button>
           </div>
            </>}
            {sliderClick == true && <>
              <div className="border rounded shadow m-2 mb-5 py-5"> <div
                className="d-flex  flex-column list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
               
                <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
                  <div className="fw-bold">Contact_data.csv</div>
                  <div className="text-muted">
                    213 contacts
                  </div>
                 
                </div>

              </div></div>
              <Form.Control type="text" placeholder="Search contact from csv" />
            <div className="list-group h-100vh mt-2">
            <div className="list-group h-100vh mt-2">
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">Mark</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">John</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">Roy</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>
              <div
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={ () => navigate( "/groupdetails" ) }
              >
                <input
                  class="form-check-input me-2 p-2" type="checkbox"

                />
                <img
                  src="https://via.placeholder.com/50"
                  alt="Group"
                  className="rounded-circle me-3"
                />
                <div className="flex-grow-1">
                  <div className="fw-bold">Devin</div>
                  <div className="text-muted">
                    Developer
                  </div>
                  <div className="text-muted">
                    Mercuryminds
                  </div>
                </div>

              </div>

            </div>
             

            </div>
             <div className="d-flex justify-content-end">
             <Button variant="primary" type="submit" className="mt-2">
               Select
             </Button>
           </div></>}
          </Form.Group>
        );
      default:
        return null;
    }
  };
  const meetings = [
    { id: 1, type: "First Meeting", status: "Scheduled", date: "2024-06-01" },
    {
      id: 2,
      type: "Follow Up Meeting",
      status: "Rescheduled",
      date: "2024-06-08",
    },
    { id: 3, type: "First Meeting", status: "Cancelled", date: "2024-06-15" },
  ];

  const getStatusBadge = ( status ) =>
  {
    switch ( status )
    {
      case "Scheduled":
        return (
          <span className="badge bg-success">
            <FaCheckCircle /> { status }
          </span>
        );
      case "Rescheduled":
        return (
          <span className="badge bg-warning">
            <FaRedo /> { status }
          </span>
        );
      case "Cancelled":
        return (
          <span className="badge bg-danger">
            <FaPhoneSlash /> { status }
          </span>
        );
      default:
        return <span className="badge bg-secondary">{ status }</span>;
    }
  };
  const [ isPopoverOpen2, setPopoverOpen2 ] = useState( false );
  const [ popoverAnchorEl, setPopoverAnchorEl ] = useState( null );
  const [ selectedMembers, setSelectedMembers ] = useState( [] );
  const [ action, setAction ] = useState( null );
  const [ openDialog, setOpenDialog ] = useState( false );
  const [ sliderClick, setSliderClick ] = useState( false );

  const handlePopoverOpen = ( event ) =>
  {
    setPopoverAnchorEl( event.currentTarget );
  };

  const handlePopoverClose = () =>
  {
    setPopoverAnchorEl( null );
    setPopoverOpen2( false );
  };

  const isPopoverOpen = Boolean( popoverAnchorEl );
  const handleChipClick = ( event, action ) =>
  {
    setPopoverAnchorEl( event.currentTarget );
    setAction( action );
    setOpenDialog( true );
    handlePopoverClose();
  };

  const handleDialogClose = () =>
  {
    setOpenDialog( false );
    setAction( null );
  };
  const [ showToast, setShowToast ] = useState( false );
  const handleToast = ( key ) =>
  {
    handlePopoverClose();
    setAction( key );
    setShowToast( true );
    return (
      <Toast
        show={ showToast }
        onClose={ () => setShowToast( false ) }
        delay={ 3000 } // Auto-hide after 3 seconds
        autohide
        style={ {
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
        } }
      >
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
          <small>Just now</small>
        </Toast.Header>
        <Toast.Body>
          The selected contacts have been { key } from the group members list.
        </Toast.Body>
      </Toast>
    );
  };
  const [ meetingStatus, setMeetingStatus ] = useState( '' );
  const [ outcome, setOutcome ] = useState( '' );
  const [ relationshipBuilder, setRelationshipBuilder ] = useState( false );
  const [ nextMeeting, setNextMeeting ] = useState( '' );
  const [ reasonCancelled, setReasonCancelled ] = useState( '' );
  const [ rescheduledDate, setRescheduledDate ] = useState( '' );

  const handleMeetingStatusChange = ( event ) =>
  {
    setMeetingStatus( event.target.value );
  };

  const handleOutcomeChange = ( event ) =>
  {
    setOutcome( event.target.value );
  };

  const handleRelationshipBuilderChange = ( event ) =>
  {
    setRelationshipBuilder( event.target.checked );
  };

  const handleNextMeetingChange = ( event ) =>
  {
    setNextMeeting( event.target.value );
  };

  const handleReasonCancelledChange = ( event ) =>
  {
    setReasonCancelled( event.target.value );
  };

  const handleRescheduledDateChange = ( event ) =>
  {
    setRescheduledDate( event.target.value );
  };
  const renderDialogContent = () =>
  {
    // if(action == "Remove"){ setShowToast(true)}
    switch ( action )
    {
      case 'Create Contacts':
        return (
          <div>
            <p>{ selectedCount } selected members added to contacts</p>
            {/* <CircularProgress /> */ }
            {/* Add more details and handling */ }
          </div>
        );
      case "Enrich":
        return (
          <div className="container my-3">
            <div className="text-center">
              <h2>We will enrich { selectedCount } contacts</h2>
              <div className="my-3">
                <FaEnvelope className="mx-2" /> Email
                <FaBuilding className="mx-2" /> Company
                <FaGlobeAmericas className="mx-2" /> URLs
                <FaBriefcase className="mx-2" /> Job title
              </div>
              <p><span className="fw-bold">Boost your results:</span> Adding a website or LinkedIn link to a contact boosts enrichment results</p>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <button type="button" className="btn btn-link">Learn more</button>
              <div> <button type="button" className="btn btn-secondary me-2">Cancel</button>
                <button type="button" className="btn btn-primary">Start enrichment</button></div>

            </div>
          </div>
        );
      case "Send RSVP":
        return (

          <ShareEmail
            dependencies={ {
              state: "BulkEmailState",
              setState: "BulkEmailDispatch",
              data: {

              },
              share_email_template: "BulkEmailState?.share_email?.template",
              exhibitor_id: JSON.parse(
                localStorage.getItem( "exhibitor_id" )
              ),
              bulkEmail: "",
            } }
          />

        );
      case "Shortlist":
        return (
          <div>
            {/* <p>{ selectedCount } members selected</p> */ }
            <p>The { selectedCount } contacts are marked as shortlisted</p>
            {/* Add more details and handling */ }
          </div>
        );
      case 'Remove':

        return (


          <div>
            {/* <p>{ selectedCount } members selected</p> */ }
            <p>The { selectedCount } contacts have been removed from the group members list.</p>
            {/* Add more details and handling */ }
          </div>
        );
      case "Meeting Status":
        return (
          <div className="container-fluid p-4">
            <form>
              <div className="form-group row mb-3">
                <label className="col-sm-3 col-form-label" style={ { fontSize: '1.2em', fontWeight: 'bold' } }>
                  <FaRegCalendarAlt className="me-2" />
                  Meeting Status
                </label>
                <div className="col-sm-9">
                  <select className="form-control" value={ meetingStatus } onChange={ handleMeetingStatusChange } style={ { fontSize: '1em', padding: '0.5em' } }>
                    <option value="">Select</option>
                    <option value="done">Meeting Done</option>
                    <option value="cancelled">Meeting Cancelled</option>
                    <option value="rescheduled">Meeting Rescheduled</option>
                  </select>
                </div>
              </div>

              { meetingStatus === 'done' && (
                <>
                  <div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label" style={ { fontSize: '1.2em', fontWeight: 'bold' } }>
                      <FaCheck className="me-2" />
                      Outcome of the Meeting
                    </label>
                    <div className="col-sm-9">
                      <select className="form-control" value={ outcome } onChange={ handleOutcomeChange } style={ { fontSize: '1em', padding: '0.5em' } }>
                        <option value="">Select</option>
                        <option value="introduction">Introduction</option>
                        <option value="detailed">Detailed</option>
                        <option value="not_worth">Not Worth</option>
                        <option value="messed_up">Messed Up</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <div className="col-sm-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={ relationshipBuilder }
                          onChange={ handleRelationshipBuilderChange }
                          id="relationshipBuilder"
                        />
                        <label className="form-check-label" htmlFor="relationshipBuilder" style={ { fontSize: '1.2em', fontWeight: 'bold' } }>
                          Add to Relationship Builder?
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-9">
                      { ( relationshipBuilder || true ) && (
                        <select className="form-control" value={ nextMeeting } onChange={ handleNextMeetingChange } style={ { fontSize: '1em', padding: '0.5em' } }>
                          <option value="">Select</option>
                          <option value="15_mins">15 mins touch up</option>
                          <option value="30_mins">30 mins brief</option>
                          <option value="1_hour">1 hour discussion</option>
                        </select>
                      ) }
                    </div>
                  </div>
                </>
              ) }

              { meetingStatus === 'cancelled' && (
                <div className="form-group row mb-3">
                  <label className="col-sm-3 col-form-label" style={ { fontSize: '1.2em', fontWeight: 'bold' } }>
                    <FaTimes className="me-2" />
                    Reason for Cancellation
                  </label>
                  <div className="col-sm-9">
                    <select className="form-control" value={ reasonCancelled } onChange={ handleReasonCancelledChange } style={ { fontSize: '1em', padding: '0.5em' } }>
                      <option value="">Select</option>
                      <option value="not_interested">Not interested (Declined)</option>
                      <option value="interested_but_not_available">Interested But Not available</option>
                      <option value="revisit_later">Revisit Later</option>
                      <option value="busy">Busy</option>
                      <option value="unplanned">Unplanned</option>
                      <option value="scheduling_conflicts">Scheduling conflicts</option>
                    </select>
                  </div>
                </div>
              ) }

              { meetingStatus === 'rescheduled' && (
                <div className="form-group row mb-3">
                  <label className="col-sm-3 col-form-label" style={ { fontSize: '1.2em', fontWeight: 'bold' } }>
                    <FaRedo className="me-2" />
                    Next Meeting Date
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="date"
                      value={ rescheduledDate }
                      onChange={ handleRescheduledDateChange }
                      style={ { fontSize: '1em', padding: '0.5em' } }
                    />
                  </div>
                </div>
              ) }
            </form>
          </div>
        );
      case "Share my Date Status":
        return (
          <div>
            <p>Share my Date Status</p>
            {/* Add more details and handling */ }
          </div>
        );
      case "Share my Calendar":
        return (
          <div>
            <p>Share my Calendar</p>
            {/* Add more details and handling */ }
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="position-relative ">
      <ToastContainer
        className="p-3"
        position="top-center"
        style={ { zIndex: 1 } }
      >
        <Toast
          show={ showToast }
          onClose={ () => setShowToast( false ) }
          delay={ 2500 } // Auto-hide after 3 seconds
          autohide
          class="position-absolute top-0 start-50 translate-middle-x"
        // style={{
        //   position: 'fixed',

        //   top:"50%",
        //   left: "50%",
        //   transform: "translate(-50%, -50%)",
        //   zIndex:1
        // }}
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body>
            { renderDialogContent() }
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="d-flex w-100 justify-content-between align-items-center bg-primary text-white p-3 rounded">
        <FaChevronLeft
          size={ 25 }
          onClick={ () => navigate( "/dashboard" ) }
          style={ { cursor: "pointer" } }
        />
        <p className="m-0 fw-semibold fs-4">Group</p>
        <p></p>
      </div>
      <div className="mb-5">
        <div className="text-center p-4 mb-5">
          <img
            src="https://via.placeholder.com/150"
            alt="Group"
            className="img-fluid rounded-circle mb-3"
            style={ { width: "150px", height: "150px" } }
          />
          <h1 className="h4">{ groupInfo.name }</h1>
        </div>

        {/* Tabs */ }
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          { [
            "info",
            "members",
            "links",
            "meetings",
            "activity Log",
            "share",
          ].map( ( tab ) => (
            <li className="nav-item" role="presentation" key={ tab }>
              <button
                className={ `nav-link ${ activeTab === tab ? "active" : "" }` }
                id={ `${ tab }-tab` }
                type="button"
                role="tab"
                aria-controls={ tab }
                aria-selected={ activeTab === tab }
                onClick={ () => handleTabClick( tab ) }
              >
                { tab.charAt( 0 ).toUpperCase() + tab.slice( 1 ) }
              </button>
            </li>
          ) ) }
        </ul>

        <div className="tab-content mb-5 tab-pane-content" id="myTabContent" style={ { minHeight: "46vh" } }>
          <div
            className={ `tab-pane fade ${ activeTab === "info" ? "show active" : "" }` }
            id="info"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <div className="p-3">
              <h6 className="text-primary">Group Name</h6>
              <p>{ groupInfo.name }</p>
              <h6 className="text-primary">Group Visibility</h6>
              <p>{ groupInfo.type }</p>
              <h6 className="text-primary">Group Type</h6>
              <p>Event (People met at event)</p>
              <h6 className="text-primary">Description</h6>
              <p>{ groupInfo.description }</p>
              <h6 className="text-primary">Created Date</h6>
              <p>{ groupInfo.createdDate }</p>
              <h6 className="text-primary">Group URL</h6>
              <p>{ groupInfo.url }</p>
              <h6 className="text-primary">Number of Members</h6>
              <p>{ groupInfo.membersCount }</p>
              <h6 className="text-primary">what can we help with</h6>
              <p>learning technology</p>
              <h6 className="text-primary">Areas we can help with </h6>
              <p>new technology</p>
              {/* <button className="btn btn-primary">Share <FaShare /></button> */ }
            </div>
          </div>

          <div
            className={ `tab-pane fade ${ activeTab === "members" ? "show active" : "" }` }
            id="members"
            role="tabpanel"
            aria-labelledby="members-tab"
          >
            <div className="p-3 mb-5">
              <div className="d-flex justify-content-end sticky-top bg-white ">
                <div>  { selectedCount > 0 && (
                  <div>
                    {/* <button
                      className="btn btn-secondary m-2"
                      onClick={handlePopoverOpen}
                    >
                      <IoMdOptions /> {selectedCount} selected
                    </button> */}
                    {/* <span onClick={handlePopoverOpen}>{selectedCount} selected</span> */ }
                    <Container>
                      {/* <Popover
                        open={isPopoverOpen}
                        anchorEl={popoverAnchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        PaperProps={{
                          style: { width: "100vw", maxWidth: "500px" },
                        }}
                      >
                        <p className="mb-0 ms-2 mt-2">{ selectedCount } selected</p>
                        <div className="p-2 d-flex flex-wrap">
                          
                          <Chip label="Create Contacts" variant="outlined" className="m-1" onClick={() => handleToast("Create Contacts")} />
                          <Chip label="Enrich" variant="outlined" className="m-1" onClick={ ( e ) => handleChipClick( e, 'Enrich' ) } />
                          <Chip label="Send RSVP" variant="outlined" className="m-1" onClick={ ( e ) => handleChipClick( e, 'Send RSVP' ) } />
                          <Chip label="Shortlist" variant="outlined" className="m-1" onClick={() => handleToast("Shortlist")}/>
                          <Chip label="Remove" variant="outlined" className="m-1"  onClick={() => handleToast("Remove")}/>
                          <Chip label="Meeting Status" variant="outlined" className="m-1" onClick={ ( e ) => handleChipClick( e, 'Meeting Status' ) } />
                          <Chip label="Share my Date Status" variant="outlined" className="m-1" onClick={ (  ) => navigate("/sharedate") } />
                          <Chip label="Share my Calendar" variant="outlined" className="m-1" onClick={ ( e ) => navigate("/sharecalander") } />
                        </div>
                      </Popover> */}
                      <div>



                      </div>
                      {/* <ToastContainer
          className="p-3"
          position="top-center"
          style={{ zIndex: 1 }}
        > */}

                      {/* </ToastContainer> */ }
                      <Dialog open={ openDialog } onClose={ handleDialogClose }>

                        <DialogContent>{ renderDialogContent() }</DialogContent>

                      </Dialog>
                    </Container>
                  </div>
                ) }</div>

                <button
                  className="btn btn-outline-primary m-2"
                  onClick={ handleShow }
                >
                  <FaPlusCircle /> Add Members
                </button>

              </div>
              <div className="w-100" style={ { position: 'relative', width: '100vw', maxWidth: 'none' } }>
                <ul className="list-group list-group-flush mb-3">
                  { members.map( ( member ) => (
                     <div className="list-group h-100vh mt-2">
                     <div
                       className="list-group-item  d-flex align-items-center"
                       onClick={ ( event ) => handleCheckboxChange( member.id, event ) }
                     >
                        <input
                          class="form-check-input me-2 p-2" type="checkbox"
                          checked={ !!checkedItems[ member.id ] }
                          onChange={ ( event ) => handleCheckboxChange( member.id, event ) }
                        />
                       <img
                         src="https://via.placeholder.com/50"
                         alt="Group"
                         className="rounded-circle me-3"
                       />
                       <div class="row align-itens-center w-100">
  <div class="col-sm"> <div className="fw-bold">{ member.name } { member.star == true && <span className="badge bg-warning rounded-pill"> <BiStar /> </span> }</div>
                         <div className="text-muted">
                         Developer
                         </div>
                         <div className="">Mercuryminds Ltd</div></div>
  <div class="col-sm align-content-end">  <div><small className="">raviraj@yopmail.com</small></div>
                         { " " }
                         <div> <small className="text-primary">+91-87667676767</small>{ " " }</div></div>
  <div class="col-sm-2 text-end "><span className="badge bg-primary rounded-pill">{ member.role }</span></div>
</div>
                       {/* <div className="flex-grow-1">
                         <div className="fw-bold">{ member.name } { member.star == true && <span className="badge bg-warning rounded-pill"> <BiStar /> </span> }</div>
                         <div className="text-muted">
                         Developer
                         </div>
                         <div className="">Mercuryminds Ltd</div>
                         <div><small className="">raviraj@yopmail.com</small></div>
                         { " " }
                         <div> <small className="text-primary">+91-87667676767</small>{ " " }</div>
                       </div> */}
                       {/* <span className="badge bg-primary rounded-pill">{ member.role }</span> */}
                     </div> 
                     
                     </div>
                 
                  ) ) }
                </ul>
                 
              </div>
              <div style={ { maxWidth: "768px" } } className="d-flex flex-column justify-content-between">
                <Modal show={ showModal } onHide={ handleClose } fullscreen centered className="d-flex flex-column justify-content-center custom-modal">

                  <div className="h-100 w-100 d-flex flex-column justify-content-between" style={ { maxWidth: "768px" } }>
                    <div
                      className="d-flex w-100 justify-content-between align-items-center"
                      style={ {
                        color: "white",
                        fontSize: "2em",
                        padding: "1%",
                        backgroundColor: "#5411dc",

                      } }
                    >
                      <FaChevronLeft
                        size={ 25 }
                        onClick={ handleClose }
                        style={ { cursor: "pointer" } }
                      />
                      <p className="m-0 fw-semibold fs-4">Add Member</p>
                      <p></p>

                    </div>
                    <Modal.Body >
                      { selectedMethod === null ? (
                        <div className="my-4">
                          <h4 className="mb-3">Select a method to add group members</h4>
                          <hr></hr>
                          <ListGroup>
                            <ListGroup.Item action onClick={ () => handleMethodSelect( "contacts" ) }>
                              <FaUserFriends className="mr-2" /> Add members from my contacts
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={ () => handleMethodSelect( "nameEmail" ) }>
                              <FaEnvelope className="mr-2" /> Add members by Name & Email
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={ () => (handleMethodSelect( "publicEvent" ),
                            setSliderClick(false)) }>
                              <FaCalendarAlt className="mr-2" /> Add members from Public Event Attendee list
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={ () => (handleMethodSelect( "csv" ),
                            setSliderClick(false)) }>
                              <FaFileCsv className="mr-2" /> Bulk import from CSV
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      ) : (
                        <Form>{ renderForm() }</Form>
                      ) }
                    </Modal.Body>
                    {/* <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save Changes
                  </Button>
                </Modal.Footer> */}
                    <Menu />
                  </div>
                </Modal>
              </div>
            </div>


          </div>

          <div
            className={ `tab-pane fade ${ activeTab === "links" ? "show active" : "" }` }
            id="links"
            role="tabpanel"
            aria-labelledby="links-tab"
          >
            <div className="p-3 mb-5">
              <div className="d-flex  justify-content-end align-items-center">
                <button className="btn btn-outline-primary m-2" onClick={ () => handleShowModal( 'addLink' ) }> <FaLink /> Add Links</button></div>
              <div className="d-flex flex-column justify-content-end align-items-center">

                <div class="container mt-5">
                  <ul class="list-group w-100">
                    <li class="list-group-item"><FaLink />{ " " } <a href="https://mob-app.veespaces.com/share" target="_blank">https://mob-app.veespaces.com/share</a></li>
                    <li class="list-group-item"><FaLink />{ " " } <a href="https://mob-app.veespaces.com/share" target="_blank">https://mob-app.veespaces.com/share</a></li>
                    <li class="list-group-item"><FaLink />{ " " } <a href="https://mob-app.veespaces.com/share" target="_blank">https://mob-app.veespaces.com/share</a></li>
                    <li class="list-group-item"><FaLink />{ " " } <a href="https://mob-app.veespaces.com/share" target="_blank">https://mob-app.veespaces.com/share</a></li>

                  </ul>
                </div>
              </div>

              <Modal show={ showModal1 } onHide={ handleCloseModal }>
                <Modal.Header closeButton>
                  <Modal.Title>
                    { selectedAction === "addLink"
                      ? "Add Link"
                      : selectedAction === "addPrompt"
                        ? "Add Prompt"
                        : "Add Question" }
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>{ renderModalContent() }</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={ handleCloseModal }>
                    Close
                  </Button>
                  <Button variant="primary" onClick={ handleCloseModal }>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>

          <div
            className={ `tab-pane fade ${ activeTab === "meetings" ? "show active" : "" }` }
            id="meetings"
            role="tabpanel"
            aria-labelledby="meetings-tab"
          >
            <div className="p-3 mb-5">
              <div className="card">
                <div className="card-header d-flex justify-content-end align-items-center">
                  {/* <h5>Schedule Meetings</h5> */ }
                  <FaPlusCircle className="text-primary" size={ 25 } />
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row text-center">
                      <div className="col-md-4 mb-3">
                        <div className="p-3 border rounded bg-light">
                          {/* <FaCheckCircle className="text-success mb-2" /> */ }
                          <div
                            className="p-3 border rounded-circle bg-success text-white d-flex flex-column align-items-center justify-content-center"
                            style={ {
                              width: "50px",
                              height: "50px",
                              margin: "0 auto",
                            } }
                          >
                            <h3 className="mb-0">36</h3>
                          </div>

                          <p className="text-muted"> Scheduled Meetings</p>
                          <p className="small text-muted">This month</p>
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="p-3 border rounded bg-light">
                          {/* <FaRedo className="text-warning  mb-2" /> */ }
                          <div
                            className="p-3 border rounded-circle bg-warning text-white d-flex flex-column align-items-center justify-content-center"
                            style={ {
                              width: "50px",
                              height: "50px",
                              margin: "0 auto",
                            } }
                          >
                            <h3 className="mb-0">27</h3>
                          </div>
                          <p className="text-muted"> Rescheduled Meetings</p>
                          <p className="small text-muted">This month</p>
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="p-3 border rounded bg-light">
                          {/* <FaPhoneSlash className="text-danger  mb-2" /> */ }
                          <div
                            className="p-3 border rounded-circle bg-danger text-white d-flex flex-column align-items-center justify-content-center"
                            style={ {
                              width: "50px",
                              height: "50px",
                              margin: "0 auto",
                            } }
                          >
                            <h3 className="mb-0">3</h3>
                          </div>
                          <p className="text-muted"> Cancelled Meetings</p>
                          <p className="small text-muted">This month</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="card">
                  <div className="card-header">
                    <h5>Meetings update</h5>
                  </div>
                  <div className="card-body">
                    <ul className="list-group mb-3">
                      { meetings.map( ( meeting ) => (
                        <li
                          key={ meeting.id }
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <h6 className="mb-0">{ meeting.type }</h6>
                            <small className="text-muted">{ meeting.date }</small>
                          </div>
                          { getStatusBadge( meeting.status ) }
                        </li>
                      ) ) }
                    </ul>
                  </div>
                </div>
              </div>
              <div className="container mt-4">

              </div>
            </div>
          </div>

          <div
            className={ `tab-pane fade ${ activeTab === "activity Log" ? "show active" : "" }` }
            id="activityLog"
            role="tabpanel"
            aria-labelledby="activityLog-tab"
          >


            <div class="page-content page-container mb-5" id="page-content">
              <div class="padding">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="timeline p-4 block mb-4">
                      <div class="tl-item active">
                        <div class="tl-dot b-warning"></div>
                        <div class="tl-content">
                          <div class="">
                            @twitter thanks for you appreciation and @google
                            thanks for you appreciation
                          </div>
                          <div class="tl-date text-muted mt-1">13 june 18</div>
                        </div>
                      </div>
                      <div class="tl-item"></div>
                      <div class="tl-item">
                        <div class="tl-dot b-primary"></div>
                        <div class="tl-content">
                          <div class="">
                            Do you know how Google search works.
                          </div>
                          <div class="tl-date text-muted mt-1">
                            45 minutes ago
                          </div>
                        </div>
                      </div>
                      <div class="tl-item">
                        <div class="tl-dot b-danger"></div>
                        <div class="tl-content">
                          <div class="">
                            Thanks to{ " " }
                            <a href="#" data-abc="true">
                              @apple
                            </a>
                            , for iphone 7
                          </div>
                          <div class="tl-date text-muted mt-1">1 day ago</div>
                        </div>
                      </div>
                      <div class="tl-item"></div>
                      <div class="tl-item">
                        <div class="tl-dot b-danger"></div>
                        <div class="tl-content">
                          <div class="">
                            Order placed{ " " }
                            <a href="#" data-abc="true">
                              @eBay
                            </a>{ " " }
                            you will get your products
                          </div>
                          <div class="tl-date text-muted mt-1">1 Week ago</div>
                        </div>
                      </div>
                      <div class="tl-item"></div>
                      <div class="tl-item">
                        <div class="tl-dot b-warning"></div>
                        <div class="tl-content">
                          <div class="">
                            Learn how to use{ " " }
                            <a href="#" data-abc="true">
                              Google Analytics
                            </a>{ " " }
                            to discover vital information about your readers.
                          </div>
                          <div class="tl-date text-muted mt-1">3 days ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={ `tab-pane fade ${ activeTab === "share" ? "show active" : "" }` }
            id="share"
            role="tabpanel"
            aria-labelledby="share-tab"
          >
            <div className="p-3 d-flex flex-column justify-content-center align-items-center mb-5">
              <img
                src="https://cdn.pixabay.com/photo/2013/07/12/14/45/qr-code-148732_1280.png"
                alt="QR Code"
                className="img-fluid rounded mb-3"
                style={ { width: "150px", height: "150px" } }
              />
              <div>
                <button className="btn btn-primary me-2">
                  <BiCopy />{ " " }
                </button>
                <button className="btn btn-primary me-2">
                  <FaMailBulk />{ " " }
                </button>
                <button className="btn btn-primary  me-2">
                  <BiShareAlt />{ " " }
                </button>
                <button className="btn btn-primary">
                  <BiDownload />{ " " }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <Menu />
      </div>
      { selectedCount > 0 && activeTab === "members" && <div className="position-absolute bottom-0 start-50 translate-middle-x bg-white  shadow-lg p-3 mb-5 bg-white rounded w-75 "> <p className="mb-0 ms-2 mt-2">{ selectedCount } selected</p>
        <div className="p-2 d-flex flex-wrap">

          <Chip label="Create Contacts" variant="outlined" className="m-1" onClick={ () => handleToast( "Create Contacts" ) } />
          <Chip label="Enrich" variant="outlined" className="m-1" onClick={ ( e ) => handleChipClick( e, 'Enrich' ) } />
          <Chip label="Send RSVP" variant="outlined" className="m-1" onClick={ ( e ) => handleChipClick( e, 'Send RSVP' ) } />
          <Chip label="Shortlist" variant="outlined" className="m-1" onClick={ () => handleToast( "Shortlist" ) } />
          <Chip label="Remove" variant="outlined" className="m-1" onClick={ () => handleToast( "Remove" ) } />
          <Chip label="Meeting Status" variant="outlined" className="m-1" onClick={ ( e ) => navigate( "/meetingstatus" ) } />
          <Chip label="Share my Date Status" variant="outlined" className="m-1" onClick={ () => navigate( "/sharedate" ) } />
          <Chip label="Share my Calendar" variant="outlined" className="m-1"  onClick={ ( e ) => navigate( "/sharecalander" ) }  />
        </div></div> }
    </div>
  );
}

export default GroupDetails;
