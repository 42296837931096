import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { GrFormNextLink } from "react-icons/gr";
import SocialScreen from "./SocialScreen";

const Register = () => {
  const location = useLocation();
  const key = location.state || {};
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const onSubmit = (data) => {
    // Handle form submission logic here
    console.log(data);
    // Navigate to desired route after form submission
    navigate("/profile/1");
  };

  const navigate = useNavigate();
  const profile = location.state || {};

  return (
    <div
      className="d-flex flex-column  align-items-center "
      // style={{
      //   background:
      //     "linear-gradient(142deg, rgba(230,230,251,1) 0%, rgba(230,224,246,1) 33%, rgba(232,223,247,1) 66%, rgba(223,206,235,1) 100%)",
      // }}
    >
      <div
        className="d-flex w-100 align-items-center mb-2 py-3 justify-content-between "
        style={{ backgroundColor: "#5411dc", color: "#ffff" }}
      >
        <FaChevronLeft
          size={25}
          onClick={() => {
            navigate("/card", { state: { key: "tours" } });
          }}
          className="text-white"
          style={{ cursor: "pointer" }}
        />
        <p className="fw-semibold fs-5 m-0"> {"Add Card Information"}</p>
        <span className="d-flex " style={{ width: "20px" }}></span>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column mt-2 fs-11 al"
      >
        {/* Card Name */}
        <label htmlFor="cardName" className="form-label fs-9">
          {"Card Name"}
        </label>
        <input
          className={`form-control mb-2 ${errors.cardName ? "is-invalid" : ""}`}
          id="cardName"
          type="text"
          {...register("cardName", {
            required: "Card name is required",
          })}
          style={{ borderRadius: "10px" }}
          value={profile?.card_profile_data?.card_name}
        />
        {errors?.cardName?.message && (
          <div className="invalid-feedback text-start">
            {errors?.cardName?.message}
          </div>
        )}

        {/* First Name */}
        <label htmlFor="firstName" className="form-label fs-9">
          First Name*
        </label>
        <input
          className={`form-control mb-2 ${errors.firstName ? "is-invalid" : ""}`}
          id="firstName"
          type="text"
          {...register("firstName", {
            required: "First name is required",
            pattern: {
              value: /^[A-Za-z]+$/,
              message: "First name must contain only letters",
            },
            minLength: {
              value: 2,
              message: "First name must be at least 2 characters long",
            },
          })}
          style={{ borderRadius: "10px" }}
          value={profile?.card_profile_data?.card_first_name}
        />
        {errors?.firstName?.message && (
          <div className="invalid-feedback text-start">
            {errors?.firstName?.message}
          </div>
        )}

        {/* Last Name */}
        <label htmlFor="lastName" className="form-label fs-9">
          Last Name*
        </label>
        <input
          className={`form-control mb-2 ${errors.lastName ? "is-invalid" : ""}`}
          id="lastName"
          type="text"
          {...register("lastName", {
            required: "Last name is required",
            pattern: {
              value: /^[A-Za-z]+$/,
              message: "Last name must contain only letters",
            },
            minLength: {
              value: 2,
              message: "Last name must be at least 2 characters long",
            },
          })}
          style={{ borderRadius: "10px" }}
          value={profile?.card_profile_data?.card_last_name}
        />
        {errors?.lastName?.message && (
          <div className="invalid-feedback text-start">
            {errors?.lastName?.message}
          </div>
        )}

        {/* Profile Image */}
        <label htmlFor="profileImage" className="form-label fs-9">
          Profile Image
        </label>
        <div className="mb-2">
          <input type="file" id="profileImage" {...register("profileImage")} />
        </div>

        {/* Job Title */}
        <label htmlFor="jobTitle" className="form-label fs-9">
          Job Title*
        </label>
        <input
          className={`form-control mb-2 ${errors.jobTitle ? "is-invalid" : ""}`}
          id="jobTitle"
          type="text"
          {...register("jobTitle", {
            required: "Job title is required",
          })}
          style={{ borderRadius: "10px" }}
          value={profile?.card_profile_data?.card_job_title}
        />
        {errors?.jobTitle?.message && (
          <div className="invalid-feedback text-start">
            {errors?.jobTitle?.message}
          </div>
        )}

        {/* Company Name */}
        <label htmlFor="companyName" className="form-label fs-9">
          Company Name
        </label>
        <input
          className="form-control mb-2"
          id="companyName"
          type="text"
          {...register("companyName")}
          style={{ borderRadius: "10px" }}
          value={profile?.card_profile_data?.card_company_name}
        />

        {/* Headline */}
        <label htmlFor="headline" className="form-label fs-9">
          Headline*
        </label>
        <input
          className={`form-control mb-2 ${errors.headline ? "is-invalid" : ""}`}
          id="headline"
          type="text"
          {...register("headline", {
            required: "Headline is required",
          })}
          style={{ borderRadius: "10px" }}
          value={profile?.card_profile_data?.card_headline}
        />
        {errors?.headline?.message && (
          <div className="invalid-feedback text-start">
            {errors?.headline?.message}
          </div>
        )}

        {/* Featured Profile Video URL */}
        <label htmlFor="videoUrl" className="form-label fs-9">
          Featured Profile Video URL (Youtube)
        </label>
        <input
          className="form-control mb-2"
          id="videoUrl"
          type="url"
          {...register("videoUrl", {
            pattern: {
              value: /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+/,
              message: "Invalid YouTube URL",
            },
          })}
          style={{ borderRadius: "10px" }}
          value={profile?.card_profile_data?.youtube}
        />
        {errors?.videoUrl?.message && (
          <div className="invalid-feedback text-start">
            {errors?.videoUrl?.message}
          </div>
        )}

        <button
          className="d-flex align-items-center justify-content-center  mt-2 btn btn-primary fw-semibold w-100 py-2"
          style={{ borderRadius: "10px", color: "#ffff" }}
          onClick={() => {
            navigate("/nextpage", { state: { key: "tours" } });
          }}
        >
          <p className="m-0">Next {""}</p>

          <GrFormNextLink className="text-white" />
        </button>
      </form>
    </div>
  );
};

export default Register;
