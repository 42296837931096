import React, { useEffect } from "react";
import { ChooseSpecificCard } from "../../state_services/actions/businesscard/CardsActions";
import logo from "../../assets/logoOnly.png";
import profile from "../../assets/profile.png";
import { AiOutlineArrowRight } from "react-icons/ai";

export const DisplayCards = (props) => {
  const CARDDETAILS = props?.data?.state?.CardsDetails;

  const ShowSpecificCard_Fn = (Card_object, index) => {
    let _Object = {
      url: Card_object?.profile_url,
      index: index,
    };
    props?.data?.dispatch(ChooseSpecificCard(_Object));
  };

  return (
    <>
      <div className="row row-cols-2 gap-3 px-2 justify-content-center">
        {CARDDETAILS.length > 0 ? (
          CARDDETAILS.map((card, index) => (
            <div
              className="col card rounded-2  align-items-center justify-content-center shadow bg-white p-0"
              onClick={() => ShowSpecificCard_Fn(card, index)}
              style={{
                width: "150px",
                cursor: "pointer",
              }}
            >
              <img
                src={card?.profile_picture ? card?.profile_picture : profile}
                className="card-img-top"
                alt="profileimg"
              />
              <div className="card-body text-start w-100 p-0 px-3 py-2">
                <p className="card-title mb-0 fw-semibold fst-italic fs-10 text-primary">
                  {card?.card_name.toUpperCase()}
                </p>
              </div>
              <div className="card-footer d-flex flex-nowrap justify-content-between align-items-center w-100">
                <p className="mb-0 fw-semibold fs-12  text-secondary">
                  {card?.card_type.toUpperCase()}
                </p>
                <AiOutlineArrowRight size={20} className="text-primary" />
              </div>
            </div>
          ))
        ) : (
          <p className="mb-0 w-100 fw-semibold fs-10 ">
            No Business card has been created
          </p>
        )}
      </div>
    </>
  );
};
