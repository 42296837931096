// src/components/DetailedProfile.js
import React from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { FaChevronLeft, FaCopy } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";
import { Menu } from "../../../functions/menu";
import { FaEdit } from "react-icons/fa";
import SocialScreen from "./SocialScreen";
import { FaLinkedin, FaFacebook, FaReddit } from "react-icons/fa";

const EventBadge = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = location.state || {};

  if (!profile) {
    return <div>Profile not found</div>;
  }
  console.log(profile);

  const profileUrl = window.location.href; // The current page URL

  return (
    <div className="file-details-container ">
      <div
        className="d-flex w-100 align-items-center justify-content-between   p-2"
        style={{ backgroundColor: "#5411dc", color: "#ffff" }}
      >
        <FaChevronLeft
          size={25}
          onClick={() => {
            navigate("/card", { state: { key: "tours" } });
          }}
          className="text-white"
          style={{ cursor: "pointer" }}
        />
        <p className="fw-semibold fs-5 m-0"> {"Profile Details"}</p>
        <span className="d-flex" style={{ width: "20px" }}></span>
      </div>
      <div className="card vh-100">
        <div className="card-body">
          <h5 className="card-title">JULIUS SOLARIS</h5>
          <p className="card-subtitle mb-2 text-muted">BOLDPUSH FOUNDER</p>
          <p className="card-text">LAS VEGAS, USA</p>
          <div className="d-flex justify-content-between align-items-center">
            <div className="rounded-circle bg-light p-3">
              <span className="text-primary">8</span>
            </div>
            {/* <div>
              <span className="badge bg-primary">LOOKING FOR</span>
              <span className="badge bg-danger">SPEAKER</span>
              <span className="badge bg-warning">SPONSOR</span>
              <span className="badge bg-dark">PREMIUM MEMBER</span>
            </div> */}
          </div>
          <div className="mt-3">
            <FaLinkedin className="me-2" />
            <FaFacebook className="me-2" />
            <FaReddit />
          </div>
        </div>
      </div>

      <Menu />
    </div>
  );
};

export default EventBadge;
