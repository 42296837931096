import React from "react";

const BulkEmailMessage = ({
  dependencies: { sendBulkEmail, setSendBulkEmail },
}) => {
  return (
    <>
      <div className="d-flex flex-column w-100 rounded-2 border bg-light p-3 mb-2">
        <label for="bulk_email_message" class="form-label fw-semibold fs-10">
          MESSAGE
        </label>
        <textarea
          type="text"
          class="form-control fw-normal fs-11 fst-italic"
          id="bulk_email_message"
          aria-describedby="bulk_email_message"
          onBlur={(evt) => {
            setSendBulkEmail((previousState) => ({
              ...previousState,
              message: evt.target.value,
            }));
          }}
        ></textarea>
      </div>
    </>
  );
};

export default BulkEmailMessage;
