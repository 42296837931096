import { colors } from "@mui/material";
import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { MdMargin } from "react-icons/md";

const Timeline = () => {
  const eventDateStyle = {
    position: "absolute",
    top: "0",
    right: "100%",
    textAlign: "right",
    fontWeight: "600",
    fontSize: "0.9em",
    minWidth: "120px",
    margin: " 0 15px",
    color: "#5411dc",
  };

  const eventDotStyle = {
    position: "absolute",
    top: "5px",
    left: "-6px",
    background: "#fff",
    borderRadius: "50%",
    height: "9px",
    width: "9px",
    boxShadow: "0 0 0 3px #5411dc",
  };

  const sampleFiles = [
    {
      fileName: "sample_file_1.pdf",
      uploadDate: "2024-06-24",
      email: "jk@yopmail.com",
      personName: "John Doe",
    },
    {
      fileName: "sample_file_2.docx",
      uploadDate: "2024-06-22",
      email: "jothy@yopmail.com",
      personName: "Jane Smith",
    },
    {
      fileName: "sample_file_3.txt",
      uploadDate: "2024-06-20",
      email: "umar@yopmail.com",
      personName: "Bob Johnson",
    },
    {
      fileName: "sample_file_4.jpg",
      uploadDate: "2024-06-18",
      email: "will@yopmail.com",
      personName: "Alice Lee",
    },
    {
      fileName: "sample_file_5.xlsx",
      uploadDate: "2024-06-16",
      email: "ryan@yopmail.com",
      personName: "David Kim",
    },
  ];

  return (
    <Container>
      <Row
        className="justify-content-center text-align-center"
        style={{ marginLeft: "5rem" }}
      >
        <Col md={10} lg={8}>
          <Card>
            <Card.Body>
              <div id="content" className="">
                <ul className="timeline">
                  {sampleFiles.map((file, index) => (
                    <li
                      key={index}
                      className="event list-group-item  ps-4 my-4 position-relative"
                      data-date={file.uploadDate}
                    >
                      <div className="text-break">
                        <strong>{file.personName}</strong> shared{" "}
                        {file.fileName} with <strong>{file.email}</strong>
                      </div>
                      {/* <div className="tl-date text-muted mt-1">
                        {file.uploadDate}
                      </div> */}
                      <span style={eventDateStyle}>{file.uploadDate}</span>
                      <span style={eventDotStyle}></span>
                    </li>
                  ))}
                </ul>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Timeline;
