import React from "react";
import Timeline from "./Timeline";
// import { Modal } from '../../functions/Modal';

const CollectionsTab = () => {
  // Filter data based on the entered keyword

  return (
    <>
      <Timeline />
    </>
  );
};

export default CollectionsTab;
