import React, { useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { ContactManagerConfig, Login_API } from "../../services/userservices";
import { useTranslation } from "react-i18next";

export const ForgotLoginCode = ({
  logo,
  dependencies: { LoginState, LoginChanger },
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  let InitialState = {
    isAPILoading: false,
  };

  function StateChanger(state, action) {
    switch (action.type) {
      case "API_LOADER": {
        return {
          ...state,
          isAPILoading: action.status,
        };
      }
      default: {
        return state;
      }
    }
  }

  const [state, changer] = useReducer(StateChanger, InitialState);

  async function registerEmail(data) {
    changer({ type: "API_LOADER", status: true });
    await Login_API({ ...data, action: "reset_password" }).then((res) => {
      LoginChanger({
        type: "TOAST_RESPONSE",
        status: res?.status,
        message: res?.message,
      });
      if (res?.status === "success") {
        localStorage.removeItem("EditLeads");
        localStorage.removeItem("DisplayLeads");
        localStorage.removeItem("EditItems");
        localStorage.removeItem("InsertItems");
        localStorage.removeItem("user_login_code");
        localStorage.removeItem("user_email");
        localStorage.removeItem("config_settings");
        setTimeout(() => {
          LoginChanger({
            type: "EMAIL_VERIFY",
            email: data.email,
            process: "triggered",
          });
          LoginChanger({ type: "SCREEN_NAME", screen: "verify" });
        }, 1500);
      }

      localStorage.setItem("user_id", JSON.stringify(res?.data["user_id"]));
      localStorage.setItem(
        "exhibitor_id",
        JSON.stringify(res?.data["exhibitor_id"]),
      );
      ContactManagerConfig({
        exhibitor_id: res?.data["exhibitor_id"],
      }).then((res) => {
        localStorage.setItem("config_settings", JSON.stringify(res));
      });

      changer({ type: "API_LOADER", status: false });
    });
  }

  useEffect(() => {
    reset({
      email: "",
    });
  }, [isSubmitSuccessful]);

  return (
    <div className="d-flex flex-column py-5 align-items-center px-3 ">
       <div className="d-flex align-items-center"> 
      <img
        src={logo}
        width="auto"
        height="auto"
        style={{ maxWidth: "170px", maxHeight: "70px" }}
      /><h3 className="text-primary">VEE CARDS</h3></div>
      <h4 className="fw-semibold fs-2 pt-4" style={{ color: "#322d43" }}>
        {t('login.forgot_login_title')}
      </h4>
      <p
        className="fw-normal fs-8 pt-2 px-1"
        style={{ color: "#4a455a" }}
      >
        {t('login.forgot_login_description')}
      </p>

      <form
        className="d-flex flex-column align-items-center mt-2 fs-11"
        style={{ gap: "20px" }}
        onSubmit={handleSubmit(registerEmail)}
      >
        <div className="form-floating fs-9 ">
          <input
            className={`form-control ${errors?.email ? "is-invalid" : ""}`}
            type="email"
            id="email_input"
            placeholder={t('login.email_placeholder')}
            {...register("email", {
              required: { value: true, message: t('login.required_email') },
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t('login.invalid_email'),
              },
            })}
            style={{ borderRadius: "10px" }}
          />
          <label htmlFor="email_input">{t('login.email_placeholder')}</label>
          {errors?.email?.message && (
            <div className="invalid-feedback text-start">
              {errors?.email?.message}
            </div>
          )}
        </div>

        <button
          className="d-flex align-items-center justify-content-center btn btn-primary fw-semibold w-100 py-3"
          style={{ borderRadius: "10px", gap: "10px" }}
          type="submit"
        >
          <p className="m-0">{t('login.submit')}</p>
          {state.isAPILoading && (
            <div className="spinner-border text-light"></div>
          )}
        </button>
      </form>
    </div>
  );
};
