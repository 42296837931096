import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft, FaPlus, FaTrash } from "react-icons/fa";
import "./leads.css";
//API SERVICES
import { CreateNewLead_API, DisplayLeads_API } from "../services/userservices";
import "./leads.css";
import { Menu } from "../functions/menu";
import { renderFieldValue, useWindowSize } from "../functions/functions";
import { NewLists } from "../screens/AddLeads/components/NewLists";
import { ToastContainer, toast } from "react-toastify";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { PhoneNumberUtil } from "google-libphonenumber";
import { t } from "i18next";
// import TagFacesIcon from '@mui/icons-material/TagFaces';
const Addleads = () =>
{
  const size = useWindowSize();
  const navigate = useNavigate();
  const APILOADERREF = useRef();
  let ExhibitorTags = JSON.parse( localStorage.getItem( "Tags" ) );

  const [ phoneNumbers, setPhoneNumbers ] = useState( [
    { phone: "", isValid: true },
  ] );

  const handlePhoneNumberChange = ( index, newPhone ) =>
  {
    const newPhoneNumbers = [ ...phoneNumbers ];
    newPhoneNumbers[ index ] = {
      phone: newPhone,
      isValid: isPhoneValid( newPhone ),
    };
    setPhoneNumbers( newPhoneNumbers );
  };

  const addPhoneNumberInput = () =>
  {
    setPhoneNumbers( [ ...phoneNumbers, { phone: "", isValid: true } ] );
  };

  const removePhoneNumberInput = ( index ) =>
  {
    const newPhoneNumbers = [ ...phoneNumbers ];
    newPhoneNumbers.splice( index, 1 );
    setPhoneNumbers( newPhoneNumbers );
  };
  const [ emailAddresses, setEmailAddresses ] = useState( [ "" ] ); // State to store email addresses as an array
  const [ tags, SetTags ] = useState( [] );
  const [ audioUrls, setAudioUrls ] = React.useState( [] );
  console.log( audioUrls, "audioUrls" );
  const deleteVoiceRecord = ( index ) =>
  {
    setAudioUrls( ( prevUrls ) => prevUrls.filter( ( _, i ) => i !== index ) );
  };
  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = ( phone ) =>
  {
    if ( phone == "" )
    {
      return true;
    } else
    {
      try
      {
        return phoneUtil.isValidNumber( phoneUtil.parseAndKeepRawInput( phone ) );
      } catch ( error )
      {
        return false;
      }
    }
  };
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    ( err ) => console.table( err ), // onNotAllowedOrFound
  );
  const addAudioElement = ( blob ) =>
  {
    const url = URL.createObjectURL( blob );
    setAudioUrls( ( prevUrls ) => [ ...prevUrls, url ] ); // Add new URL to the array
  };
  const first_name = useRef();
  const last_name = useRef();
  const company = useRef();
  const email_address = useRef();
  const phone_no = useRef();
  const desigination = useRef();
  const PHONE_NO_VALIDATE = useRef();
  const EMAIL_VALIDATE = useRef();
  const FIRSTNAME_VALIDATE = useRef();

  console.log( phoneNumbers, "phoneNumbers" );
  let TOAST_CONFIG = {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  const [ addLists, setAddLists ] = useState( {
    Lists: [],
    OnlyListIds: [],
    UpdatedList: [],
  } );
  const [ submitValidator, setSubmitValidator ] = useState( false );
  useEffect( () =>
  {
    if ( JSON.parse( localStorage.getItem( "AddToContact" ) ) != null )
    {
      let ContactInfo = JSON.parse( localStorage.getItem( "AddToContact" ) );
      first_name.current.value =
        typeof ContactInfo?.n != "undefined"
          ? renderFieldValue( ContactInfo, "firstName" )
          : "";
      // first_name.current.value =
      //   typeof ContactInfo?.fn?.[0]?.value?.[0]?.split(" ")?.[0] != "undefined"
      //     ? ContactInfo?.fn?.[0]?.value?.[0]?.split(" ")?.[0] +
      //       " " +
      //       ContactInfo?.fn?.[0]?.value?.[0]?.split(" ")?.[1]
      //     : "";
      last_name.current.value =
        typeof ContactInfo?.n != "undefined"
          ? renderFieldValue( ContactInfo, "lastName" )
          : "";
      company.current.value =
        typeof ContactInfo?.org != "undefined"
          ? renderFieldValue( ContactInfo, "organization" )
          : "";
      setEmailAddresses(
        typeof ContactInfo?.email != "undefined"
          ? renderFieldValue( ContactInfo, "email" )
          : [],
      );

      setPhoneNumbers(
        typeof ContactInfo?.tel != "undefined"
          ? transformPhoneNumbers( renderFieldValue( ContactInfo, "contactNo" ) )
          : [],
      );

      desigination.current.value =
        typeof ContactInfo?.title != "undefined"
          ? renderFieldValue( ContactInfo, "designation" )
          : "";
      localStorage.setItem( "AddToContact", null );
    }
  }, [] );
  const transformPhoneNumbers = ( phoneNumbers ) =>
  {
    return phoneNumbers.map( ( phone ) => ( {
      phone,
      isValid: isPhoneValid( phone ),
    } ) );
  };
  const serialize = ( form ) =>
  {
    // console.log(form);
    let requestArray = {};
    form.querySelectorAll( "[name]" ).forEach( ( elem ) =>
    {
      // console.log(elem);
      requestArray[ elem.name ] = elem.value;
    } );
    requestArray[ "list_id" ] = [ ...addLists?.OnlyListIds ];
    requestArray[ "country" ] = "india";
    requestArray[ "status" ] = "1";
    requestArray[ "key" ] = "4";
    requestArray[ "source" ] = "leads-mobileapp";
    requestArray[ "id" ] = JSON.parse( localStorage.getItem( "exhibitor_id" ) );
    return requestArray;
  };
  const AlertDisplay_fn = ( type, message ) =>
  {
    //  console.log(type, message, "error");
    APILOADERREF.current.classList.remove( "d-flex" );
    APILOADERREF.current.classList.add( "d-none" );
    if ( type.localeCompare( "success" ) == 0 )
    {
      toast.success( message, TOAST_CONFIG );
      setTimeout( () =>
      {
        navigate( "/showleads" );
      }, 3000 );
    } else if ( type.localeCompare( "email_phone_mandatory" ) == 0 )
    {
      toast.warning( message, TOAST_CONFIG );
    } else
    {
      toast.warning( message, TOAST_CONFIG );
    }
  };
  const Goback_fn = () =>
  {
    navigate( -1 );
  };
  const handleSubmit = ( event ) =>
  {
    event.preventDefault();
    setSubmitValidator( true );
    const allPhoneNumbersInvalid = phoneNumbers.every(
      ( phone ) => phone.length <= 4,
    );
    const allEmailAddressesEmpty = emailAddresses.every(
      ( email ) => email === "",
    );
    if ( phoneNumbers[ 0 ]?.phone.length <= 4 && emailAddresses[ 0 ] == "" )
    {
      AlertDisplay_fn(
        "email_phone_mandatory",
        "Either email or phone number is mandatory",
      );
    }
    console.log( "data", phoneNumbers[ 0 ].length );
    let isFirstNameIsInValid = new RegExp( /\d/ );
    let isAlphabetsFound = new RegExp( /[A-Za-z\s]/ );
    let isWhiteSpaceFound = new RegExp( /\s/ );
    if ( first_name.current.value == "" )
    {
      FIRSTNAME_VALIDATE.current.innerText =
        "A first name is required to add a new contact";
      FIRSTNAME_VALIDATE.current.classList.remove( "d-none" );
      FIRSTNAME_VALIDATE.current.classList.add( "d-flex" );
      first_name.current.classList.remove( "is-valid" );
      first_name.current.classList.add( "is-invalid" );
    } else if ( isFirstNameIsInValid.test( first_name.current.value ) )
    {
      FIRSTNAME_VALIDATE.current.innerText =
        "First name is in invalid format, kindly check again and enter";
      FIRSTNAME_VALIDATE.current.classList.remove( "d-none" );
      FIRSTNAME_VALIDATE.current.classList.add( "d-flex" );
      first_name.current.classList.remove( "is-valid" );
      first_name.current.classList.add( "is-invalid" );
    } else
    {
      FIRSTNAME_VALIDATE.current.innerText = "";
      FIRSTNAME_VALIDATE.current.classList.remove( "d-flex" );
      FIRSTNAME_VALIDATE.current.classList.add( "d-none" );
      first_name.current.classList.remove( "is-invalid" );
      first_name.current.classList.add( "is-valid" );
    }


    if ( first_name.current.value !== "" )
    {
      let serialized = serialize( document.querySelector( "#leadform" ) );
      serialized.email_address = emailAddresses;
      serialized.phone_no = phoneNumbers.map( ( pn ) => pn.phone );
      serialized.tag_name = tags;
      let payload = { user: [ serialized ] };
      console.log( serialized, "fdgdgffdgfd" );
      //---------------------------------Duplicates Avoid-----------------------------------------//
      let _leadsdata = "",
        Duplicateflg = false;
      if ( JSON.parse( localStorage.getItem( "DisplayLeads" ) ) != null )
      {
        _leadsdata = JSON.parse( localStorage.getItem( "DisplayLeads" ) );
        Duplicateflg = _leadsdata.hasOwnProperty( serialized.email_address );
      }
      //---------------------------------Duplicates Avoid-----------------------------------------//
      //--------------------------------------------------Online Work---------------------------------------------------------//
      if ( window.navigator.onLine )
      {

        if (
          FIRSTNAME_VALIDATE.current.classList.contains( "d-none" ) &&
          ( phoneNumbers[ 0 ].phone.length >= 4 ||
            emailAddresses[ 0 ] != "" )

        )
        {

          APILOADERREF.current.classList.remove( "d-none" );
          APILOADERREF.current.classList.add( "d-flex" );
          CreateNewLead_API( payload ).then( ( res ) =>
          {
            if ( Array.isArray( res ) )
            {
              res.map( function ( object, index )
              {
                if ( object.status.localeCompare( "success" ) == 0 )
                {
                  if ( JSON.parse( localStorage.getItem( "InsertItems" ) ) == null )
                  {
                    let _obj = {};
                    _obj[ serialized.email_address ] = serialized;
                    _obj[ serialized.email_address ][ "insert_status" ] = true;
                    localStorage.setItem( "InsertItems", JSON.stringify( _obj ) );
                  } else
                  {
                    let _obj = JSON.parse( localStorage.getItem( "InsertItems" ) );
                    _obj[ serialized.email_address ] = serialized;
                    _obj[ serialized.email_address ][ "insert_status" ] = true;
                    localStorage.setItem( "InsertItems", JSON.stringify( _obj ) );
                  }
                  //-------------------------------------------Update Display Leads-------------------------------------------//
                  let Dpayload = {
                    id: JSON.parse( localStorage.getItem( "exhibitor_id" ) ),
                  };
                  DisplayLeads_API( Dpayload ).then( ( res ) =>
                  {
                    // console.log(res,"res");
                    if ( res.status.localeCompare( "success" ) == 0 )
                    {
                      let leads_data = {};
                      res.data.map( ( leads ) =>
                      {
                        leads_data[ leads.data.email_address ] = leads.data;
                      } );
                      localStorage.setItem(
                        "DisplayLeads",
                        JSON.stringify( leads_data ),
                      );
                    }

                  } );
                  // AlertDisplay_fn(object.status, object.message);
                  //-------------------------------------------Update Display Leads-------------------------------------------//
                }
                // else {
                // }
                setAddLists( {
                  Lists: [],
                  OnlyListIds: [],
                  UpdatedList: [],
                } );

                AlertDisplay_fn( object.status, object.message );
              } );
            }
          } );
        } else
        {
          AlertDisplay_fn( "warning" );
        }
      }
      //--------------------------------------------------Online Work---------------------------------------------------------//

      //--------------------------------------------------Offline Work---------------------------------------------------------//
      if ( window.navigator.onLine == false )
      {
        if ( JSON.parse( localStorage.getItem( "InsertItems" ) ) == null )
        {
          let _obj = {};
          _obj[ serialized.email_address ] = serialized;
          _obj[ serialized.email_address ][ "insert_status" ] = false;
          localStorage.setItem( "InsertItems", JSON.stringify( _obj ) );
          AlertDisplay_fn( "success", "Contact added" );
          first_name.current.classList.remove( "is-valid" );
          email_address.current.classList.remove( "is-valid" );
          phone_no.current.classList.remove( "is-valid" );
          document.querySelector( "#leadform" ).reset();
        } else
        {
          let _obj = JSON.parse( localStorage.getItem( "InsertItems" ) );
          if (
            _obj.hasOwnProperty( serialized.email_address ) &&
            Duplicateflg == true
          )
          {
            AlertDisplay_fn( "warning", "Email ID already exists" );
            first_name.current.classList.remove( "is-valid" );
            email_address.current.classList.remove( "is-valid" );
          } else
          {
            AlertDisplay_fn( "success", "Contact added" );
            first_name.current.classList.remove( "is-valid" );
            email_address.current.classList.remove( "is-valid" );
            _obj[ serialized.email_address ] = serialized;
            _obj[ serialized.email_address ][ "insert_status" ] = false;
            localStorage.setItem( "InsertItems", JSON.stringify( _obj ) );
            document.querySelector( "#leadform" ).reset();
          }
        }
      }
    }
    //--------------------------------------------------Offline Work---------------------------------------------------------//
  };
  const handleChange = ( event ) =>
  {
    if ( submitValidator )
    {
      let isFirstNameIsInValid = new RegExp( /\d/ );
      let isAlphabetsFound = new RegExp( /[A-Za-z\s]/ );
      let isWhiteSpaceFound = new RegExp( /\s/ );
      if ( first_name.current.value == "" )
      {
        FIRSTNAME_VALIDATE.current.innerText =
          "A first name is required to add a new contact";
        FIRSTNAME_VALIDATE.current.classList.remove( "d-none" );
        FIRSTNAME_VALIDATE.current.classList.add( "d-flex" );
        first_name.current.classList.remove( "is-valid" );
        first_name.current.classList.add( "is-invalid" );
      } else if ( isFirstNameIsInValid.test( first_name.current.value ) )
      {
        FIRSTNAME_VALIDATE.current.innerText =
          "First name is in invalid format, kindly check again and enter";
        FIRSTNAME_VALIDATE.current.classList.remove( "d-none" );
        FIRSTNAME_VALIDATE.current.classList.add( "d-flex" );
        first_name.current.classList.remove( "is-valid" );
        first_name.current.classList.add( "is-invalid" );
      } else
      {
        FIRSTNAME_VALIDATE.current.innerText = "";
        FIRSTNAME_VALIDATE.current.classList.remove( "d-flex" );
        FIRSTNAME_VALIDATE.current.classList.add( "d-none" );
        first_name.current.classList.remove( "is-invalid" );
        // first_name.current.classList.add("is-valid");
      }
      if ( email_address.current.value !== "" )
      {
        let isEmailIDInValid = new RegExp( "[a-z0-9]+@[a-z]+.[a-z]{2,3}" );
        if ( isEmailIDInValid.test( email_address.current.value ) !== true )
        {
          EMAIL_VALIDATE.current.innerText =
            "This email is invalid, kindly check again and enter";
          EMAIL_VALIDATE.current.classList.remove( "d-none" );
          EMAIL_VALIDATE.current.classList.add( "d-flex" );
          email_address.current.classList.remove( "is-valid" );
          email_address.current.classList.add( "is-invalid" );
        } else
        {
          EMAIL_VALIDATE.current.innerText = "";
          EMAIL_VALIDATE.current.classList.remove( "d-flex" );
          EMAIL_VALIDATE.current.classList.add( "d-none" );
          email_address.current.classList.remove( "is-invalid" );
          // email_address.current.classList.add("is-valid");
        }
      } else
      {
        EMAIL_VALIDATE.current.innerText = "";
        EMAIL_VALIDATE.current.classList.remove( "d-flex" );
        EMAIL_VALIDATE.current.classList.add( "d-none" );
        // email_address.current.classList.remove("is-invalid");
        // email_address.current.classList.add("is-valid");
      }
      if ( phone_no.current.value !== "" )
      {
        let phoneRegex = new RegExp( "^(\\+\\d{1,3}\\s?)?\\d{6,14}$" );

        if ( phoneRegex.test( phone_no.current.value ) !== true )
        {
          PHONE_NO_VALIDATE.current.innerText =
            "This phone number is invalid, kindly check again and enter";
          PHONE_NO_VALIDATE.current.classList.remove( "d-none" );
          PHONE_NO_VALIDATE.current.classList.add( "d-flex" );
          phone_no.current.classList.remove( "is-valid" );
          phone_no.current.classList.add( "is-invalid" );
        } else
        {
          PHONE_NO_VALIDATE.current.innerText = "";
          PHONE_NO_VALIDATE.current.classList.remove( "d-flex" );
          PHONE_NO_VALIDATE.current.classList.add( "d-none" );
          phone_no.current.classList.remove( "is-invalid" );

        }
      } else
      {
        PHONE_NO_VALIDATE.current.innerText = "";
        PHONE_NO_VALIDATE.current.classList.remove( "d-flex" );
        PHONE_NO_VALIDATE.current.classList.add( "d-none" );

      }
    }
  };
  console.log( phoneNumbers );
  console.log( emailAddresses );

  const addEmailAddressInput = () =>
  {
    setEmailAddresses( [ ...emailAddresses, "" ] ); // Add one more empty string to the emailAddresses array
  };


  const removeEmailAddressInput = ( index ) =>
  {
    const updatedEmailAddresses = [ ...emailAddresses ];
    updatedEmailAddresses.splice( index, 1 ); // Remove the email address at the specified index
    setEmailAddresses( updatedEmailAddresses );
  };

  const handleEmailAddressChange = ( index, value ) =>
  {
    const updatedEmailAddresses = [ ...emailAddresses ];
    updatedEmailAddresses[ index ] = value;
    setEmailAddresses( updatedEmailAddresses );
  };

  return (
    <>
      <div
        className="d-flex flex-column p-0  mx-auto"
        style={ {
          overflow: "hidden",
        } }
      >
        <div
          className="d-flex justify-content-between flex-column align-items-center "
          style={ { backgroundColor: "#5411dc" } }
        >
          <div
            className="d-flex w-100 justify-content-between align-items-center"
            style={ { color: "white", fontSize: "2em", padding: "1%" } }
          >
            <FaChevronLeft
              size={ 25 }
              onClick={ Goback_fn }
              style={ { cursor: "pointer" } }
            />
            <p className="m-0 fw-semibold fs-4">{ t( "contactsMenu.addNewContact" ) }</p>
            <p className="m-0 " style={ { width: "20px" } }></p>
          </div>
          <form
            className=" row row-cols-lg-2 d-flex flex-column justify-content-center align-items-center px-4 py-4 rounded-2 bg-white  w-100"
            id="leadform"
            onSubmit={ handleSubmit }
            style={ { overflowY: "auto" } }
          >
            <div className="mb-3">
              <label className="form-label w-100 text-start fw-semibold text-primary">
                { t( "contactsMenu.firstName" ) } <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control "
                onChange={ ( e ) =>
                {
                  handleChange( e );
                } }
                name="first_name"
                ref={ first_name }
              />
              <p
                ref={ FIRSTNAME_VALIDATE }
                className="d-none mb-0 fw-normal fs-11 text-danger"
              ></p>
            </div>
            <div className="mb-3">
              <label className="form-label w-100 text-start text-primary fw-semibold">
                { t( "contactsMenu.lastName" ) }
              </label>
              <input
                type="text"
                className="form-control"
                name="last_name"
                ref={ last_name }
              />
            </div>
            <div className="mb-3">
              <label className="form-label w-100 text-start text-primary fw-semibold">
                { t( "contactsMenu.company" ) }
              </label>
              <input
                type="text"
                className="form-control"
                name="company"
                ref={ company }
              />
            </div>

            { phoneNumbers.map( ( phoneNumber, index ) => (
              <div className="mb-3" key={ `phone_${ index }` }>
                <label className="form-label w-100 text-start text-primary fw-semibold">
                  { t( "contactsMenu.phone" ) }{ index > 0 ? ` ${ index + 1 }` : "" }
                </label>
                <div className="d-flex">
                  <PhoneInput

                    disableDialCodePrefill
                    className={ `form-control ${ !phoneNumber.isValid ? "is-invalid" : "" }` }
                    inputStyle={ {
                      border: "none",
                    } }

                    onChange={ ( value ) => handlePhoneNumberChange( index, value ) }

                    value={ phoneNumber.phone }
                  />


                  { index === phoneNumbers.length - 1 && index <= 1 ? (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={ addPhoneNumberInput }
                    >
                      <FaPlus />{ " " }
                      {/* Plus icon for adding more phone number inputs */ }
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={ () => removePhoneNumberInput( index ) }
                    >
                      <FaTrash />{ " " }
                      {/* Plus icon for adding more phone number inputs */ }
                    </button>
                  ) }
                </div>
                <p
                  ref={ PHONE_NO_VALIDATE }
                  className="d-none mb-0 fw-normal fs-11 text-danger"
                ></p>
                { !phoneNumber.isValid && (
                  <p className="mb-0 fw-normal fs-11 text-danger">
                    { t( "contactsMenu.phoneValidMsg" ) }
                  </p>
                ) }
              </div>
            ) ) }
            {/* Email Address Inputs */ }
            { emailAddresses.map( ( emailAddress, index ) => (
              <div className="mb-3" key={ `email_${ index }` }>
                <label className="form-label w-100 text-start text-primary fw-semibold">
                  { t( "contactsMenu.email" ) }{ index > 0 ? ` ${ index + 1 }` : "" }
                </label>
                <div className="d-flex">
                  <input
                    type="email"
                    className="form-control"
                    onChange={ ( e ) =>
                      handleEmailAddressChange( index, e.target.value )
                    }
                    value={ emailAddress }
                  />
                  { index === emailAddresses.length - 1 && index <= 1 ? (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={ addEmailAddressInput }
                    >
                      <FaPlus /> {/* Plus icon for adding more email inputs */ }
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={ () => removeEmailAddressInput( index ) }
                    >
                      <FaTrash /> {/* Plus icon for adding more email inputs */ }
                    </button>
                  ) }
                </div>
                <p
                  ref={ EMAIL_VALIDATE }
                  className="d-none mb-0 fw-normal fs-11 text-danger"
                ></p>
              </div>
            ) ) }

            <div className="mb-3">
              <label className="form-label w-100 text-start text-primary fw-semibold">
                { t( "contactsMenu.designation" ) }
              </label>
              <input
                type="text"
                name="desigination"
                className="form-control"
                ref={ desigination }
              />
            </div>
            <div className="mb-3">
              <label className="form-label w-100 text-start text-primary fw-semibold">
                { t( "contactsMenu.notes" ) }
              </label>
              <textarea
                type="text"
                rows={ 2 }
                name="notes"
                className="form-control"

              />
            </div>
            <div className="mb-3">
              <div className="recorder-container">
                <div>
                  <div>
                    { audioUrls.map( ( url, index ) => (
                      <div
                        key={ index }
                        className="d-flex align-items-center mb-3"
                      >
                        <audio key={ index } controls src={ url }></audio>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={ () => deleteVoiceRecord( index ) }
                        >
                          <FaTrash color="brown" />{ " " }
                        </button>
                      </div>
                    ) ) }
                  </div>
                </div>

              </div>
            </div>
            <div className="mb-3">
              <label className="form-label w-100 text-start text-primary fw-semibold">
                { t( "contactsMenu.lists" ) }
              </label>
              <NewLists addLists={ addLists } setAddLists={ setAddLists } />
            </div>
            <div className="mb-3">
              <label className="form-label w-100 text-start text-primary fw-semibold">
                { t( "contactsMenu.tags" ) }
              </label>
              <Autocomplete
                multiple
                id="tags-filled"
                options={ ExhibitorTags.map( ( option ) => option?.tag_name ) }

                freeSolo
                size="small"
                filterSelectedOptions
                onChange={ ( event, newValue ) =>
                {
                  SetTags( newValue );
                } }
                renderTags={ ( value, getTagProps ) =>
                  value.map( ( option, index ) => (
                    <Chip
                      variant="outlined"
                      label={ option }
                      color="secondary"
                      sx={ { padding: "2px", margin: "2px" } }
                      { ...getTagProps( { index } ) }
                    />
                  ) )
                }
                renderInput={ ( params ) => (
                  <TextField
                    { ...params }
                    variant="standard"
                    color="secondary"

                  />
                ) }
              />
            </div>
            <button
              type="submit"
              className="d-flex align-items-center justify-content-center gap-2 btn btn-primary "
            >
              <p className="fw-semibold fs-9 mb-0">{ t( "contactsMenu.submit" ) }</p>
              <div
                ref={ APILOADERREF }
                class="d-none spinner-border"
                role="status"
              ></div>
            </button>
          </form>
        </div>
        <Menu />
      </div>
      <ToastContainer />
    </>
  );
};
export default Addleads;
