import React, { useState } from "react";
import { Container, Row, Col, ListGroup, Modal, Button, Image, Form } from "react-bootstrap";
import { FaUser, FaLock, FaInfoCircle, FaQuestionCircle, FaShareAlt, FaSignOutAlt, FaChevronLeft } from "react-icons/fa";
import { MdPerson } from "react-icons/md";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import brandLogo  from '../../src/assets/logoOnly.png';
import { Menu } from "../functions/menu";
import { useNavigate } from "react-router-dom";
function Settings() {
  const [modalShow, setModalShow] = useState({ show: false, content: null });
  const navigate = useNavigate();
  const handleClose = () => setModalShow({ show: false, content: null });
  const handleShow = (content) => setModalShow({ show: true, content });

  return (
    <div className="d-flex flex-column h-100  justify-content-between"> <div
    className="d-flex w-100 justify-content-between align-items-center rounded-bottom"
    style={{ color: "white", fontSize: "2em", padding: "1%",backgroundColor: "#5411dc" }}
  >
    <FaChevronLeft
      size={25}
     onClick={() =>navigate("/dashboard")}
      style={{ cursor: "pointer" }}
    />
    <p className="m-0 fw-semibold fs-4 p-2">Settings</p>
    {/* <FaPlus
      size={25}
    //   onClick={() =>navigate("/addgroups")}
      style={{ cursor: "pointer" }}
    /> */}
  </div>
    <Container className="mt-5 pb-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <div className="text-center mb-4">
            <Image src="https://via.placeholder.com/100" roundedCircle />
            <h3 className="mt-2">John Doe</h3>
            <p className="text-muted">Account Plan: Expo kit Free</p>
          </div>
          <ListGroup>
            <ListGroup.Item action onClick={() => handleShow(<Profile />)}>
              <MdPerson /> Profile
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => handleShow(<AccountType />)}>
              <FaUser /> Account Plan
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => handleShow(<Privacy />)}>
              <FaLock /> Change Password
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => handleShow(<Terms />)}>
              <FaInfoCircle /> Terms and Conditions
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => handleShow(<Help />)}>
              <FaQuestionCircle /> Help
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => handleShow(<Invite />)}>
              <FaShareAlt /> Invite a Friend
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => {
                localStorage.setItem("user_logged_in", JSON.stringify(false));
                window.location = `${window.location.origin}/login`;
              }}>
              <FaSignOutAlt className="text-danger" /> Logout
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      <Modal show={modalShow.show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalShow.content}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
    <div className="sticky-bottom"> <Menu/></div>
    </div>
  );
}

const Profile = () => (
  <div>
      <Container></Container>
   <Row>
              <Col>
                <h5>Edit Profile</h5>
                <Form>
                  <Form.Group controlId="formFirstName" className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your first name" />
                  </Form.Group>

                  <Form.Group controlId="formLastName" className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your last name" />
                  </Form.Group>

                  <Form.Group controlId="formMobile" className="mb-3">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control type="tel" placeholder="Enter your mobile number" />
                  </Form.Group>

                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email" />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
  </div>
);

const AccountType = () => (
  <div>
    <h5>Account Type</h5>
    <p>Account type details go here...</p>
  </div>
);

const Privacy = () => (
  <div>
             <Container>
            
            <Row >
              <Col>
                <h5>Change Password</h5>
                <Form>
                  <Form.Group controlId="formCurrentPassword" className="mb-3">
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter current password" />
                  </Form.Group>

                  <Form.Group controlId="formNewPassword" className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter new password" />
                  </Form.Group>

                  <Form.Group controlId="formConfirmPassword" className="mb-3">
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control type="password" placeholder="Confirm new password" />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
  </div>
);

const Terms = () => (
  <div>
    <h5>Terms and Conditions</h5>
    <p>Terms and conditions details go here...</p>
  </div>
);

const Help = () => (
  <div>
    <h5>Help</h5>
    <p>Help content goes here...</p>
  </div>
);

const Invite = () => (
  <div>
    <h5>Invite a Friend</h5>
    <p>Invite a friend content goes here...</p>
  </div>
);

export default Settings;
