import React, { memo, useEffect, useState } from "react";
import {
  AiOutlineCalendar,
  AiOutlineFile,
  AiOutlineLink,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import { FaChevronLeft } from "react-icons/fa";
import { IoMdCopy } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { renderFieldValue } from "../../functions/functions";
import moment from "moment";
export const IndividualHistory = memo(
  ({ dependencies: { state, setState } }) => {
    const navigate = useNavigate();

    const [contactInfo, setContactInfo] = useState({});
    console.log(state, "state---------------");
    useEffect(() => {
      if (state?.History?.dataType === "vcard") {
        let ProperContactInfo_String = state?.History?.content?.replace(
          /\\"/g,
          '"'
        );
        setContactInfo(JSON.parse(ProperContactInfo_String));
      }
    }, []);

    const AddToContact_Fn = () => {
      localStorage.setItem(
        "AddToContact",
        state?.History?.content?.replace(/\\"/g, '"')
      );
      navigate("/leads");
    };
    const parseVCalendar = (vCalendarString) => {
      const lines = vCalendarString.split(/\r\n|\r|\n/);
      let event = {};

      for (let line of lines) {
        const [key, value] = line.split(":");
        switch (key) {
          case "BEGIN":
            // Handle BEGIN, END, and other properties as needed
            break;
          case "SUMMARY":
            event.summary = value;
            break;
          case "DTSTART":
            event.startDate = value;
            break;
          case "DTEND":
            event.endDate = value;
            break;
          case "LOCATION":
            event.location = value;
            break;
          case "DESCRIPTION":
            event.description = value;
            break;
          default:
            break;
        }
      }

      return event;
    };

    const handleVCalendar = (vCalendarString) => {
      // Parse vCalendar string to extract event details
      const parsedEvent = parseVCalendar(vCalendarString); // Implement parseVCalendar function as needed

      // Check if the parsed event has necessary details
      if (
        parsedEvent &&
        parsedEvent.summary &&
        parsedEvent.startDate &&
        parsedEvent.endDate
      ) {
        // Construct a calendar event object
        const calendarEvent = {
          summary: parsedEvent.summary,
          location: parsedEvent.location,
          description: parsedEvent.description,
          start: moment(parsedEvent.startDate, "YYYYMMDDTHHmmss").toDate(),
          end: moment(parsedEvent.endDate, "YYYYMMDDTHHmmss").toDate(),
        };

        // Attempt to add the event to the user's default calendar
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // For Internet Explorer
          const blob = new Blob([calendarEvent.toString()]);
          window.navigator.msSaveOrOpenBlob(blob, "calendarEvent.vcs");
        } else {
          // For modern browsers
          const calendarEventBlob = new Blob([calendarEvent.toString()], {
            type: "text/calendar;charset=utf-8",
          });
          const calendarEventURL =
            window.URL.createObjectURL(calendarEventBlob);

          // Trigger download or add to calendar
          const link = document.createElement("a");
          link.href = calendarEventURL;
          link.setAttribute("download", "calendarEvent.ics");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        console.error("Invalid vCalendar format or missing required fields.");
        // Handle error or provide feedback to the user
      }
    };

    return (
      <>
        <div
          className="container-lg d-flex flex-column min-vh-100 bg-primary"
          style={{ gap: "20px" }}
        >
          <div className="d-flex w-100  justify-content-between align-items-center px-2 text-white py-3">
            <FaChevronLeft
              size={25}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setState({ type: "UPDATE_SCREEN", screen: "History" });
              }}
            />
            <p className="mb-0 fw-semibold fs-3 text-white">
              {state?.History?.date}
            </p>
            <p className="mb-0"></p>
          </div>
          <div
            className="d-flex flex-column w-100 align-items-center px-2 py-5 bg-white rounded mx-auto"
            style={{ gap: "20px", maxWidth: "400px" }}
          >
            <div className="d-flex w-100 justify-content-center align-items-center text-white">
              <div className="d-flex align-items-center justify-content-center rounded-circle shadow p-3 text-primary">
                {state?.History?.dataType === "vcard" ? (
                  <AiOutlineFile size={30} />
                ) : state?.History?.dataType == "event" ? (
                  <AiOutlineCalendar size={30} />
                ) : (
                  <AiOutlineLink size={30} />
                )}
              </div>
            </div>

            {state?.History?.dataType === "vcard" ? (
              <div className="table-responsive">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th scope="row">First Name : </th>
                      <td style={{ overflowWrap: "anywhere" }}>
                        {renderFieldValue(contactInfo, "firstName")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Last Name : </th>
                      <td style={{ overflowWrap: "anywhere" }}>
                        {renderFieldValue(contactInfo, "lastName")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Email Address : </th>
                      <td style={{ overflowWrap: "anywhere" }}>
                        {renderFieldValue(contactInfo, "email")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Contact No : </th>
                      <td style={{ overflowWrap: "anywhere" }}>
                        {renderFieldValue(contactInfo, "contactNo")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Address : </th>
                      <td style={{ overflowWrap: "anywhere" }}>
                        {renderFieldValue(contactInfo, "address")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">City : </th>
                      <td style={{ overflowWrap: "anywhere" }}>
                        {renderFieldValue(contactInfo, "city")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">State : </th>
                      <td style={{ overflowWrap: "anywhere" }}>
                        {renderFieldValue(contactInfo, "state")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Country : </th>
                      <td style={{ overflowWrap: "anywhere" }}>
                        {renderFieldValue(contactInfo, "country")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Pincode : </th>
                      <td style={{ overflowWrap: "anywhere" }}>
                        {renderFieldValue(contactInfo, "pincode")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : state?.History?.dataType == "event" ? (
              <div className="table-responsive">
                <table className="table ">
                  <tbody>
                    <tr>
                      <td style={{ overflowWrap: "anywhere" }}>
                        <th scope="row">Event Summary </th>
                        {parseVCalendar(state?.History?.url)?.summary}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ overflowWrap: "anywhere" }}>
                        <th scope="row">Start </th>
                        {moment(
                          parseVCalendar(state?.History?.url)?.startDate,
                          "YYYYMMDDTHHmmss"
                        )
                          .toDate()
                          .toString()}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ overflowWrap: "anywhere" }}>
                        <th scope="row">End </th>
                        {moment(
                          parseVCalendar(state?.History?.url)?.endDate,
                          "YYYYMMDDTHHmmss"
                        )
                          .toDate()
                          .toString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <a
                className="text-wrap text-break mb-0 fw-light px-4 fs-10"
                style={{ cursor: "pointer" }}
              >
                {state?.History?.url}
              </a>
            )}
            <div className="d-flex " style={{ gap: "10px" }}>
              {state?.History?.dataType !== "vcard" &&
                state?.History?.dataType !== "event" && (
                  <button
                    className="d-flex justify-content-between align-items-center btn btn-outline-primary rounded-1 shadow"
                    style={{ gap: "5px" }}
                    onClick={() => {
                      let link = document.getElementsByTagName("a");
                      navigator.clipboard.writeText(link?.[0].innerHTML);
                    }}
                  >
                    <IoMdCopy />
                    <p className="mb-0 fw-semibold ">COPY</p>
                  </button>
                )}
              <button
                className="d-flex justify-content-between align-items-center btn btn-primary rounded-1 shadow"
                style={{ gap: "5px" }}
                onClick={() => {
                  if (
                    state?.History?.dataType !== "vcard" &&
                    state?.History?.dataType !== "event"
                  ) {
                    window.open(state?.History?.url, "_blank");
                  }
                }}
              >
                {state?.History?.dataType === "vcard" ? (
                  <AiOutlineUserAdd />
                ) : (
                  <BiLinkExternal />
                )}

                {state?.History?.dataType === "vcard" ? (
                  <p className="mb-0 fw-semibold" onClick={AddToContact_Fn}>
                    ADD TO CONTACTS
                  </p>
                ) : state?.History?.dataType === "event" ? (
                  <p
                    className="mb-0 fw-semibold"
                    onClick={() => handleVCalendar(state?.History?.url)}
                  >
                    SAVE EVENT
                  </p>
                ) : (
                  <p className="mb-0 fw-semibold">OPEN LINK</p>
                )}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
);
