import React, { memo, useEffect, useState, useRef } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { Modal } from "../../../functions/Modal";
import { useForm } from "react-hook-form";
import { AiFillCloseSquare, AiOutlineCloseSquare } from "react-icons/ai";
import { NewBusinessCard_API } from "../../../services/userservices";
import { t } from "i18next";

export const AddTags = memo(
  ({
    dependencies: { state, setState, profileInformation, existing_tags },
  }) => {
    const {
      register,
      handleSubmit,
      watch,
      reset,
      formState: { errors, isSubmitSuccessful },
    } = useForm({
      defaultValues: {
        tags: "",
      },
    });

    const EXHIBITOR_ID = JSON.parse(localStorage.getItem("exhibitor_id"));
    const [displayContacts, setDisplayContacts] = useState(existing_tags);
    const [searchContact, setSearchContact] = useState("");
    const displayContactsDiv = useRef(null);

    async function AddTheTags(data) {
      // let TagArray = data?.tags?.split(/(\s+)/).filter(function (e) {
      //   return e.trim().length > 0;
      // });
      // console.log(TagArray, data?.tags);
      // setState({ type: 'TAGS_STATE', value: [...TagArray] });
      // setState({
      //   type: 'ADD_TAGS_MODAL_STATE',
      //   value: false,
      // });

      let Tagvalue = data?.tags.trim().replace(/(\r\n|\n|\r)/gm, "");
      if (
        state.AddTags.Tags.filter((Contactname) =>
          Contactname.toLowerCase().includes(Tagvalue),
        ).length == 0
      ) {
        //---------------------------------------API Calling----------------------------------------//
        const PayloadFormData = new FormData();
        PayloadFormData.append("action", "update_tags");
        PayloadFormData.append("exhibitor_id", EXHIBITOR_ID);
        PayloadFormData.append("contact_id", profileInformation.contact_id);
        PayloadFormData.append("tag_name", Tagvalue);
        NewBusinessCard_API(PayloadFormData).then((res) => {
          let _obj = JSON.parse(localStorage.getItem("EditLeads"));
          _obj.data.tagged_contacts = res;
          localStorage.setItem("EditLeads", JSON.stringify(_obj));
          //------------------------Tags---------------------------//
          let TagArr = new Array();
          let TagIndexArr = new Array();

          res.map((contacts) => {
            TagIndexArr.push(contacts.tag_id);
            TagArr.push(contacts.tag_name);
          });
          //------------------------Tags---------------------------//

          setState({
            type: "TAGS_STATE",
            value: { Tags: TagArr, Tags_ID: TagIndexArr },
          });
          reset({
            tags: "",
          });
          setState({
            type: "ADD_TAGS_MODAL_STATE",
            value: false,
          });
        });
        //---------------------------------------API Calling----------------------------------------//
      } else {
        reset({
          tags: "",
        });
      }
    }

    const removeTag = (index) => {
      let removedTag = state?.AddTags?.Tags.splice(index, 1);
      let arrayToBeUpdated = state?.AddTags?.Tags.filter((tag) => {
        return tag !== removedTag;
      });
      //---------------------------------------API Calling----------------------------------------//
      const PayloadFormData = new FormData();
      PayloadFormData.append("action", "remove_tag");
      PayloadFormData.append("exhibitor_id", EXHIBITOR_ID);
      PayloadFormData.append("contact_id", profileInformation.contact_id);
      PayloadFormData.append("tag", state.AddTags.RemoveTagsId[index]);
      NewBusinessCard_API(PayloadFormData).then((res) => {
        if (res?.status?.localeCompare("success") == 0) {
          /*----------------------------Temp - Tags state maintain------------------------------*/
          let _obj = JSON.parse(localStorage.getItem("EditLeads"));
          _obj.data.tagged_contacts.splice(index, 1);
          localStorage.setItem("EditLeads", JSON.stringify(_obj));
          /*----------------------------Temp - Tags state maintain------------------------------*/
        }
      });
      //---------------------------------------API Calling----------------------------------------//
      return arrayToBeUpdated;
    };

    const addProfileTags = (event) => {
      if (event.target.value !== "") {
        let Tagvalue = event.target.value.trim().replace(/(\r\n|\n|\r)/gm, "");
        if (
          state.AddTags.Tags.filter((Contactname) =>
            Contactname.toLowerCase().includes(Tagvalue),
          ).length == 0
        ) {
          //---------------------------------------API Calling----------------------------------------//
          const PayloadFormData = new FormData();
          PayloadFormData.append("action", "update_tags");
          PayloadFormData.append("exhibitor_id", EXHIBITOR_ID);
          PayloadFormData.append("contact_id", profileInformation.contact_id);
          PayloadFormData.append("tag_name", Tagvalue);
          NewBusinessCard_API(PayloadFormData).then((res) => {
            let _obj = JSON.parse(localStorage.getItem("EditLeads"));
            _obj.data.tagged_contacts = res;
            localStorage.setItem("EditLeads", JSON.stringify(_obj));
            //------------------------Tags---------------------------//
            let TagArr = new Array();
            let TagIndexArr = new Array();

            res.map((contacts) => {
              TagIndexArr.push(contacts.tag_id);
              TagArr.push(contacts.tag_name);
            });
            //------------------------Tags---------------------------//

            setState({
              type: "TAGS_STATE",
              value: { Tags: TagArr, Tags_ID: TagIndexArr },
            });
            reset({
              tags: "",
            });
          });
          //---------------------------------------API Calling----------------------------------------//
        } else {
          reset({
            tags: "",
          });
        }
      }
    };

    const ContactsFilter_Fn = (object) => {
      if (object.target.value.charAt(0).localeCompare("@") == 0) {
        displayContactsDiv.current.style.display = "block";
        setSearchContact(
          object.target.value.substring(1, object.target.value.length - 1),
        );
      } else {
        displayContactsDiv.current.style.display = "none";
        setSearchContact("");
      }
    };

    const ChooseContacts_Fn = (object) => {
      let Tagvalue = object.target.innerHTML
        .trim()
        .replace(/(\r\n|\n|\r)/gm, "");
      if (
        state.AddTags.Tags.filter((Contactname) =>
          Contactname.toLowerCase().includes(Tagvalue),
        ).length == 0
      ) {
        //---------------------------------------API Calling----------------------------------------//
        const PayloadFormData = new FormData();
        PayloadFormData.append("action", "update_tags");
        PayloadFormData.append("exhibitor_id", EXHIBITOR_ID);
        PayloadFormData.append("contact_id", profileInformation.contact_id);
        PayloadFormData.append("tag_name", Tagvalue);
        NewBusinessCard_API(PayloadFormData).then((res) => {
          let _obj = JSON.parse(localStorage.getItem("EditLeads"));
          _obj.data.tagged_contacts = res;
          localStorage.setItem("EditLeads", JSON.stringify(_obj));
          //------------------------Tags---------------------------//
          let TagArr = new Array();
          let TagIndexArr = new Array();

          res.map((contacts) => {
            TagIndexArr.push(contacts.tag_id);
            TagArr.push(contacts.tag_name);
          });
          //------------------------Tags---------------------------//

          setState({
            type: "TAGS_STATE",
            value: { Tags: TagArr, Tags_ID: TagIndexArr },
          });
          reset({
            tags: "",
          });
        });
        //---------------------------------------API Calling----------------------------------------//
      } else {
        reset({
          tags: "",
        });
      }
    };

    useEffect(() => {
      reset({
        tags: "",
      });
    }, [isSubmitSuccessful]);

    useEffect(() => {
      /*----------------------------Temp - Tags state maintain------------------------------*/
      let _obj = JSON.parse(localStorage.getItem("EditLeads"));
      let TagArr = new Array();
      let TagIndexArr = new Array();

      _obj.data.tagged_contacts.map((contacts) => {
        TagIndexArr.push(contacts.tag_id);
        TagArr.push(contacts.tag_name);
      });
      setState({
        type: "TAGS_STATE",
        value: { Tags: TagArr, Tags_ID: TagIndexArr },
      });
      /*----------------------------Temp - Tags state maintain------------------------------*/
    }, []);

    return (
      <>
        {state?.AddTags?.Tags.length <= 0 ? (
          <div
            className="d-flex w-100 justify-content-center "
            style={{ gap: "10px" }}
          >
            <p className="fst-italic fs-11 fw-semibold text-secondary">
             {t('contactsMenu.tags')}
            </p>
            <BsFillPencilFill
              style={{ cursor: "pointer" }}
              type="button"
              onClick={() => {
                setState({
                  type: "ADD_TAGS_MODAL_STATE",
                  value: true,
                });
              }}
            />
          </div>
        ) : (
          <div
            className="d-flex flex-column w-100 justify-content-center rounded  px-3 py-3"
            style={{
              gap: "10px",
              cursor: "pointer",
              backgroundColor: "rgb(235, 224, 255)",
            }}
            onClick={() => {
              setState({
                type: "ADD_TAGS_MODAL_STATE",
                value: true,
              });
            }}
          >
            <div className="d-flex justify-content-between w-100 align-items-center">
              <p className="d-flex justify-content-start w-100  fs-10 fw-semibold text-dark mb-0">
              {t("contactsMenu.tags")}
              </p>
              <BsFillPencilFill style={{ cursor: "pointer" }} type="button" />
            </div>
            <div
              className="d-flex justify-content-start flex-wrap"
              style={{ gap: "10px" }}
            >
              {state?.AddTags?.Tags.map((tag, index) => {
                return (
                  <p
                    className="d-flex  align-items-center mb-0  text-white fw-semibold fs-10 rounded px-2 py-1"
                    style={{ backgroundColor: "#5411dc", gap: "5px" }}
                    key={index}
                  >
                    {tag}
                  </p>
                );
              })}
            </div>
          </div>
        )}
        {state?.AddTags?.isAddTagsModal_Active && (
          <Modal>
            <div className="d-flex w-100 justify-content-between align-items-center  py-2 px-2">
              <p className="fst-italic fs-10 fw-semibold text-dark mb-0">
                {t('contactsMenu.tags')}
              </p>
              <AiFillCloseSquare
                className="text-primary"
                onClick={() => {
                  setState({
                    type: "ADD_TAGS_MODAL_STATE",
                    value: false,
                  });
                }}
                size={25}
                style={{ cursor: "pointer" }}
              />
            </div>
            <form onSubmit={handleSubmit(AddTheTags)}>
              <div
                className="d-flex flex-column flex-start  py-2 px-2"
                style={{ gap: "5px" }}
              >
                <div
                  className="d-flex justify-content-start flex-wrap"
                  style={{ gap: "10px" }}
                >
                  {state?.AddTags?.Tags.map((tag, index) => {
                    return (
                      <p
                        className="d-flex  align-items-center mb-0 px-2 py-1 text-white fw-semibold fs-10 rounded"
                        style={{ backgroundColor: "#5411dc", gap: "5px" }}
                        key={index}
                      >
                        {tag}{" "}
                        <AiOutlineCloseSquare
                          size={20}
                          onClick={() => {
                            setState({
                              type: "REMOVE_TAG",
                              value: removeTag(index),
                            });
                          }}
                        />
                      </p>
                    );
                  })}
                </div>
                <textarea
                  className="form-control fs-10"
                  placeholder="Type something here to add new TAG + ENTER key"
                  {...register("tags")}
                  onKeyUp={(event) =>
                    event.key === "Enter" ? addProfileTags(event) : null
                  }
                  onChange={(e) => ContactsFilter_Fn(e)}
                ></textarea>

                {/*----------------------------Display Contact List--------------------------*/}
                <div
                  style={{
                    maxHeight: "150px",
                    overflowY: "scroll",
                    display: "none",
                  }}
                  ref={displayContactsDiv}
                >
                  {displayContacts
                    .filter((Contactname) =>
                      Contactname.toLowerCase().includes(searchContact),
                    )
                    .map((Contactnames) => (
                      <p
                        role="button"
                        className="border"
                        onClick={(e) => ChooseContacts_Fn(e)}
                      >
                        {Contactnames}
                      </p>
                    ))}
                </div>
                {/*----------------------------Display Contact List--------------------------*/}
              </div>
            </form>
          </Modal>
        )}
      </>
    );
  },
);
