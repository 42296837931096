import axios from "axios";
// import AWS from "aws-sdk";
import { S3 } from "@aws-sdk/client-s3";
console.log(process.env.REACT_APP_MODE, "REACT_APP_MODE");
export let API_baseUrl = "";
export let API_baseUrl_MobileApps = "";
export let API_baseUrl_AdminApi = "";
export let API_baseUrl_For_Ocr = "";
let UrlAddition = "";

//For production use '' as UrlAddition
//For development use '.php' as UrlAddition

const s3BucketName = process.env.REACT_APP_S3_BUCKET;
const awsAccessKey = process.env.REACT_APP_AWS_ACCESS_KEY;
const awsSecretKey = process.env.REACT_APP_AWS_SECRET_KEY;
const awsRegion = process.env.REACT_APP_AWS_REGION;
const apiRootPath = process.env.REACT_APP_API_ROOT_PATH;
const apiAppRootPath = process.env.REACT_APP_API_APP_ROOT_PATH;
const API_SECRET_TOKEN = process.env.REACT_APP_API_SECRET_TOKEN;

// JS SDK v3 does not support global configuration.
// Codemod has attempted to pass values to each service client in this file.
// You may need to update clients outside of this file, if they use global config.
// AWS.config.update({
//   accessKeyId: awsAccessKey,
//   secretAccessKey: awsSecretKey,
//   region: awsRegion,
// });

const s3 = new S3({
  credentials: {
    accessKeyId: awsAccessKey,
    secretAccessKey: awsSecretKey,
  },

  region: awsRegion,
});

if (process.env.REACT_APP_MODE === "production") {
  //live
  API_baseUrl = "https://app.veespaces.com/api/v3/";
  API_baseUrl_MobileApps = "https://app.veespaces.com/api/v3/mobile_apps/";
  API_baseUrl_For_Ocr = "https://cs.vee.cards/ocrEXT";
} else if (process.env.REACT_APP_MODE === "alpha") {
  //alpha
  API_baseUrl = "https://alpha-app.veespaces.com/api/v3/";
  API_baseUrl_MobileApps =
    "https://alpha-app.veespaces.com/api/v3/mobile_apps/";
  API_baseUrl_For_Ocr = "https://alpha-cs.vee.cards/ocrEXT";
} else if (process.env.REACT_APP_MODE === "uat") {
  //uat
  API_baseUrl = "https://uat-app.veespaces.com/api/v3/";
  API_baseUrl_MobileApps = "https://uat-app.veespaces.com/api/v3/mobile_apps/";
  API_baseUrl_For_Ocr = "https://uat-cs.vee.cards/ocrEXT";
}

export const CreateNewLead_API = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: API_baseUrl_MobileApps + "create_new_lead",
    data: JSON.stringify(payload),
    headers: { "Content-Type": "application/json" },
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};

export const DisplayLeads_API = async (payload) => {
  const service_response = await axios({
    method: "get",
    url:
      API_baseUrl_MobileApps + "get_leads" + UrlAddition + "?id=" + payload.id,
  })
    .then(function (response) {
      return { status: "success", data: response?.data };
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};

export const ContactManagerConfig = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: API_baseUrl_MobileApps + "contact_manager_config",
    data: payload,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error", message: response };
    });
  //console.log("config",service_response);
  return service_response;
};

export const EditLeads_API = async (payload) => {
  const service_response = await axios({
    method: "get",
    url: API_baseUrl_MobileApps + "update_leads" + UrlAddition + "?" + payload,
  })
    .then(function (response) {
      return { status: "success", data: response?.data };
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};

export const Login_API = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: API_baseUrl_MobileApps + "contact_app" + UrlAddition,
    data: payload,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error", message: response };
    });
  console.log("contact_app", service_response);
  return service_response;
};

export const List_API = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: API_baseUrl_MobileApps + "get_lists",
    data: JSON.stringify(payload),
    headers: { "Content-Type": "application/json" },
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};

export const sendResourcesEmail = async (payload) => {
  let response = await axios({
    method: "post",
    url: API_baseUrl + "send_email_preview",
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {});

  return response;
};

export const getEmailTemplate = async (payload) => {
  const response = await axios({
    method: "post",
    url: API_baseUrl + "get_email_template",
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(async (response) => {
      return response?.data;
    })
    .catch((error) => {
      // console.log("countryCodes error", error);
    });
  return response;
};

export const getEmailTemplatesBasedOnPlan = async (payload) => {
  const response = await axios({
    method: "get",
    url:
      API_baseUrl +
      "get_email_templates_based_on_plan" +
      "?exhibitor_id=" +
      payload,
  })
    .then(async (response) => {
      return response?.data;
    })
    .catch((error) => {
      // console.log("countryCodes error", error);
    });
  return response;
};

export const NewBusinessCard_API = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: API_baseUrl_MobileApps + "manage-contact-business-card" + UrlAddition,
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
    // config: { }
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};

export const HistoryForQrcode_API = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: API_baseUrl_MobileApps + "manage-contact-qrcode" + UrlAddition,
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
    // config: { }
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};
export const Businesscard_Fetch_API = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: API_baseUrl_MobileApps + "fetch_digital_cards" + UrlAddition,
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
    // config: { }
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};

export const AssetsByUserAPI = async (payload) => {
  const service_response = await axios({
    method: "get",
    url:
      API_baseUrl_MobileApps +
      "assets_by_user" +
      UrlAddition +
      `?exhibitor_id=${payload.exhibitor_id}`,
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error" };
    });
  console.log(service_response, "asset");
  return service_response;
};

export const Stories_API = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: API_baseUrl_MobileApps + "manage-story" + UrlAddition,
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
    // config: { }
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};

export const SocialLink_API = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: API_baseUrl_MobileApps + "social_links" + UrlAddition,
    data: payload,
    headers: { "Content-Type": "application/json" },
    // config: { }
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};

export const BulkEmailSend = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: apiRootPath + "send_email_to_contacts",
    data: payload,
    headers: {
      "Content-Type": "multipart/form-data",
      'x-api-key': API_SECRET_TOKEN
    },
  })
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};

//------------------------------OCR API----------------------------------//
export const FetchDetailsFromBcardImage_API = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: "https://cs.vee.cards/ocrEXT",
    data: payload,
    headers: {
      "Content-Type": "multipart/form-data",
      "X-Api-Key": process.env.REACT_APP_OCR_API_KEY,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
    // config: { }
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};
//------------------------------OCR API----------------------------------//

export const CreateNewLead_Notes = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: API_baseUrl_MobileApps + "create_new_lead_notes",
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};

export const Create_List = async (payload) => {
  const service_response = await axios({
    method: "post",
    url: API_baseUrl_MobileApps + "list",
    data: payload,
    headers: { "Content-Type": "application/json" },
  })
    .then(function (response) {
      return response?.data;
    })
    .catch(function (response) {
      return { status: "error" };
    });

  return service_response;
};

async function streamToUint8Array(stream) {
  const chunks = [];
  const reader = stream.getReader();

  return new Promise((resolve, reject) => {
    function pump() {
      reader
        .read()
        .then(({ done, value }) => {
          if (done) {
            // Concatenate chunks into a single Uint8Array
            const concatenatedChunks = new Uint8Array(
              chunks.reduce((acc, chunk) => acc + chunk.length, 0),
            );
            let offset = 0;
            for (const chunk of chunks) {
              concatenatedChunks.set(chunk, offset);
              offset += chunk.length;
            }
            resolve(concatenatedChunks);
            return;
          }
          if (value instanceof Uint8Array) {
            chunks.push(value);
          } else {
            // Handle other types of data if necessary
            console.error("Unexpected value type:", typeof value);
          }
          pump();
        })
        .catch(reject);
    }

    pump();
  });
}

export function downloadFile(key) {
  let fileName = key.story_s3_path;
  console.log(fileName, "filename>>>>>", key.story_s3_path);
  const params = {
    Bucket: s3BucketName,
    Key: key.story_s3_path,
  };
  s3.getObject(params, async (err, data) => {
    console.log(data);
    console.log(data.Body);
    if (err) {
      console.error("Error downloading file:", err);
    } else {
      try {
        const arrayBuffer = await streamToUint8Array(data.Body);
        const blob = new Blob([arrayBuffer], { type: data.ContentType });
        const file = new File([blob], "image.png", {
          type: "image/png",
          lastModified: new Date().getTime(),
        });
        console.log(file, "file");
        if (navigator.share) {
          navigator
            .share({
              files: [file],
              title: key?.name,
              text: key?.name,
            })
            .then(() => console.log("Image shared successfully"))
            .catch((error) => console.log("Error sharing image:", error));
        } else {
          // Fallback if Web Share API is not supported
          alert(
            "Web Share API is not supported in this browser. Please try another browser or share the image manually.",
          );
        }
        // const downloadLink = document.createElement("a");
        // downloadLink.href = URL.createObjectURL(blob);
        // downloadLink.download = fileName;
        // downloadLink.click();
        // URL.revokeObjectURL(downloadLink.href);
      } catch (error) {
        console.error("Error converting stream to buffer:", error);
      }
    }
  });
}
