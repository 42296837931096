import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import "./BulkShare.css"; // Ensure to create this file for styling

const BulkShare = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const files = location.state?.files || []; // Get files from navigation state

  return (
    <div className="bulk-share">
      <div
        className="d-flex w-100 align-items-center justify-content-between  p-2"
        style={{ backgroundColor: "#5411dc", color: "#ffff" }}
      >
        <FaChevronLeft
          size={25}
          onClick={() => {
            navigate("/card");
          }}
          className="text-white"
          style={{ cursor: "pointer" }}
        />
        <p className="fw-semibold fs-5 m-0"> {"File Details"}</p>
        <span className="d-flex" style={{ width: "20px" }}></span>
      </div>
      <div
        className="d-flex flex-column justify-content-start bg-white rounded-2 w-100"
        style={{ height: "90%" }}
      >
        <ul className="nav nav-tabs w-100">
          <li className="nav-item" style={{ cursor: "pointer" }}>
            <a
              className={`nav-link fw-semibold fs-10`}
              aria-current="page"
              style={{ color: "black" }}
            >
              {"FILES"}
            </a>
          </li>

          <li className="nav-item" style={{ cursor: "pointer" }}>
            <a className={`nav-link fw-semibold fs-10`}>{"PROFILES"}</a>
          </li>
        </ul>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <ul className="file-list w-100">
          {files.length === 0 ? (
            <li className="file-item">No files selected for sharing.</li>
          ) : (
            files.map((file, index) => (
              <li key={index} className="file-item d-flex align-items-center">
                <div className="file-info px-4">
                  <span className="file-name text-break">{file.name}</span>
                  <span className="file-details text-break">
                    Type: {file.type || "Unknown"}, Size:{" "}
                    {(file.size / (1024 * 1024)).toFixed(2)} MB
                  </span>
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
      <div className="d-flex justify-content-center">
        <div className="d-flex align-items-center justify-content-center btn btn-primary mt-3  fw-semibold ">
          <Link style={{ textDecoration: "none", color: "#ffff" }} to="/card">
            SHARE
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BulkShare;
