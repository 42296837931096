import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import { BsPersonFill, BsCheck } from 'react-icons/bs';
import { AiOutlineCalendar } from 'react-icons/ai';
import { MdLocationOn } from 'react-icons/md';
import { IoMdTime } from 'react-icons/io';
import { FaRegStickyNote } from 'react-icons/fa';
import { FaChevronLeft, FaPencilAlt, FaPlus } from "react-icons/fa";
import { Menu } from "../functions/menu";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarGroup, Switch } from '@mui/material';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiAutoComplete from "./MuiAutoComplete";
const ShareDate = () => {
    const [image, setImage] = useState("https://via.placeholder.com/50");
    const navigate = useNavigate();

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
    };
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className="container-fluid d-flex flex-column justify-content-between" style={{ minHeight: "100vh" }}>
            <div className="d-flex justify-content-between align-items-center bg-primary text-white p-3">
                <FaChevronLeft size={25} onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
                <p className="m-0 fw-bold fs-4">Share Date</p>
                <p></p>
            </div>

            <Form className="p-4">
                <Form.Group controlId="formBasicWho" className="mb-3">
                    <div className="d-flex align-items-center">
                        {/* <AvatarGroup total={24}>
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                            <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                            <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                        </AvatarGroup> */}
                        <span className="ms-2">Contacts shared the date</span>
                    </div>
                </Form.Group>
                <MuiAutoComplete/>
                <Form.Group controlId="formBasicDate" className="mb-3 row">
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <Form.Label>Date</Form.Label>
                        <DatePicker
                         className="form-control"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      
    //   excludeTimes={[
    //     setHours(setMinutes(new Date(), 0), 17),
    //     setHours(setMinutes(new Date(), 30), 18),
    //     setHours(setMinutes(new Date(), 30), 19),
    //     setHours(setMinutes(new Date(), 30), 17),
    //   ]}
      dateFormat="MMMM d, yyyy"
    />
                    </div>
                    <div className="col-12 col-md-6">
                        <Form.Label>Time</Form.Label>
                        {/* <Form.Control type="time" /> */}
                        <DatePicker
                        className="form-control"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="h:mm aa"
    />
                    </div>
                </Form.Group>
                <FormGroup className="border border-primary px-1 rounded col-sm-3">
 
  <FormControlLabel  control={<Switch defaultChecked color="secondary" size="large"/>} label="In-person" />

</FormGroup>
               
                <Form.Group controlId="formBasicLocation" className="mb-3">
                    <Form.Label>Where</Form.Label>
                    <Form.Control type="text" placeholder="123 Main Street, Anytown, CA 12345" />
                </Form.Group>

                <Form.Group controlId="formBasicNotes" className="mb-4">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="We are meeting for the first time! We will..." />
                </Form.Group>

                <Button variant="primary" type="submit" className=" ">
                    Done 
                </Button>
            </Form>

            <div>
                <Menu />
            </div>
        </div>
    );
};

export default ShareDate;
