import React, { useEffect, useState } from "react";
import { FaCopy, FaInfo, FaQrcode } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiShareForwardFill } from "react-icons/ri";
import { useCustomPagination, useWindowSize } from "../../functions/functions";
import
  {
    Businesscard_Fetch_API,
    getEmailTemplate,
  } from "../../services/userservices";
import SpecificCard from "./components/SpecificCard";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

// import InfoIcon from '@mui/icons-material/Info';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import VCard from "vcard-creator";
import QRCode from "qrcode.react";
import { t } from "i18next";
const CardsTab = ( { data, state, setState } ) =>
{
  const size = useWindowSize();
  const datas = data?.data;
  const isPlanSubscribed = data?.isPlanSubscribed;
  const [ keyword, setKeyword ] = useState( null );
  const [ businesscards, setBusinessCards ] = useState( null );
  const [ specificData, setSpecificData ] = useState( null );
  const [ view, setView ] = useState( "ALL" ); //ALL or SPECIFIC  without white space
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    // bgcolor: 'background.paper',
    border: "2px solid #5411dc",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };
  const [ open, setOpen ] = React.useState( false );
  const [ vcf, setVcf ] = React.useState( null );
  const handleOpen = ( data ) =>
  {
    setOpen( true );
    setVcf( data );
  };
  const handleClose = () => setOpen( false );
  function getWidth ()
  {
    const screenWidth = window.innerWidth;
    if ( screenWidth >= 1100 )
    {
      return "25%";
    } else if ( screenWidth >= 768 && screenWidth < 1100 )
    {
      return "40%";
    } else
    {
      return "100%";
    }
  }
  // Filter data based on the entered keyword
  const filteredData = keyword
    ? data?.data.filter(
      ( item ) =>
        item &&
        item?.card_name &&
        item?.card_name.toLowerCase().includes( keyword.toLowerCase() )
    )
    : data?.data || {};
  console.log( filteredData, "filteredData" );
  console.log( data, "data?.data" );
  const { Paginator, pageWiseData, currentPage, setCurrentPage } =
    useCustomPagination( {
      datas: filteredData,
      pageSize: 20,
      siblingCount: 1,
    } );

  // console.log(pageWiseData, currentPage);

  useEffect( () =>
  {
    Businesscard_Fetch_API( {
      exhibitor_id: JSON.parse( localStorage.getItem( "exhibitor_id" ) ),
    } ).then( ( res ) =>
    {
      if ( res?.status?.localeCompare( "success" ) == 0 )
      {
        setBusinessCards( res?.data );
      }
    } );
  }, [] );
  function formatDate ( dateString )
  {
    console.log( dateString, "dateString" );
    const date = new Date( dateString );

    const year = date.getFullYear();
    const month = `0${ date.getMonth() + 1 }`.slice( -2 );
    const day = `0${ date.getDate() }`.slice( -2 );
    const hours = `0${ date.getHours() }`.slice( -2 );
    const minutes = `0${ date.getMinutes() }`.slice( -2 );
    const seconds = `0${ date.getSeconds() }`.slice( -2 );

    return `${ year }${ month }${ day }T${ hours }${ minutes }${ seconds }`;
  }
  const navigate = useNavigate();

  const handleCreateCard = () =>
  {
    navigate( "/create-card" ); // Navigate to the create card form page
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={ handleCreateCard }
        style={ { margin: "20px", backgroundColor: "#6610f2" } }
      >
        Create Card
      </Button>
      <div
        className="d-flex flex-wrap  w-100 justify-content-center align-items-start h-100 p-2 gap-2"
        style={ {
          scrollbarWidth: "thin",
          scrollbarGutter: "stable",
          margin: 0,
          overflowY: "auto",
        } }
      >
        { Array.isArray( datas ) &&
          isPlanSubscribed !== null &&
          isPlanSubscribed !== "undefined" ? (
          isPlanSubscribed === "Yes" ? (
            view === "ALL" ? (
              Object.keys( pageWiseData ).length > 0 &&
                typeof pageWiseData[ currentPage ] !== "undefined" &&
                Object.keys( pageWiseData[ currentPage ][ "data" ] ).length > 0 ? (
                <>
                  { Object.keys( pageWiseData[ currentPage ][ "data" ] ).map(
                    ( card, index ) =>
                    {
                      console.log(
                        pageWiseData[ currentPage ][ "data" ][ card ]
                          .card_profile_data
                      );
                      const myVCard = new VCard();
                      const profile_data =
                        pageWiseData[ currentPage ][ "data" ][ card ]
                          .card_profile_data;
                      const lastname = profile_data.card_last_name;
                      const firstname = profile_data.card_first_name;
                      const company = profile_data.card_company_name;
                      const jobtitle = profile_data.card_job_title;
                      const role = profile_data.card_headline;
                      const email = profile_data.card_email;
                      const phone = profile_data.card_phone;
                      const address = profile_data.card_address;
                      const website = profile_data.card_website_url;

                      if ( lastname && firstname )
                      {
                        myVCard.addName( lastname, firstname );
                      } else if ( lastname )
                      {
                        myVCard.addName( lastname );
                      } else if ( firstname )
                      {
                        myVCard.addName( firstname );
                      }
                      // Add work data if available
                      if ( company ) myVCard.addCompany( company );
                      if ( jobtitle ) myVCard.addJobtitle( jobtitle );
                      if ( role ) myVCard.addRole( role );
                      // Add contact data
                      if ( email ) myVCard.addEmail( email );
                      if ( phone ) myVCard.addPhoneNumber( phone, "PREF;WORK" );
                      if ( address ) myVCard.addAddress( address );
                      if ( website ) myVCard.addURL( website );

                      const vcalendar = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//hacksw/handcal//NONSGML v1.0//EN
BEGIN:VEVENT
ORGANIZER;CN=${ profile_data?.organiser ? profile_data.organiser : "" }:mailto:${ profile_data?.email ? profile_data.email : "" }
DTSTART:${ profile_data?.from_date ? formatDate( profile_data.from_date ) : "" }
DTEND:${ profile_data?.to_date ? formatDate( profile_data.to_date ) : "" }
SUMMARY:${ profile_data?.title ? profile_data.title : "" }
DESCRIPTION:${ profile_data?.description ? profile_data.description : "" }
LOCATION:${ profile_data?.venue ? profile_data.venue : "" }
END:VEVENT
END:VCALENDAR`;

                      console.log( "data" );
                      return (
                        <>
                          <div
                            key={ index }
                            className=" card rounded  align-items-center justify-content-center shadow bg-white "
                            style={ {
                              maxwidth: "330px",
                              cursor: "pointer",
                              // width:getWidth()
                            } }
                          >
                            <img
                              src={
                                pageWiseData[ currentPage ][ "data" ][ card ]
                                  .card_thumbnail_image
                              }
                              className="card-img-top rounded-2"
                              alt="profileimg"
                              height="150px"
                            />
                            <div className="card-body text-start w-100 p-0 px-3 py-2">
                              <p className="card-title mb-0 fw-semibold fst-italic fs-10 text-primary">
                                { pageWiseData[ currentPage ][ "data" ][
                                  card
                                ].card_name.toUpperCase() }
                              </p>
                            </div>
                            <div className="text-end w-100 mb-2 ">
                              <small className="  text-primary border rounded p-1 mx-2">
                                { pageWiseData[ currentPage ][ "data" ][ card ]
                                  ?.card_profile_data?.card_type == "Event"
                                  ? t( "shareMenu.Event" )
                                  : null }
                                { pageWiseData[ currentPage ][ "data" ][ card ]
                                  ?.card_profile_data?.card_type == "Business"
                                  ? t( "shareMenu.Business" )
                                  : null }
                                { pageWiseData[ currentPage ][ "data" ][ card ]
                                  ?.card_profile_data?.card_type == "Individual"
                                  ? t( "shareMenu.Individual" )
                                  : null }
                              </small>
                            </div>
                            <div className="card-footer d-flex flex-nowrap justify-content-start align-items-center w-100 gap-2">
                              <button
                                className="btn btn-primary px-2 py-1"
                                onClick={ () =>
                                {
                                  navigator.clipboard.writeText(
                                    pageWiseData[ currentPage ][ "data" ][ card ]
                                      .card_url
                                  );
                                } }
                              >
                                <FaCopy size={ 16 } />
                              </button>
                              <button
                                className="btn btn-primary px-2 py-1"
                                onClick={ async ( evt ) =>
                                {
                                  evt.preventDefault();
                                  let payload = new FormData();
                                  payload.append(
                                    "exhibitor_id",
                                    JSON.parse(
                                      localStorage.getItem( "exhibitor_id" )
                                    )
                                  );
                                  payload.append( "email_instance_id", 13 );
                                  payload.append(
                                    "card_id",
                                    pageWiseData[ currentPage ][ "data" ][ card ]
                                      .card_id
                                  );

                                  await getEmailTemplate( payload ).then(
                                    ( res ) =>
                                    {
                                      if (
                                        res?.subject &&
                                        res?.body &&
                                        typeof res?.subject !== "undefined" &&
                                        typeof res?.body !== "undefined"
                                      )
                                      {
                                        // console.log('inside');
                                        setState( {
                                          type: "UPDATE_EMAIL_TEMPLATE",
                                          subject: res?.subject,
                                          body: res?.body,
                                          instanceID: 13,
                                          sourceID:
                                            pageWiseData[ currentPage ][ "data" ][
                                              card
                                            ].card_id,
                                          contentType: null,
                                        } );
                                      } else
                                      {
                                        setState( {
                                          type: "UPDATE_EMAIL_TEMPLATE",
                                          subject: "preset subject here",
                                          body: "<p>Enter your text here</p>",
                                          instanceID: 13,
                                          sourceID:
                                            pageWiseData[ currentPage ][ "data" ][
                                              card
                                            ].card_id,
                                          contentType: null,
                                        } );
                                      }
                                      setState( {
                                        type: "UPDATE_SHARE_MODAL_STATE",
                                        value: true,
                                      } );
                                    }
                                  );
                                } }
                              >
                                <MdEmail size={ 16 } />
                              </button>
                              <button
                                className="btn btn-primary px-2 py-1"
                                onClick={ () =>
                                {
                                  if ( navigator.share )
                                  {
                                    navigator
                                      .share( {
                                        title:
                                          pageWiseData[ currentPage ][ "data" ][
                                            card
                                          ].card_name,
                                        url: pageWiseData[ currentPage ][ "data" ][
                                          card
                                        ].card_url,
                                        text: pageWiseData[ currentPage ][ "data" ][
                                          card
                                        ].card_name,
                                      } )
                                      .then( () => { } )
                                      .catch( ( err ) =>
                                      {
                                        console.log( err );
                                      } );
                                  } else
                                  {
                                    setState( {
                                      type: "UPDATE_FALLBACK_STATE",
                                      state: true,
                                      url: pageWiseData[ currentPage ][ "data" ][
                                        card
                                      ].card_url,
                                    } );
                                  }
                                } }
                              >
                                <RiShareForwardFill size={ 16 } />
                              </button>
                              <button
                                className="btn btn-primary px-2 py-1"
                                onClick={ () =>
                                {
                                  handleOpen(
                                    profile_data.card_type === "Event"
                                      ? vcalendar.toString()
                                      : myVCard.toString()
                                  );
                                } }
                              >
                                <FaQrcode size={ 16 } />
                              </button>
                              <button
                                className="btn btn-primary px-2 py-1"
                                onClick={ () =>
                                {
                                  navigate( "/profile/1", {
                                    state:
                                      pageWiseData[ currentPage ][ "data" ][ card ],
                                  } );
                                } }
                              >
                                <AiOutlineArrowRight size={ 16 } />
                              </button>

                            </div>
                          </div>
                        </>
                      );
                    }
                  ) }
                </>
              ) : (
                <>
                  <h2 className="fw-bold fs-4 mb-0 py-4 px-2 text-center">
                    No cards have been uploaded
                  </h2>
                  <p className="mb-0 fw-normal text-secondary fs-10">
                    <a
                      className="text-decoration-none text-primary pe-auto"
                      target="_blank"
                      href={
                        process.env.REACT_APP_MODE == "production"
                          ? "https://app.veespaces.com/login"
                          : "https://dev-app.veespaces.com/login"
                      }
                    >
                      Click here
                    </a>{ " " }
                    to upload some digital cards to share with others
                  </p>
                </>
              )
            ) : (
              <SpecificCard cardURL={ specificData } setState={ setView } />
            )
          ) : (
            <>
              <h2 className="fw-bold fs-4 mb-0 py-4 px-2 text-center">
                This plan has not been subscribed
              </h2>
              <p className="mb-0 fw-normal text-secondary fs-10">
                <a
                  className="text-decoration-none text-primary pe-auto"
                  target="_blank"
                  href="https://www.veespaces.com/digital-business-cards/pricing/"
                >
                  Click here
                </a>{ " " }
                to view our plan details
              </p>
            </>
          )
        ) : (
          <>
            <div
              className="d-flex w-100 bg-white align-items-center justify-content-center"
              style={ { height: size.height } }
            >
              <div
                className="spinner-border text-primary "
                role="status"
                style={ { width: "200px", height: "200px" } }
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        ) }
      </div>
      <div className="d-flex align-items-center justify-content-center bg-primary rounded-2 px-2 py-1">
        <Paginator />
      </div>
      <Modal
        open={ open }
        onClose={ handleClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ style }>
          <QRCode
            value={ vcf }
            size={ 250 } // min 150
            level="L"
            renderAs="canvas"
            includeMargin={ true }
            bgColor="#fff"
            fgColor="#5411dc"
            style={ { borderRadius: "10px" } }
          />
        </Box>
      </Modal>
    </>
  );
};

export default CardsTab;
