import React, { useReducer, useEffect, useRef } from "react";
import EmailEditor from "../../../functions/EmailEditor";
import { useForm } from "react-hook-form";
import {
  BulkEmailSend,
  getEmailTemplate,
  getEmailTemplatesBasedOnPlan,
  sendResourcesEmail,
} from "../../../services/userservices";
import { AiFillCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { useWindowSize } from "../../../functions/functions";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Chip } from "@mui/material";

const ShareEmail = ({
  dependencies: {
    state,
    setState,
    data,
    share_email_template,
    exhibitor_id,
    bulkEmail,
  },
}) => {
  const editorRef = useRef(null);
  const size = useWindowSize();
  console.log(bulkEmail?.selectedEmails, "dfsf");
  const INPUTFIELD = {
    display: "flex",
    flexDirection: "column",

    gap: "5px",
    width: "100%",
  };
  const INPUTBLOCK = {
    display: "flex",

    flexDirection: "row",
    alignItems: "center",
    borderBottom: "2px solid black",
  };
  const ERRBLOCK = {
    display: "flex",
    width: "100%",
  };
  const INPUTBOX = {
    outline: "none",
    border: 0,
    padding: "10px 12px",
    fontSize: "0.7rem",
    width: "100%",
    height: "40px",
  };
  const INPUTBOXERR = {
    outline: "none",
    border: 0,
    padding: "10px 12px",
  };
  const defaultFields = [
    {
      label: "To",
      fieldFor: "email",
      required: true,
      placeholder: "Please enter the email to send",
      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
      type: "email",
      tag: "multipleEmail",
    },
    {
      label: "To",
      fieldFor: "email",
      required: true,
      placeholder: "Please enter the email to send",
      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
      type: "email",
      tag: "singleEmail",
    },
    {
      label: "Subject",
      fieldFor: "subject",
      required: true,
      placeholder: "Enter an subject",
      pattern: null,
      type: "text",
      tag: "input",
    },
  ];
  const status = {
    shareResponse: {
      state: false,
      message: "",
      severity: "",
    },
    loader: false,
  };
  const shareReducer = (state, action) => {
    switch (action.type) {
      case "SHARE_API_RESPONSE": {
        if (action.state === false) {
          return {
            ...state,
            shareResponse: {
              state: action.state,
              message: "",
              severity: "",
            },
          };
        }
        if (action.state === true) {
          return {
            ...state,
            shareResponse: {
              state: action.state,
              message: action.message,
              severity: action.severity,
            },
          };
        }
      }
      case "API_LOADER": {
        return {
          ...state,
          loader: action.state,
        };
      }
      default:
        return state;
    }
  };
  const [shareState, shareDispatch] = useReducer(shareReducer, status);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      email: "",
      subject: "",
    },
  });

  const [template, setTemplate] = React.useState([]);
  const [templateDesign, setTemplateDesign] =
    React.useState(share_email_template);
  const [sendBulkEmail, setSendBulkEmail] = React.useState({
    exhibitor_id: exhibitor_id,
    contact_id: bulkEmail?.selected_contacts,
    assets: [],
    collections: [],
    digital_cards: [],
    virtual_tours: [],
    message: "",
  });
  console.log(template, "template");
  const handleChange = (event) => {
    const selectedInstanceId = event.target.value;

    // Find the template object in the array where mail_instance_id matches the selected value
    const selectedTemplate = template.find(
      (item) => item.mail_instance_id === selectedInstanceId
    );

    // If a matching template is found, update templateDesign state
    if (selectedTemplate) {
      setTemplateDesign({
        subject: selectedTemplate.subject,
        body: selectedTemplate.body,
      });
    }

    // (async function () {
    //   let payload = new FormData();
    //   payload.append(
    //     'exhibitor_id',
    //     exhibitor_id
    //   );
    //   payload.append('email_instance_id', event.target.value); //17

    //   await getEmailTemplate(payload).then((res) => {
    //     if (
    //       res?.subject &&
    //       res?.body &&
    //       typeof res?.subject !== 'undefined' &&
    //       typeof res?.body !== 'undefined'
    //     ) {
    //       setTemplateDesign({
    //         subject: res?.subject,
    //         body: res?.body,
    //       })
    //     } else {

    //     }
    //   });
    // })();
  };

  async function sendEmail(dataEmail) {
    shareDispatch({ type: "API_LOADER", state: true });
    let payload = new FormData();
    payload.append("exhibitor_id", exhibitor_id);
    payload.append("email_address", dataEmail?.email);
    payload.append("subject", dataEmail?.subject);
    payload.append("email_instance_id", data?.email_instance_id);
    payload.append("source_id", data?.source_id);
    if (data?.content_type !== null) {
      payload.append("content_type", data?.content_type);
    }
    payload.append(
      "body",
      editorRef.current ? editorRef.current.getContent() : ""
    );

    await sendResourcesEmail(payload)
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(res?.message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        shareDispatch({
          type: "SHARE_API_RESPONSE",
          state: true,
          message: res?.message,
          severity: res?.status,
        });
        setTimeout(() => {
          setState({ type: "UPDATE_SHARE_MODAL_STATE", value: false });
        }, 2000);
        shareDispatch({ type: "API_LOADER", state: false });
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  async function bulkSendEmail(dataEmail) {
    shareDispatch({ type: "API_LOADER", state: true });
    await BulkEmailSend({
      exhibitor_id: sendBulkEmail?.exhibitor_id,
      user_id: JSON.parse(localStorage.getItem("user_id")),
      contact_id: JSON.stringify(sendBulkEmail?.contact_id),
      subject: dataEmail?.subject,
      content: editorRef.current ? editorRef.current.getContent() : "",
      type: "mass-email",
    })
      .then((res) => {
        if (res.data) {
          toast.success("Email sented sucessfully", {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            // setApiLoader('none');
            setState({ type: "UPDATE_SHARE_MODAL_STATE", value: false });
          }, 1000);
        } else {
          toast.error(res?.data?.message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        shareDispatch({ type: "API_LOADER", state: false });
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  useEffect(() => {
    reset({
      email: "",
      subject: "",
    });
    getEmailTemplatesBasedOnPlan(exhibitor_id).then((res) => {
      setTemplate(res.response);
    });
  }, [isSubmitSuccessful]);

  useEffect(() => {
    reset({
      email: "",
      subject: templateDesign?.subject,
    });
  }, [templateDesign, reset]);
  return (
    <>
      <div
        className="d-flex flex-column justify-content-start align-items-center w-100 p-2"
        style={
          shareState?.loader === true
            ? { opacity: 0.5, pointerEvents: "none" }
            : null
        }
      >
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex flex-column align-items-start justify-content-start text-start w-100">
            <h3 className="fw-bold fs-2 mb-1">Compose</h3>
            <p className="fw-normal fs-11 mb-0">
              Share content, quick and easy
            </p>
          </div>

          <AiFillCloseCircle
            size={35}
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setState({ type: "UPDATE_SHARE_MODAL_STATE", value: false });
            }}
          />
        </div>
        <form
          onSubmit={
            bulkEmail != ""
              ? handleSubmit(bulkSendEmail)
              : handleSubmit(sendEmail)
          }
          id="email-share"
          className="d-flex flex-column justify-content-start w-100 gap-2 overflow-y-auto"
          style={{ height: size.height - 150 }}
        >
          {defaultFields?.map((field, index) => {
            return (
              <>
                {(field?.tag === "multipleEmail") & (bulkEmail !== "") ? (
                  <div style={INPUTFIELD} key={index}>
                    <div style={INPUTBLOCK}>
                      <label
                        htmlFor={field?.fieldFor}
                        id="input-label"
                        className="fw-semibold fs-11 "
                        style={{ width: "58px" }}
                      >
                        {field?.label.toUpperCase()}
                      </label>
                      {/* <input
                     placeholder={field?.placeholder}
                    //  {...register(field?.fieldFor, {
                    //    required: field?.required,
                    //    pattern:
                    //      field?.pattern !== null ? field?.pattern : null,
                    //  })}
                     type={field?.type}
                     style={
                       errors?.[field?.fieldFor] ? INPUTBOXERR : INPUTBOX
                     }
                     id={field?.fieldFor}
                     className="fw-normal fst-italic fs-11 w-100"
                   /> */}

                      <Box sx={{ overflowX: "auto", maxHeight: "70px" }}>
                        {bulkEmail?.selectedEmails?.map((selectedEmail) => {
                          return (
                            <>
                              <Chip
                                sx={{ margin: "2px" }}
                                label={selectedEmail}
                              />
                            </>
                          );
                        })}
                      </Box>
                    </div>
                    <div style={ERRBLOCK}>
                      {errors?.[field?.fieldFor] &&
                        (errors?.[field?.fieldFor].type === "required" ? (
                          <span
                            style={{
                              color: "rgb(139, 139, 85)",
                              fontSize: "0.6rem",
                            }}
                          >
                            This field is required !!
                          </span>
                        ) : errors?.[field?.fieldFor].type === "pattern" ? (
                          <span
                            style={{
                              color: "rgb(139, 139, 85)",
                              fontSize: "0.6rem",
                            }}
                          >
                            Invalid input !!
                          </span>
                        ) : null)}
                    </div>
                  </div>
                ) : null}
                {(field?.tag === "singleEmail") & (bulkEmail == "") ? (
                  <div style={INPUTFIELD} key={index}>
                    <div style={INPUTBLOCK}>
                      <label
                        htmlFor={field?.fieldFor}
                        id="input-label"
                        className="fw-semibold fs-11 "
                        style={{ width: "58px" }}
                      >
                        {field?.label.toUpperCase()}
                      </label>
                      <input
                        placeholder={field?.placeholder}
                        {...register(field?.fieldFor, {
                          required: field?.required,
                          pattern:
                            field?.pattern !== null ? field?.pattern : null,
                        })}
                        type={field?.type}
                        style={
                          errors?.[field?.fieldFor] ? INPUTBOXERR : INPUTBOX
                        }
                        id={field?.fieldFor}
                        className="fw-normal fst-italic fs-11 w-100"
                      />
                    </div>
                    <div style={ERRBLOCK}>
                      {errors?.[field?.fieldFor] &&
                        (errors?.[field?.fieldFor].type === "required" ? (
                          <span
                            style={{
                              color: "rgb(139, 139, 85)",
                              fontSize: "0.6rem",
                            }}
                          >
                            This field is required !!
                          </span>
                        ) : errors?.[field?.fieldFor].type === "pattern" ? (
                          <span
                            style={{
                              color: "rgb(139, 139, 85)",
                              fontSize: "0.6rem",
                            }}
                          >
                            Invalid input !!
                          </span>
                        ) : null)}
                    </div>
                  </div>
                ) : null}
                {field?.tag === "input" ? (
                  <div style={INPUTFIELD} key={index}>
                    <div style={INPUTBLOCK}>
                      <label
                        htmlFor={field?.fieldFor}
                        id="input-label"
                        className="fw-semibold fs-11 "
                        style={{ width: "58px" }}
                      >
                        {field?.label.toUpperCase()}
                      </label>
                      <input
                        placeholder={field?.placeholder}
                        {...register(field?.fieldFor, {
                          required: field?.required,
                          pattern:
                            field?.pattern !== null ? field?.pattern : null,
                        })}
                        type={field?.type}
                        style={
                          errors?.[field?.fieldFor] ? INPUTBOXERR : INPUTBOX
                        }
                        id={field?.fieldFor}
                        className="fw-normal fst-italic fs-11 w-100"
                      />
                    </div>
                    <div style={ERRBLOCK}>
                      {errors?.[field?.fieldFor] &&
                        (errors?.[field?.fieldFor].type === "required" ? (
                          <span
                            style={{
                              color: "rgb(139, 139, 85)",
                              fontSize: "0.6rem",
                            }}
                          >
                            This field is required !!
                          </span>
                        ) : errors?.[field?.fieldFor].type === "pattern" ? (
                          <span
                            style={{
                              color: "rgb(139, 139, 85)",
                              fontSize: "0.6rem",
                            }}
                          >
                            Invalid input !!
                          </span>
                        ) : null)}
                    </div>
                  </div>
                ) : null}
              </>
            );
          })}
          {bulkEmail != "" && (
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth variant="standard" color="secondary">
                <InputLabel id="demo-simple-select-label">
                  Choose mail template
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={template.mail_instance_id}
                  defaultValue={data.mail_instance_id}
                  onChange={handleChange}
                >
                  {template.map((option) => (
                    <MenuItem
                      key={option.mail_instance_id}
                      value={option.mail_instance_id}
                    >
                      {option.template_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <EmailEditor
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={templateDesign?.body}
            init={{
              height: size.height - 150,
              menubar: false,
              convert_urls: false,
              plugins: [
                "advlist",
                "anchor",
                "autolink",
                "help",
                "image",
                "link",
                "lists",
                "searchreplace",
                "table",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </form>
        <button
          type="submit"
          form="email-share"
          className="d-flex justify-content-center align-items-center btn btn-primary w-100 mt-2"
        >
          <p className="m-0 me-2 fw-semibold fs-10">SEND</p>
          {shareState?.loader === true ? (
            <div class="spinner-border text-light" role="status"></div>
          ) : null}
        </button>
      </div>
    </>
  );
};

export default ShareEmail;
