import React from "react";
import "./DashboardCard.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { IoIosPeople } from "react-icons/io";
const DashboardCard = () => {
  const remindersDone = 3;
  const totalReminders = 6;
  const daysLeft = 3;
  const relationshipsManaged = 9;
  const newRelationships = 5;

  const percentage = (remindersDone / totalReminders) * 100;

  return (
    <div className="dashboard-card ">
      <div className="d-flex ">
        <div
          className="me-5 justify-content-center align-items-center"
          style={{ width: "8rem" }}
        >
          <CircularProgressbar
            value={percentage}
            text={`${remindersDone}/${totalReminders}`}
            styles={buildStyles({
              textSize: "16px",
              pathColor: "#6610f2",
              textColor: "#6610f2",
              trailColor: "#e0e0e0",
            })}
          />
          <div className="d-flex justify-content-center">
            <span className="days-left">{daysLeft} days left</span>
          </div>
        </div>
        <div className="relationships-section d-flex justify-content-center">
          <IoIosPeople size={40} color="#6610f2" />
          <div className="relationships-number">
            <span className="number">{relationshipsManaged}</span>
            <span className="new-relationships">+{newRelationships}</span>
          </div>
          <p
            className="fs-8 fw-light pe-6 pt-1 lh-1 mt-1"
            style={{ color: " #7a7a7a" }}
          >
            Relationships Managed
          </p>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-center btn btn-primary mt-3  fw-semibold ">
        Manage Relationship
      </div>
    </div>
  );
};

export default DashboardCard;
