import React, { useState } from "react";
import { FaCheck, FaChevronLeft, FaRegCalendarAlt, FaTimes } from 'react-icons/fa';
import { FaCheckCircle, FaRedo, FaPhoneSlash, FaUsers, FaShareAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Menu } from "../functions/menu";
import { Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
function MeetingStatus() {
    const [meetingStatus, setMeetingStatus] = useState('');
    const [outcome, setOutcome] = useState('');
    const [relationshipBuilder, setRelationshipBuilder] = useState(false);
    const [nextMeeting, setNextMeeting] = useState('');
    const [reasonCancelled, setReasonCancelled] = useState('');
    const [rescheduledDate, setRescheduledDate] = useState('');
  
    const handleMeetingStatusChange = (event) => {
      setMeetingStatus(event.target.value);
    };
  
    const handleOutcomeChange = (event) => {
      setOutcome(event.target.value);
    };
  
    const handleRelationshipBuilderChange = (event) => {
      setRelationshipBuilder(event.target.checked);
    };
  
    const handleNextMeetingChange = (event) => {
      setNextMeeting(event.target.value);
    };
  
    const handleReasonCancelledChange = (event) => {
      setReasonCancelled(event.target.value);
    };
  
    const handleRescheduledDateChange = (event) => {
      setRescheduledDate(event.target.value);
    };
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
  return <div>
     <div className="container-fluid d-flex flex-column justify-content-between" style={{ minHeight: "100vh" }}>
            <div className="d-flex justify-content-between align-items-center bg-primary text-white p-3">
                <FaChevronLeft size={25} onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
                <p className="m-0 fw-bold fs-4">Meeting Status</p>
                <p></p>
            </div>

            <div className="container-fluid p-4 bg-light ">
      <form>
        <div className="form-group row mb-3">
          <label className=" col-form-label align-items-center">
            <FaRegCalendarAlt className="me-2" />
            <span>Meeting Status</span>
          </label>
          <div className="">
            <select className="form-select" value={meetingStatus} onChange={handleMeetingStatusChange}>
              <option value="">Select</option>
              <option value="done">Meeting Done</option>
              <option value="cancelled">Meeting Cancelled</option>
              <option value="rescheduled">Meeting Rescheduled</option>
            </select>
          </div>
        </div>

        {meetingStatus === 'done' && (
          <>
            <div className="form-group row mb-3">
              <label className=" col-form-label align-items-center">
                <FaCheck className="me-2" />
                <span>Outcome of the Meeting</span>
              </label>
              <div className="">
                <select className="form-select" value={outcome} onChange={handleOutcomeChange}>
                  <option value="">Select</option>
                  <option value="introduction">Introduction</option>
                  <option value="detailed">Detailed</option>
                  <option value="not_worth">Not Worth</option>
                  <option value="messed_up">Messed Up</option>
                </select>
              </div>
            </div>

            <div className="form-group row mb-3">
              <div className=" align-items-center">
                <input 
                  className="form-check-input me-2" 
                  type="checkbox" 
                  checked={relationshipBuilder} 
                  onChange={handleRelationshipBuilderChange}
                  id="relationshipBuilder"
                />
                <label className="form-check-label" htmlFor="relationshipBuilder">
                  Add to Relationship Builder?
                </label>
              </div>
              <div className="">
                {(relationshipBuilder || true) && (
                  <select className="form-select" value={nextMeeting} onChange={handleNextMeetingChange}>
                    <option value="">Select</option>
                    <option value="15_mins">15 mins touch up</option>
                    <option value="30_mins">30 mins brief</option>
                    <option value="1_hour">1 hour discussion</option>
                  </select>
                )}
              </div>
               <div className="d-flex">
             <Button variant="primary" type="submit" className="mt-2">
                    Done 
                </Button>
                </div>
            </div>
          </>
        )}

        {meetingStatus === 'cancelled' && (
          <div className="form-group row mb-3">
            <label className=" col-form-label align-items-center">
              <FaTimes className="me-2" />
              <span>Reason for Cancellation</span>
            </label>
            <div className="">
              <select className="form-select" value={reasonCancelled} onChange={handleReasonCancelledChange}>
                <option value="">Select</option>
                <option value="not_interested">Not interested (Declined)</option>
                <option value="interested_but_not_available">Interested But Not available</option>
                <option value="revisit_later">Revisit Later</option>
                <option value="busy">Busy</option>
                <option value="unplanned">Unplanned</option>
                <option value="scheduling_conflicts">Scheduling conflicts</option>
              </select>
            </div>
              <div className="d-flex">
             <Button variant="primary" type="submit" className="mt-2">
                    Done 
                </Button>
                </div>
          </div>
        )}

        {meetingStatus === 'rescheduled' && (
          <div className="form-group row mb-3">
            <label className=" col-form-label align-items-center">
              <FaRedo className="me-2" />
              <span>Next Meeting Date</span>
            </label>
            <div className="">
              {/* <input 
                className="form-control" 
                type="date" 
                value={rescheduledDate} 
                onChange={handleRescheduledDateChange} 
              /> */}
                           <ReactDatePicker
                         className="form-control"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      
    //   excludeTimes={[
    //     setHours(setMinutes(new Date(), 0), 17),
    //     setHours(setMinutes(new Date(), 30), 18),
    //     setHours(setMinutes(new Date(), 30), 19),
    //     setHours(setMinutes(new Date(), 30), 17),
    //   ]}
      dateFormat="MMMM d, yyyy"
    />
            </div>
            <div className="d-flex">
              <div className="d-flex">
             <Button variant="primary" type="submit" className="mt-2">
                    Done 
                </Button>
                </div>
                </div>
          </div>
        )}
      </form>
    </div>

            <div>
                <Menu/>
            </div>
        </div>
    
</div>;
}

export default MeetingStatus;
