import React, { memo, useEffect } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { useDateTime, useQRScanData } from "../../../functions/functions";
import vCard from "vcf";
//API SERVICES
import { HistoryForQrcode_API } from "../../../services/userservices";
import { t } from "i18next";

export const Scanner = memo(({ dependencies: { state, setState } }) => {
  const config = { fps: 10, qrbox: { width: 300, height: 300 } };
  const date = useDateTime();
  const EXHIBITOR_ID = JSON.parse(localStorage.getItem("exhibitor_id"));

  const AddToHistory_API_Fn = (url) => {
    const data = useQRScanData(url);
    console.log(data);
    const PayloadFormData = new FormData();
    PayloadFormData.append("action", "add_contact_qrcode");
    PayloadFormData.append("url", url);
    PayloadFormData.append("data", JSON.stringify(data?.content));
    PayloadFormData.append("card_type", data?.dataType);
    PayloadFormData.append("exhibitor_id", EXHIBITOR_ID);
    HistoryForQrcode_API(PayloadFormData).then((res) => {
      // console.log(res);
    });
  };

  useEffect(() => {
    let htmlqrscanner = new Html5Qrcode("reader");

    htmlqrscanner.start(
      { facingMode: "environment" },
      config,
      (decodedText, decodedResult) => {
        console.log(decodedText, "decodedText");
        console.log(decodedResult, "decodedResult");
        AddToHistory_API_Fn(decodedText);
        htmlqrscanner.stop();
        setState({
          type: "UPDATE_SCANNED_LINK",
          url: decodedText,
          // data: decodedResult,
          data: "",
          date: `${date?.getDate + " " + date?.getMonth + " " + date?.getYear}`,
          time: `${date?.getTime}`,
        });
      },
      (errorMessage) => {
        // console.warn(`Code scan error = ${errorMessage}`);
      },
    );

  }, []);

  return (
   <div class="position-relative">
      <div
        className="d-block w-100 mx-auto"
        id="reader"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
        }}
      ></div>
      <small class="position-absolute bottom-0 start-50 translate-middle-x text-light border p-2 rounded mb-5">{t('qrMenu.tap')}</small>
    </div>
  );
});
