import React, { useRef, useEffect, useState } from "react";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { BiImageAdd, BiUpload } from "react-icons/bi";
import { BsCardImage, BsFillPencilFill } from "react-icons/bs";
import { FaWindowClose } from "react-icons/fa";
import none from "../../../src/none.jpg";

//API
import {
  NewBusinessCard_API,
  DisplayLeads_API,
} from "../../services/userservices";

export const BusinessCardScreen = () => {
  const CARD_DETAILS = {
    FRONT_IMAGE: "Front",
    BACK_IMAGE: "Back",
  };

  const ALERTS = {
    WARNING: "warning",
    SUCCESS: "success",
    DELETE: "delete",
    UPLOAD: "upload",
  };

  const EXHIBITOR_ID = JSON.parse(localStorage.getItem("exhibitor_id"));
  const Bcardbackimg = useRef(null);
  const Bcardfrontimg = useRef(null);
  const Bcard_file_frontimage = useRef(null);
  const Bcard_file_backimage = useRef(null);
  const Alert_warning = useRef(null);
  const Alert_success = useRef(null);

  const [showFrontImageEditOption, setShowFrontImageEditOption] = useState({
    status: false,
  });
  const [showBackImageEditOption, setShowBackImageEditOption] = useState({
    status: false,
  });
  const [showAlert, setShowAlert] = useState({ Alert_Type: "none" });
  const [profiledata, setProfileData] = useState("");
  const [frontImageFile, setFrontImageFile] = useState("");
  const [backImageFile, setBackImageFile] = useState("");

  useEffect(() => {
    let _obj = JSON.parse(localStorage.getItem("EditLeads"));
    let _getleadsobj = JSON.parse(localStorage.getItem("DisplayLeads"));
    // Bcardbackimg.current.src =
    //   _obj.data.business_card_back_page != null &&
    //   _obj.data.business_card_back_page != ''
    //     ? _obj.data.business_card_back_page
    //     : none;
    // Bcardfrontimg.current.src =
    //   _obj.data.business_card_front_page != null &&
    //   _obj.data.business_card_front_page != ''
    //     ? _obj.data.business_card_front_page
    //     : none;
    Bcardbackimg.current.src =
      _getleadsobj[_obj.data.email_address].business_card_back_page != null &&
      _getleadsobj[_obj.data.email_address].business_card_back_page != ""
        ? _getleadsobj[_obj.data.email_address].business_card_back_page
        : none;
    Bcardfrontimg.current.src =
      _getleadsobj[_obj.data.email_address].business_card_front_page != null &&
      _getleadsobj[_obj.data.email_address].business_card_front_page != ""
        ? _getleadsobj[_obj.data.email_address].business_card_front_page
        : none;
    setProfileData(_obj.data);
    //validation
    // if (
    //   _obj.data.business_card_front_page != null &&
    //   _obj.data.business_card_front_page != ''
    // ) {
    //   setShowFrontImageEditOption({ status: true });
    // } else {
    //   setShowFrontImageEditOption({ status: false });
    // }

    // if (
    //   _obj.data.business_card_back_page != null &&
    //   _obj.data.business_card_back_page != ''
    // ) {
    //   setShowBackImageEditOption({ status: true });
    // } else {
    //   setShowBackImageEditOption({ status: false });
    // }

    if (
      _getleadsobj[_obj.data.email_address].business_card_front_page != null &&
      _getleadsobj[_obj.data.email_address].business_card_front_page != ""
    ) {
      setShowFrontImageEditOption({ status: true });
    } else {
      setShowFrontImageEditOption({ status: false });
    }

    if (
      _getleadsobj[_obj.data.email_address].business_card_back_page != null &&
      _getleadsobj[_obj.data.email_address].business_card_back_page != ""
    ) {
      setShowBackImageEditOption({ status: true });
    } else {
      setShowBackImageEditOption({ status: false });
    }
  }, []);

  const BusinessCardUpload_Fn = () => {
    const PayloadFormData = new FormData();
    PayloadFormData.append("action", "update_business_cards");
    PayloadFormData.append("business_card_fpage", frontImageFile);
    PayloadFormData.append("business_card_bpage", backImageFile);
    PayloadFormData.append("exhibitor_id", EXHIBITOR_ID);
    PayloadFormData.append("contact_id", profiledata.contact_id);
    PayloadFormData.append("first_name", profiledata.first_name);
    NewBusinessCard_API(PayloadFormData).then((res) => {
      if (res.status.localeCompare("success") === 0) {
        //-------------------------------------------------Update Contact - Fetch------------------------------------------------//
        let Dpayload = {
          id: EXHIBITOR_ID,
        };

        DisplayLeads_API(Dpayload).then((res) => {
          if (res.status.localeCompare("success") == 0) {
            let leads_data = {};
            res.data.map((leads) => {
              leads_data[leads.data.email_address] = leads.data;

              // let _obj = JSON.parse(localStorage.getItem('EditLeads'));
              // if (
              //   leads.data.email_address.localeCompare(
              //     _obj.data.email_address
              //   ) == 0
              // ) {
              //   _obj.data.business_card_front_page =
              //     leads.data.business_card_front_page;
              //   _obj.data.business_card_back_page =
              //     leads.data.business_card_back_page;
              //   localStorage.setItem('EditLeads', JSON.stringify(_obj));
              // }
            });
            localStorage.setItem("DisplayLeads", JSON.stringify(leads_data));
            setShowAlert({ Alert_Type: ALERTS.SUCCESS, Option: ALERTS.UPLOAD });
            document.querySelector("#bcardform").reset();
          }
        });
        //-------------------------------------------------Update Contact - Fetch------------------------------------------------//
      } else {
        setShowAlert({ Alert_Type: ALERTS.WARNING });
        document.querySelector("#bcardform").reset();
      }
    });
  };

  const BusinessCardDelete_Fn = (TYPE) => {
    let Frontimg_name = "",
      Backimg_name = "";
    if (TYPE === CARD_DETAILS.FRONT_IMAGE) {
      Frontimg_name = Bcardfrontimg.current.src.replace(/^.*[\\\/]/, "");
    } else if (TYPE === CARD_DETAILS.BACK_IMAGE) {
      Backimg_name = Bcardfrontimg.current.src.replace(/^.*[\\\/]/, "");
    }

    const PayloadFormData = new FormData();
    PayloadFormData.append("action", "delete_business_card");
    PayloadFormData.append("business_card_fpage", Frontimg_name);
    PayloadFormData.append("business_card_bpage", Backimg_name);
    PayloadFormData.append("exhibitor_id", EXHIBITOR_ID);
    PayloadFormData.append("contact_id", profiledata.contact_id);
    NewBusinessCard_API(PayloadFormData).then((res) => {
      if (res.status.localeCompare("success") === 0) {
        //-------------------------------------------------Update Contact - Fetch------------------------------------------------//
        let Dpayload = {
          id: EXHIBITOR_ID,
        };

        DisplayLeads_API(Dpayload).then((res) => {
          if (res.status.localeCompare("success") == 0) {
            let leads_data = {};
            res.data.map((leads) => {
              leads_data[leads.data.email_address] = leads.data;

              // let _obj = JSON.parse(localStorage.getItem('EditLeads'));
              // if (
              //   leads.data.email_address.localeCompare(
              //     _obj.data.email_address
              //   ) == 0
              // ) {
              //   _obj.data.business_card_front_page =
              //     leads.data.business_card_front_page;
              //   _obj.data.business_card_back_page =
              //     leads.data.business_card_back_page;
              //   localStorage.setItem('EditLeads', JSON.stringify(_obj));
              // }
            });
            localStorage.setItem("DisplayLeads", JSON.stringify(leads_data));

            document.querySelector("#bcardform").reset();
            if (TYPE === CARD_DETAILS.FRONT_IMAGE) {
              setShowFrontImageEditOption({ status: false });
            } else if (TYPE === CARD_DETAILS.BACK_IMAGE) {
              setShowBackImageEditOption({ status: false });
            }
            setShowAlert({ Alert_Type: ALERTS.SUCCESS, Option: ALERTS.DELETE });
          }
        });
        //-------------------------------------------------Update Contact - Fetch------------------------------------------------//
      } else {
        setShowAlert({ Alert_Type: ALERTS.WARNING });
        document.querySelector("#bcardform").reset();
      }
    });
  };

  const BrowseImage_Fn = (event, BrowseType) => {
    if (BrowseType === CARD_DETAILS.FRONT_IMAGE) {
      setFrontImageFile(event.target.files[0]);
      Bcardfrontimg.current.src = URL.createObjectURL(event.target.files[0]);
      Bcardfrontimg.current.onload = function () {
        URL.revokeObjectURL(Bcardfrontimg.current.src); // free memory space
      };
    } else if (BrowseType === CARD_DETAILS.BACK_IMAGE) {
      setBackImageFile(event.target.files[0]);
      Bcardbackimg.current.src = URL.createObjectURL(event.target.files[0]);
      Bcardbackimg.current.onload = function () {
        URL.revokeObjectURL(Bcardbackimg.current.src); // free memory space
      };
    }
  };

  const Cancelimage_Fn = (BrowseType) => {
    if (BrowseType === CARD_DETAILS.FRONT_IMAGE) {
      BusinessCardDelete_Fn(CARD_DETAILS.FRONT_IMAGE);
    } else if (BrowseType === CARD_DETAILS.BACK_IMAGE) {
      BusinessCardDelete_Fn(CARD_DETAILS.BACK_IMAGE);
    }
  };

  const AlertDisplay_fn = (TYPE) => {
    if (TYPE === ALERTS.SUCCESS) {
      const timer = setTimeout(() => {
        Alert_success.current.style.display = "none";
        clearTimeout(timer);
        setShowAlert({ Alert_Type: "none" });
      }, 3000);
    } else if (TYPE === ALERTS.WARNING) {
      const timer = setTimeout(() => {
        Alert_warning.current.style.display = "none";
        clearTimeout(timer);
        setShowAlert({ Alert_Type: "none" });
      }, 5000);
    }
  };

  useEffect(() => {
    AlertDisplay_fn(showAlert.Alert_Type);
  }, [showAlert]);

  useEffect(() => {
    if (frontImageFile !== "") {
      BusinessCardUpload_Fn();
      setShowFrontImageEditOption({ status: true });
    }
  }, [frontImageFile]);

  useEffect(() => {
    if (backImageFile !== "") {
      BusinessCardUpload_Fn();
      setShowBackImageEditOption({ status: true });
    }
  }, [backImageFile]);

  return (
    <>
      <div className="d-flex w-100 justify-content-center">
        <form
          className="d-flex flex-column w-100 px-3 my-3 "
          id="bcardform"
          style={{ gap: "20px", maxWidth: "400px", overflowY: "auto" }}
        >
          <div
            ref={Alert_success}
            className="fs-11 alert alert-success"
            style={{
              display: `${
                showAlert.Alert_Type === ALERTS.SUCCESS ? "block" : "none"
              }`,
            }}
          >
            <strong>
              {showAlert.Option === ALERTS.UPLOAD
                ? "Business Card image uploaded successfully.."
                : "Deleted successfully"}
            </strong>
          </div>
          <div
            ref={Alert_warning}
            className="fs-11 alert alert-warning"
            style={{
              display: `${
                showAlert.Alert_Type === ALERTS.WARNING ? "block" : "none"
              }`,
            }}
          >
            <strong>Image uploading error!..</strong>
          </div>

          <div
            className="form-group d-flex flex-column border border-2 rounded-2 px-3 py-3"
            style={{ gap: "10px" }}
          >
            <div
              className="d-flex justify-content-start align-items-center"
              style={{ gap: "10px" }}
            >
              <BsCardImage size={20} className="text-dark" />
              <p className="mb-0 fw-semibold fs-11 text-secondary ">
                BUSINESS CARD IMAGE - FRONT
              </p>
            </div>

            <label
              htmlFor="frontimage"
              className={`${
                showFrontImageEditOption.status == true ? "d-none" : "d-flex"
              } w-auto align-items-center p-2 bg-primary rounded-1 shadow`}
              style={{ gap: "5px", cursor: "pointer" }}
            >
              <BiImageAdd size={25} className="text-white" />
              <p className="mb-0 fw-normal w-auto text-white fs-10">SELECT</p>
            </label>
            <button
              type="button"
              className="btn btn-outline-primary fs-11 fw-semibold"
              style={{
                display: `${
                  showFrontImageEditOption.click_status == true
                    ? "block"
                    : "none"
                }`,
              }}
              onClick={() => {
                setShowFrontImageEditOption({
                  status: true,
                  click_status: false,
                });
              }}
            >
              CANCEL
            </button>
            <input
              ref={Bcard_file_frontimage}
              type="file"
              className="form-control"
              id="frontimage"
              name="frontimage"
              accept="image/*"
              style={{ display: "none", visibility: "none" }}
              onChange={(event) =>
                BrowseImage_Fn(event, CARD_DETAILS.FRONT_IMAGE)
              }
            />
            <div className="d-flex justify-content-center">
              <img
                ref={Bcardfrontimg}
                className="rounded-2 "
                height="auto"
                width="auto"
                src={none}
                style={{
                  maxWidth: "86%",
                  display: `${
                    showFrontImageEditOption.status == true ? "block" : "none"
                  }`,
                }}
                alt="front"
              ></img>
              <div
                className="d-flex flex-column justify-content-center align-items-center px-2"
                style={{ gap: "30px" }}
              >
                <AiFillCloseCircle
                  size={25}
                  style={{
                    cursor: "pointer",
                    display: `${
                      showFrontImageEditOption.status == true ? "block" : "none"
                    }`,
                  }}
                  className="text-dark fw-semibold shadow-lg"
                  onClick={() => Cancelimage_Fn(CARD_DETAILS.FRONT_IMAGE)}
                />
                <BsFillPencilFill
                  style={{
                    cursor: "pointer",
                    display: `${
                      showFrontImageEditOption.status == true ? "block" : "none"
                    }`,
                  }}
                  className="text-dark"
                  onClick={() => {
                    setShowFrontImageEditOption({
                      status: false,
                      click_status: true,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="form-group d-flex flex-column border border-2 rounded-2 px-3 py-3"
            style={{ gap: "10px" }}
          >
            <div
              className="d-flex justify-content-start align-items-center"
              style={{ gap: "10px" }}
            >
              <BsCardImage size={20} className="text-dark" />
              <p className="mb-0 fw-semibold fs-11 text-secondary ">
                BUSINESS CARD IMAGE - BACK
              </p>
            </div>

            <label
              htmlFor="backimage"
              className={`${
                showBackImageEditOption.status == true ? "d-none" : "d-flex"
              } w-auto align-items-center p-2 bg-primary rounded-1 shadow`}
              style={{ gap: "5px", cursor: "pointer" }}
            >
              <BiImageAdd size={25} className="text-white" />
              <p className="mb-0 fw-normal w-auto text-white fs-10">SELECT</p>
            </label>
            <button
              type="button"
              className="btn btn-outline-primary fs-11 fw-semibold"
              style={{
                display: `${
                  showBackImageEditOption.click_status == true
                    ? "block"
                    : "none"
                }`,
              }}
              onClick={() => {
                setShowBackImageEditOption({
                  status: true,
                  click_status: false,
                });
              }}
            >
              CANCEL
            </button>
            <input
              ref={Bcard_file_backimage}
              type="file"
              className="form-control"
              id="backimage"
              name="backimage"
              accept="image/*"
              style={{ display: "none", visibility: "none" }}
              onChange={(event) =>
                BrowseImage_Fn(event, CARD_DETAILS.BACK_IMAGE)
              }
            />
            <div className="d-flex justify-content-center">
              <img
                ref={Bcardbackimg}
                className="rounded-2"
                height="auto"
                width="auto"
                src={none}
                style={{
                  maxWidth: "86%",
                  display: `${
                    showBackImageEditOption.status == true ? "block" : "none"
                  }`,
                }}
                alt="front"
              ></img>
              <div
                className="d-flex flex-column justify-content-center align-items-center px-2"
                style={{ gap: "30px" }}
              >
                <AiFillCloseCircle
                  size={25}
                  style={{
                    cursor: "pointer",
                    display: `${
                      showBackImageEditOption.status == true ? "block" : "none"
                    }`,
                  }}
                  className="text-dark fw-semibold shadow-lg"
                  onClick={() => Cancelimage_Fn(CARD_DETAILS.BACK_IMAGE)}
                />
                <BsFillPencilFill
                  style={{
                    cursor: "pointer",
                    display: `${
                      showBackImageEditOption.status == true ? "block" : "none"
                    }`,
                  }}
                  className="text-dark"
                  onClick={() => {
                    setShowBackImageEditOption({
                      status: false,
                      click_status: true,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
