import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function MuiAutoComplete() {
  return (
    <Autocomplete
      multiple
      limitTags={7}
      size="small"
      filterSelectedOptions
      id="multiple-limit-tags"
      color='secondary'
      options={top100Films}
      getOptionLabel={(option) => option.title}
      defaultValue={[top100Films[1], top100Films[2], top100Films[3],top100Films[4],top100Films[5],top100Films[6],top100Films[7],top100Films[0]]}
      renderInput={(params) => (
        <TextField {...params}   color='secondary'/>
      )}
    //   sx={{ width: '500px' }}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: 'Ram', year: 1994 },
  { title: 'Joe', year: 1972 },
  { title: 'Siva', year: 1974 },
  { title: 'prakash', year: 2008 },
  { title: 'priya', year: 1957 },
  { title: "raja", year: 1993 },
  { title: "dinesh", year: 1993 },
  { title: "devi", year: 1993 },
//   { title: "rani", year: 1993 },
//   { title: "sheha", year: 1993 },
//   { title: "pooja", year: 1993 },
 

];
