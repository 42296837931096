import React, { useState } from "react";
import "./FileUpload.css";
import FileItem from "./FileItem";
import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material";

const FileUpload = () => {
  const sampleFiles = [
    new File(["Sample content"], "sample_file_1.pdf", {
      type: "application/pdf",
    }),
    new File(["Another content"], "sample_file_2.docx", {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    }),
    new File(["Yet another content"], "sample_file_3.txt", {
      type: "text/plain",
    }),
  ];

  const [files, setFiles] = useState(sampleFiles);
  const [selectedFiles, setSelectedFiles] = useState(
    sampleFiles.reduce((acc, file) => {
      acc[file.name] = false;
      return acc;
    }, {})
  );
  const [allSelected, setAllSelected] = useState(false);

  const handleFileChange = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setFiles(uploadedFiles);
    const initialSelection = {};
    uploadedFiles.forEach((file) => {
      initialSelection[file.name] = false;
    });
    setSelectedFiles(initialSelection);
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleToggleSelectAll = () => {
    const newSelectionState = !allSelected;
    const updatedSelection = {};
    files.forEach((file) => {
      updatedSelection[file.name] = newSelectionState;
    });
    setSelectedFiles(updatedSelection);
    setAllSelected(newSelectionState);
  };

  const toggleFileSelection = (fileName) => {
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [fileName]: !prevSelectedFiles[fileName],
    }));
  };

  const navigate = useNavigate();

  const handleBulkShareClick = () => {
    const filesToShare = files.filter((file) => selectedFiles[file.name]);
    if (filesToShare.length === 0) {
      alert("No files selected for sharing.");
      return;
    }
    navigate(`/bulk-share`, { state: { files: filesToShare } });
  };

  const selectedCount = Object.values(selectedFiles).filter(Boolean).length;

  return (
    <div className="file-upload">
      <div className="d-flex justify-content-between" style={{ width: "100%" }}>
        <div className="file-selector">
          <button
            onClick={handleToggleSelectAll}
            className="toggle-select-button select-all-button"
          >
            {allSelected ? "Deselect All" : "Select All"}
          </button>
        </div>
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          multiple
          onChange={handleFileChange}
        />
        <button onClick={handleButtonClick} className="add-files-button">
          Add Files
        </button>
      </div>
      <div className="d-flex justify-content-center">
        <ul className="file-list w-100">
          {files.map((file) => (
            <FileItem
              key={file.name}
              file={file}
              isSelected={selectedFiles[file.name]}
              onToggleSelect={toggleFileSelection}
            />
          ))}
        </ul>
      </div>

      {selectedCount > 0 && (
        <div
          id="popdiv"
          className="position-absolute bottom-0 start-50 translate-middle-x bg-white shadow-lg p-3 mb-5 bg-white rounded "
        >
          <p className="mb-0 ms-2 mt-2">{selectedCount} selected</p>
          <div className="p-2 d-flex flex-wrap">
            <Chip
              label="Bulk Share"
              variant="outlined"
              className="m-1"
              onClick={handleBulkShareClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
