import React from "react";
import { useNavigate } from "react-router-dom";

const FileItem = ({ file, isSelected, onToggleSelect }) => {
  const navigate = useNavigate();

  const handleShareClick = () => {
    navigate(`/file-details/${file.name}`, { state: { file } });
  };

  return (
    <li className={`file-item d-flex ${isSelected ? "selected" : ""}`}>
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => onToggleSelect(file.name)}
      />
      <div className="file-info px-4">
        <span className="file-name text-break">{file.name}</span>
        <span className="file-details text-break">
          Type: {file.type || "Unknown"}, Size:{" "}
          {(file.size / (1024 * 1024)).toFixed(2)} MB
        </span>
      </div>
      <button
        className="ms-auto p-2 share-button btn btn-outline-primary"
        onClick={handleShareClick}
      >
        Share
      </button>
    </li>
  );
};

export default FileItem;
