import {
  DISPLAY_CARDS,
  CHOOSE_SPECIFIC_CARD,
  SET_PRESENT_DISPLAY,
} from "../../../state_services/actionTypes/businesscard/CardsActionTypes";

const FetchCards = (CardsList) => {
  return {
    type: DISPLAY_CARDS,
    Cardsdetails: CardsList,
  };
};

const ChooseSpecificCard = (object) => {
  return {
    type: CHOOSE_SPECIFIC_CARD,
    ProfileUrl: object.url,
    ChoosedCardIndex: object.index,
  };
};

const SetPresentDisplay = (display) => {
  return {
    type: SET_PRESENT_DISPLAY,
    DISPLAY: display,
  };
};

export { FetchCards, ChooseSpecificCard, SetPresentDisplay };
