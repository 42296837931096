import React, { memo, useEffect, useState, useRef, useCallback } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { Modal } from "../../../functions/Modal";
import { useForm } from "react-hook-form";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { List_API, NewBusinessCard_API } from "../../../services/userservices";
import { toast } from "react-toastify";
import Chip from "@mui/material/Chip";
export const OCRLists = memo(({ addLists, setAddLists }) => {
  const SearchContact = useRef(null);
  const DisplayAllLists = useRef(null);
  const [allLists, setAllLists] = useState([]);
  const [searchContactField, setSearchContactField] = useState("");

  let CALLBACK_VAR_LISTS = addLists?.Lists;
  let CALLBACK_VAR_LISTS_IDS = addLists?.OnlyListIds;
  const SelectionList = useCallback(() => {
    let filteredListName = [];

    if (addLists?.Lists.length > 0 && addLists?.OnlyListIds.length > 0) {
      JSON.parse(localStorage.getItem("Lists")).filter((list) => {
        if (!addLists?.OnlyListIds?.includes(list?.list_id)) {
          filteredListName?.push(list);
        }
      });
    } else {
      filteredListName = JSON.parse(localStorage.getItem("Lists"));
    }
    return filteredListName;
  }, [CALLBACK_VAR_LISTS, CALLBACK_VAR_LISTS_IDS]);

  let EFFECT_UPDATE_LISTS_ONLY_ID = addLists?.Lists;

  useEffect(() => {
    let value = () => {
      let onlyID = [];
      if (addLists?.Lists.length > 0) {
        [...addLists?.Lists].filter((list) => {
          onlyID.push(list?.list_id);
        });
      }
      return onlyID;
    };
    let valueName = () => {
      let onlyName = [];
      if (addLists?.Lists.length > 0) {
        [...addLists?.Lists].filter((list) => {
          onlyName.push(list?.list_name);
        });
      }
      return onlyName;
    };
    setAddLists((previousState) => ({
      ...previousState,
      OnlyListIds: value(),
      OnlyListNames: valueName(),
    }));
  }, [EFFECT_UPDATE_LISTS_ONLY_ID]);

  useEffect(() => {
    setAllLists(SelectionList());
  }, []);

  useEffect(() => {
    if (searchContactField !== "") {
      let filteredListName = SelectionList()?.filter((filteredList) => {
        return filteredList?.list_name
          .toLowerCase()
          .includes(searchContactField.toLowerCase());
      });
      setAllLists([...filteredListName]);
    } else {
      setAllLists(SelectionList());
    }
  }, [searchContactField]);

  function RemoveList(Checking_List) {
    let filteredList = addLists?.Lists.filter((list) => {
      return !(Checking_List?.list_id).includes(list?.list_id);
    });
    setAddLists((previousState) => ({ ...previousState, Lists: filteredList }));
    setAllLists((previousState) => [...previousState, Checking_List]);
  }

  return (
    <>
      <div>
        <div>
          <div
            className="d-flex flex-column flex-start  py-2 px-0"
            style={{ gap: "5px" }}
          >
            {/*------------------------ Display the lists that are added to the contact and also newly added through selection box------------------------------------  */}
            <div
              className="d-flex justify-content-start flex-wrap"
              style={{ gap: "10px" }}
            >
              {addLists?.Lists.map((list, index) => {
                return (
                  <Chip
                    label={list?.list_name}
                    color="secondary"
                    sx={{
                      bgcolor: `${
                        list?.list_color != "" ? list?.list_color : "#5411dc"
                      }`,
                      color: "white",
                      padding: "10px",
                    }}
                    onDelete={() => {
                      RemoveList(list);
                    }}
                  />
                  // <p
                  //   className="d-flex  align-items-center mb-0 px-2 py-1 text-white fw-semibold fs-10 rounded"
                  //   style={{
                  //     backgroundColor: `${
                  //       list?.list_color != '' ? list?.list_color : '#5411dc'
                  //     }`,
                  //     gap: '5px',
                  //   }}
                  //   key={index}>
                  //   {list?.list_name}
                  //   <AiOutlineCloseSquare
                  //     size={20}
                  //     onClick={() => {
                  //       RemoveList(list);
                  //     }}
                  //   />
                  // </p>
                );
              })}
            </div>
            {/*------------------------ Display the lists that are added to the contact and also newly added through selection box------------------------------------  */}

            {/* ----------------------------------Search lists from filtered all lists from selection box below -----------------------------------------*/}
            {/* <input
              className="form-control fs-10"
              ref={SearchContact}
              placeholder="Add"
              onChange={(evt) => {
                setSearchContactField(evt.target.value);
              }}></input> */}
            {/* ----------------------------------Search lists from filtered all lists from selection box below -----------------------------------------*/}

            {/*---------------------------- Display All lists filtered with exception from lists that are added already to the contact --------------------------*/}
            {allLists.length > 0 && (
              <div
                className="border rounded-2 px-3 p-2"
                style={{
                  maxHeight: "150px",
                  overflowY: "scroll",
                  display: "block",
                }}
                ref={DisplayAllLists}
              >
                {allLists.length > 0 &&
                  allLists?.map((filteredList) => {
                    return (
                      <>
                        <Chip
                          label={filteredList?.list_name}
                          sx={{
                            bgcolor: `${
                              filteredList?.list_color != ""
                                ? filteredList?.list_color
                                : "#5411dc"
                            }`,
                            color: "white",
                            padding: "10px",
                            "&:hover": {
                              bgcolor: `${
                                filteredList?.list_color !== ""
                                  ? filteredList?.list_color
                                  : "#5411dc"
                              }`,
                            },
                          }}
                          onClick={(e) => {
                            let value = allLists.filter((list) => {
                              return list?.list_id != filteredList?.list_id;
                            });

                            setAllLists([...value]);
                            setAddLists((previousState) => ({
                              ...previousState,
                              Lists: [...previousState?.Lists, filteredList],
                            }));
                          }}
                        />
                        {/* <p
                        role="button"
                        className="fw-semibold text-secondary fs-10"
                        onClick={(e) => {
                          let value = allLists.filter((list) => {
                            return list?.list_id != filteredList?.list_id;
                          });

                          setAllLists([...value]);
                          setAddLists((previousState) => ({
                            ...previousState,
                            Lists: [...previousState?.Lists, filteredList],
                          }));
                        }}>
                        {filteredList?.list_name}
                      </p> */}
                      </>
                    );
                  })}
              </div>
            )}
            {/*---------------------------- Display All lists filtered with exception from lists that are added already to the contact --------------------------*/}
          </div>
        </div>
      </div>
    </>
  );
});
