import React from "react";
import { Link, useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import "./FileUpload.css";
import { FaChevronLeft, FaFileDownload, FaCopy } from "react-icons/fa";

import { IoShareSocialSharp } from "react-icons/io5";

const FileDetails = () => {
  const navigate = useNavigate();

  return (
    <div className="file-details-container ">
      <div
        className="d-flex w-100 align-items-center justify-content-between p-2"
        style={{ backgroundColor: "#5411dc", color: "#ffff" }}
      >
        <FaChevronLeft
          size={25}
          onClick={() => {
            navigate("/card");
          }}
          className="text-white"
          style={{ cursor: "pointer" }}
        />
        <p className="fw-semibold fs-5 m-0"> {"File Details"}</p>
        <span className="d-flex " style={{ width: "20px" }}></span>
      </div>
      <div
        className="d-flex flex-column justify-content-start  bg-white rounded-2 w-100 "
        style={{ height: "90%" }}
      >
        <ul class="nav nav-tabs w-100">
          <li class="nav-item" style={{ cursor: "pointer" }}>
            <a class={`nav-link fw-semibold fs-10 `} aria-current="page">
              {"FILES"}
            </a>
          </li>

          <li class="nav-item" style={{ cursor: "pointer" }}>
            <a class={`nav-link fw-semibold fs-10 `} style={{ color: "black" }}>
              {"PROFILES"}
            </a>
          </li>
        </ul>
        {/* <div
              className="d-flex flex-column justify-content-start align-items-center overflow-y-auto w-100 "
              style={{ height: size.height }}
            >
              {BulkEmailState?.activeTab === "cards" ? (
                <CardsTab
                  data={BulkEmailState?.assets?.digital_cards}
                  state={BulkEmailState}
                  setState={BulkEmailDispatch}
                />
              ) : null}
              {BulkEmailState?.activeTab === "collections" ? (
                <CollectionsTab
                  data={BulkEmailState?.assets?.collections}
                  state={BulkEmailState}
                  setState={BulkEmailDispatch}
                />
              ) : null}
              {BulkEmailState?.activeTab === "tours" ? (
                <ToursTab
                  data={BulkEmailState?.assets?.virtual_tours}
                  state={BulkEmailState}
                  setState={BulkEmailDispatch}
                />
              ) : null}
              {BulkEmailState?.activeTab === "assets" ? (
                <AssetsTab
                  data={BulkEmailState?.assets?.assets}
                  state={BulkEmailState}
                  setState={BulkEmailDispatch}
                />
              ) : null}
            </div> */}
      </div>
      <div className="d-flex flex-column  align-items-center mt-5 vh-100">
        <p className="text-break text-center">
          <strong>Name:</strong> {"sample-file.pdf"}
        </p>
        <p>
          <strong>Type:</strong> {"pdf" || "Unknown"}
        </p>
        <p>
          <strong>Size:</strong> {"1MB"}
        </p>
        <div className="qr-code-container">
          <QRCode value={"www.google.com"} />
        </div>
        <div className="d-flex gap-1">
          <div className="d-flex align-items-center justify-content-center btn btn-primary mt-3  fw-semibold ">
            <Link style={{ textDecoration: "none", color: "#ffff" }} to="/card">
              <FaCopy />
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-center btn btn-primary mt-3  fw-semibold ">
            <Link style={{ textDecoration: "none", color: "#ffff" }} to="/card">
              PASSWORD
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-center btn btn-primary mt-3  fw-semibold ">
            <Link style={{ textDecoration: "none", color: "#ffff" }} to="/card">
              <FaFileDownload />
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-center btn btn-primary mt-3  fw-semibold ">
            <Link style={{ textDecoration: "none", color: "#ffff" }} to="/card">
              <IoShareSocialSharp />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileDetails;
