import React, { useState, useEffect } from "react";
import { Menu } from "../functions/menu";
import { FaEdit, FaRegCalendarAlt } from "react-icons/fa";
import { FaChevronLeft, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
// import { BiCheck, BiCheck } from "react-icons/fa";
import { BiCheck } from "react-icons/bi";
import DashboardCard from "./DashboardCard";
import { Button, Form, Toast } from "react-bootstrap";
import { Chip } from "@mui/material";
function Groups ()
{
  const navigate = useNavigate();
  const [ activeTab, setActiveTab ] = useState( "groups" );
  const [ relationship, setRelationship ] = useState( false );
  console.log( activeTab, "dfgdfgdfg" );
  const location = useLocation();
  const { key } = location.state || {};

  const handleTabClick = ( tab ) =>
  {
    setActiveTab( tab );
  };
  const todayReminders = [
    { id: 1, text: "Meeting with Bob", time: "10:00 AM" },
    { id: 2, text: "Call with Alice", time: "2:00 PM" },
  ];

  const weekReminders = [
    { id: 3, text: "Project Deadline", time: "Wednesday" },
    { id: 4, text: "Team Meeting", time: "Friday 3:00 PM" },
  ];

  const monthReminders = [
    { id: 5, text: "Monthly Report Submission", time: "June 25th" },
    { id: 6, text: "Client Presentation", time: "June 30th" },
  ];

  const handleSnooze = ( id ) =>
  {
    console.log( "Snooze reminder with ID:", id );
  };
  // Dummy data for contacts
  const [ contacts, setContacts ] = useState( [
    {
      id: 1,
      name: "Alice Smith",
      priority: "High",
      meetingFrequency: "Weekly",
    },
    {
      id: 2,
      name: "Bob Johnson",
      priority: "Medium",
      meetingFrequency: "Monthly",
    },
    {
      id: 3,
      name: "Charlie Brown",
      priority: "Low",
      meetingFrequency: "Quarterly",
    },
  ] );

  // Function to add a new contact
  // const addContact = () => {
  //   const newContact = {
  //     id: contacts.length + 1,
  //     name: "New Contact",
  //     priority: "Medium",
  //     meetingFrequency: "Monthly",
  //   };
  //   setContacts([...contacts, newContact]);
  // };

  // Function to delete a contact
  // const deleteContact = ( id ) =>
  // {
  //   const updatedContacts = contacts.filter( ( contact ) => contact.id !== id );
  //   setContacts( updatedContacts );
  // };
  const [ showToast, setShowToast ] = useState( false );
  const deleteContact = (  ) =>
  {
   
    
    setShowToast( true );
   
  };
  const reminders = [
    {
      id: 1,
      name: "Ryan Gosling",
      task: "Email the wedding photos",
      time: "Today 10:00 AM",
    },
    {
      id: 2,
      name: "Cillian Murphy",
      task: "Monthly report",
      time: "Today 12:00 PM",
    },
    {
      id: 3,
      name: "Robert Downey JR",
      task: "Call back",
      time: "Overdue - Yesterday 19:10 PM",
    },
  ];

  const [ showModal, setShowModal ] = useState( false );
  const [ selectedContact, setSelectedContact ] = useState( null );
  const [ priority, setPriority ] = useState( "" );
  const [ meetingFrequency, setMeetingFrequency ] = useState( "" );

  // const addContact = () => {
  //   setShowModal(true);
  // };

  const handleModalClose = () =>
  {
    setShowModal( false );
    setSelectedContact( null );
    setPriority( "" );
    setMeetingFrequency( "" );
  };

  const handleSaveContact = () =>
  {
    // Create a new contact object
    const newContact = {
      id: contacts.length + 1,
      name: selectedContact.name,
      priority,
      meetingFrequency,
    };

    // Add the new contact to the list
    setContacts( [ ...contacts, newContact ] );

    // Close the modal and reset state
    handleModalClose();
  };

  // const deleteContact = (id) => {
  //   const updatedContacts = contacts.filter((contact) => contact.id !== id);
  //   setContacts(updatedContacts);
  // };
  const addContact = () =>
  {
    setRelationship( false );
    setShowModal( true );

  };
  const addpriority = () =>
  {
    setRelationship( true );
    setShowModal( true );

  };

  useEffect( () =>
  {
    if ( key == "Remainder" )
    {
      setActiveTab( "Remainder" );
    }
  }, [] );

  const [ selectedContacts, setSelectedContacts ] = useState( new Set() );




  const handleCheckboxChange = ( id ) =>
  {
    const updatedSelectedContacts = new Set( selectedContacts );
    if ( updatedSelectedContacts.has( id ) )
    {
      updatedSelectedContacts.delete( id );
    } else
    {
      updatedSelectedContacts.add( id );
    }
    setSelectedContacts( updatedSelectedContacts );
  };
  return (
    <div>
      {/* Header */ }

      <div
        className="d-flex w-100 justify-content-between align-items-center"
        style={ {
          color: "white",
          fontSize: "2em",
          padding: "1%",
          backgroundColor: "#5411dc",
        } }
      >
        <FaChevronLeft
          size={ 25 }
          onClick={ () => navigate( "/dashboard" ) }
          style={ { cursor: "pointer" } }
        />
        <p className="m-0 fw-semibold fs-4">Dates</p>
        <p></p>

      </div>
      {/* Group List */ }
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        { [ "groups", "Remainder", "Relationship builder" ].map( ( tab ) => (
          <li className="nav-item" role="presentation" key={ tab }>
            <button
              className={ `nav-link ${ activeTab === tab ? "active" : "" }` }
              id={ `${ tab }-tab` }
              type="button"
              role="tab"
              aria-controls={ tab }
              aria-selected={ activeTab === tab }
              onClick={ () => handleTabClick( tab ) }
            >
              { tab.charAt( 0 ).toUpperCase() + tab.slice( 1 ) }
            </button>
          </li>
        ) ) }
      </ul>
      <div className="tab-content " id="myTabContent" style={ { minHeight: "85vh" } }>

        <div
          className={ `tab-pane fade ${ activeTab === "groups" ? "show active" : "" }` }
          id="groups"
          role="tabpanel"
          aria-labelledby="info-tab"
        >
          <div className="d-flex justify-content-end w-100">
            <button
              className="btn btn-primary rounded-circle p-1 m-2"
              onClick={ () => navigate( "/addgroups" ) }
            >
              <FaPlus
                size={ 25 }

                style={ { cursor: "pointer" } }
              />
            </button>
          </div>
          <div className="list-group">
            <div
              className="list-group-item list-group-item-action d-flex align-items-center"
              onClick={ () => navigate( "/groupdetails" ) }
            >
              <img
                src="https://via.placeholder.com/50"
                alt="Group"
                className="rounded-circle me-3"
              />
              <div className="flex-grow-1">
                <div className="fw-bold">Event Orgaiser</div>
                
              </div>
              <span
                className="badge  rounded-pill"
                style={ { color: "white", backgroundColor: "#5411dc" } }
              >
                Private
              </span>
            </div>
            <div
              className="list-group-item list-group-item-action d-flex align-items-center"
              onClick={ () => navigate( "/groupdetails" ) }
            >
              <img
                src="https://via.placeholder.com/50"
                alt="Group"
                className="rounded-circle me-3"
              />
              <div className="flex-grow-1">
                <div className="fw-bold">Universal Consult</div>
                
              </div>
              <span
                className="badge  rounded-pill"
                style={ { color: "white", backgroundColor: "#5411dc" } }
              >
                Private
              </span>
            </div>
            <div
              className="list-group-item list-group-item-action d-flex align-items-center"
              onClick={ () => navigate( "/groupdetails" ) }
            >
              <img
                src="https://via.placeholder.com/50"
                alt="Group"
                className="rounded-circle me-3"
              />
              <div className="flex-grow-1">
                <div className="fw-bold">WEO-2024</div>
                
              </div>
              <span
                className="badge  rounded-pill"
                style={ { color: "white", backgroundColor: "#5411dc" } }
              >
                Public
              </span>
            </div>
            <div
              className="list-group-item list-group-item-action d-flex align-items-center"
              onClick={ () => navigate( "/groupdetails" ) }
            >
              <img
                src="https://via.placeholder.com/50"
                alt="Group"
                className="rounded-circle me-3"
              />
              <div className="flex-grow-1">
                <div className="fw-bold">Group Name</div>
                
              </div>
              <span
                className="badge  rounded-pill"
                style={ { color: "white", backgroundColor: "#5411dc" } }
              >
                Invite Only
              </span>
            </div>
          </div>
        </div>

        <div
          className={ `tab-pane fade ${ activeTab === "Remainder" ? "show active" : "" }` }
          id="Remainder"
          role="tabpanel"
          aria-labelledby="info-tab"
        >
          { " " }
          <div className="container mt-4">
            <div className="card">
              {/* <div className="card-header bg-primary text-white">
            <div>3/6 reminders done</div>
            <div>9+ relationships maintained</div>
          </div> */}
              <DashboardCard />
              <div className="card-body mb-5">
                { reminders.map( ( reminder ) => (
                  <div
                    key={ reminder.id }
                    className="mb-2 p-3"
                    style={ {
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      border: "1px solid #e0e0e0",
                      borderRadius: " 8px",
                    } }
                  >
                    <h5 className="fw-semibold">{ reminder.name }</h5>
                    <p style={ { margin: "0" } }>
                      <FaRegCalendarAlt size={ 20 } />
                      { reminder.time }
                    </p>
                    <p> { reminder.task }</p>
                  </div>
                ) ) }
              </div>
            </div>
          </div>
        </div>
        <div
          className={ `tab-pane fade ${ activeTab === "Relationship builder" ? "show active" : "" }` }
          id="My Relationship builder"
          role="tabpanel"
          aria-labelledby="info-tab"
        >
          { " " }
          <div className="container mt-4">
            <div className="card">
              <div className="card-header d-flex justify-content-end align-items-center">
                <button className="btn btn-primary rounded-circle p-1" onClick={ addContact }>
                  <FaPlus size={ 25 } />
                </button>
              </div>
              <div className="card-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th><input type="checkbox" className="form-check-input p-2" /></th>
                      <th>Name</th>
                      <th>Priority</th>
                      <th>Meeting Frequency</th>
                    </tr>
                  </thead>
                  <tbody>
                    { contacts.map( ( contact ) => (
                      <tr key={ contact.id }>
                        <td className="text-break">
                          <input
                            type="checkbox"
                            className="form-check-input p-2"
                            onChange={ () => handleCheckboxChange( contact.id ) }
                            checked={ selectedContacts.has( contact.id ) }
                          />
                        </td>
                        <td className="text-break">{ contact.name }</td>
                        <td className="text-break">{ contact.priority }</td>
                        <td>{ contact.meetingFrequency }</td>
                      </tr>
                    ) ) }
                  </tbody>
                </table>
              </div>
            </div>
            <Toast
        show={ showToast }
        onClose={ () => setShowToast( false ) }
        className="position-absolute top-0 start-50 translate-middle-x"
        delay={ 3000 } // Auto-hide after 3 seconds
        autohide
        // style={ {
        //   position: 'fixed',
        //   bottom: '1rem',
        //   right: '1rem',
        // } }
      >
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
          <small>Just now</small>
        </Toast.Header>
        <Toast.Body>
          The selected contacts have been deleted from the relationship builder.
        </Toast.Body>
      </Toast>
            { selectedContacts.size > 0 && (
              <div className="position-absolute bottom-0 start-50 translate-middle-x bg-white shadow-lg p-3 mb-5 bg-white rounded">
                <p className="mb-0 ms-2 mt-2">{ selectedContacts.size } selected</p>
                <div className="p-2 d-flex flex-wrap">
                  <Chip label="set priority and meeting frequency" variant="outlined" className="m-1" onClick={ addpriority } />
                  <Chip label="delete" variant="outlined" className="m-1" onClick={ deleteContact } />
                </div>
              </div>
            ) }

            {/* Bootstrap Modal for adding contacts */ }
            <div
              className={ `modal-fullscreen-sm-down modal fade ${ showModal ? "show" : "" }` }
              tabIndex="-1"
              style={ { display: showModal ? "block" : "none" } }
            >
              <div className="modal-dialog modal-dialog-centered modal-fullscreen d-flex justify-content-center">
                <div className="modal-content" style={ { maxWidth: "768px" } }>
                  <div
                    className="d-flex w-100 justify-content-between align-items-center"
                    style={ {
                      color: "white",
                      fontSize: "2em",
                      padding: "1%",
                      backgroundColor: "#5411dc",
                    } }
                  >
                    <FaChevronLeft
                      size={ 25 }
                      onClick={ handleModalClose }
                      style={ { cursor: "pointer" } }
                    />
                    <p className="m-0 fw-semibold fs-4">Relationships builder</p>
                    <p></p>
                  </div>
                  { !relationship && (
                    <>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="priority" className="form-label">
                            Contact
                          </label>
                          <Form.Control type="text" placeholder="Search contact" />
                          <div className="list-group h-100vh mt-2">
                            { contacts.map( ( contact ) => (
                              <div
                                key={ contact.id }
                                className="list-group-item list-group-item-action d-flex align-items-center"
                              >
                                <input
                                  className="form-check-input me-2 p-2"
                                  type="checkbox"
                                  checked={ selectedContacts.has( contact.id ) }
                                  onChange={ () => handleCheckboxChange( contact.id ) }
                                />
                                <img
                                  src="https://via.placeholder.com/50"
                                  alt="Group"
                                  className="rounded-circle me-3"
                                />
                                <div className="flex-grow-1">
                                  <div className="fw-bold">{ contact.name }</div>
                                  <div className="text-muted">
                                    Developer
                                  </div>
                                </div>
                              </div>
                            ) ) }
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <h4
                          type="button"
                          className="btn text-primary"
                        // onClick={handleModalClose}
                        >
                          { selectedContacts.size } selected
                        </h4>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={ () => setRelationship( true ) }
                        >
                          Select
                        </button>
                      </div>
                    </>
                  ) }
                  { relationship && (
                    <>
                      <div className="m-3">
                        <label htmlFor="priority" className="form-label">
                          Priority
                        </label>
                        <select
                          className="form-select"
                          id="priority"
                          value={ priority }
                          onChange={ ( e ) => setPriority( e.target.value ) }
                        >
                          <option value="">Select Priority</option>
                          <option value="High">High</option>
                          <option value="Medium">Medium</option>
                          <option value="Low">Low</option>
                        </select>
                      </div>
                      <div className="m-3">
                        <label htmlFor="frequency" className="form-label">
                          Meeting Frequency
                        </label>
                        <select
                          className="form-select"
                          id="frequency"
                          value={ meetingFrequency }
                          onChange={ ( e ) => setMeetingFrequency( e.target.value ) }
                        >
                          <option value="">Select Frequency</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Quarterly">Quarterly</option>
                        </select>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={ handleModalClose }
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={ handleModalClose }
                        >
                          Save
                        </button>
                      </div>
                    </>
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Menu */ }
      <div class="">
        <Menu />
      </div>
    </div>
  );
}

export default Groups;
