import React, { useState } from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaWhatsapp,
  FaSnapchat,
  FaTiktok,
  FaDiscord,
  FaTelegram,
  FaPinterest,
  FaVimeo,
  FaLine,
  FaSkype,
  FaWeixin,
  FaSignal,
} from "react-icons/fa";
import { IoLogoVenmo } from "react-icons/io5";
import { CiTrash } from "react-icons/ci";
import { useForm, Controller } from "react-hook-form";

const socialPlatforms = [
  { name: "Website", icon: <FaFacebook />, placeholder: "Website URL" },
  { name: "Twitter", icon: <FaTwitter />, placeholder: "Twitter Username" },
  {
    name: "Instagram",
    icon: <FaInstagram />,
    placeholder: "Instagram Username",
  },
  { name: "LinkedIn", icon: <FaLinkedin />, placeholder: "LinkedIn Username" },
  { name: "Facebook", icon: <FaFacebook />, placeholder: "Facebook Username" },
  { name: "YouTube", icon: <FaYoutube />, placeholder: "YouTube Channel" },
  { name: "WhatsApp", icon: <FaWhatsapp />, placeholder: "WhatsApp Number" },
  { name: "Snapchat", icon: <FaSnapchat />, placeholder: "Snapchat Username" },
  { name: "TikTok", icon: <FaTiktok />, placeholder: "TikTok Username" },
  { name: "Venmo", icon: <IoLogoVenmo />, placeholder: "Venmo Username" },
  { name: "Discord", icon: <FaDiscord />, placeholder: "Discord Tag" },
  { name: "Telegram", icon: <FaTelegram />, placeholder: "Telegram Username" },
  {
    name: "Pinterest",
    icon: <FaPinterest />,
    placeholder: "Pinterest Username",
  },
  { name: "Vimeo", icon: <FaVimeo />, placeholder: "Vimeo Username" },
  { name: "Line", icon: <FaLine />, placeholder: "Line ID" },
  { name: "Skype", icon: <FaSkype />, placeholder: "Skype Username" },
  { name: "WeChat", icon: <FaWeixin />, placeholder: "WeChat ID" },
  { name: "Signal", icon: <FaSignal />, placeholder: "Signal Number" },
];

const SocialScreen = () => {
  const { control, register } = useForm();
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const handleAddPlatform = (platform) => {
    if (!selectedPlatforms.includes(platform)) {
      setSelectedPlatforms([...selectedPlatforms, platform]);
    }
  };

  const handleRemovePlatform = (platform) => {
    setSelectedPlatforms(selectedPlatforms.filter((p) => p !== platform));
  };

  return (
    <div>
      <p className="fw-bold fs-7" style={{ color: "#6610f2" }}>
        Social Links
      </p>
      <div className="d-flex flex-wrap mb-3">
        {socialPlatforms.map((platform) => (
          <button
            type="button"
            key={platform.name}
            onClick={() => handleAddPlatform(platform)}
            className="btn btn-outline-secondary m-1"
          >
            {platform.icon}
          </button>
        ))}
      </div>
      {selectedPlatforms.map((platform, index) => (
        <div key={index} className=" align-items-center mb-2">
          <label className="me-2 fw-semibold fs-8 p-2">{platform.name}:</label>
          <div className="d-flex p-2">
            <Controller
              name={platform.name}
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  placeholder={platform.placeholder}
                  className="form-control "
                />
              )}
            />
            <button
              type="button"
              onClick={() => handleRemovePlatform(platform)}
              className="btn btn-danger ms-2"
            >
              <CiTrash />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SocialScreen;
