import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FetchCards } from "../state_services/actions/businesscard/CardsActions";
import {
  DISPLAY_CARDS,
  CHOOSE_SPECIFIC_CARD,
} from "../state_services/actionTypes/businesscard/CardsActionTypes";

//---------------- -------------------------//
import { DisplayCards } from "../screens/Businesscards/DisplayCards";
import { ShowSpecificCard } from "../screens/Businesscards/ShowSpecificCard";

//API services
import { Businesscard_Fetch_API } from "../services/userservices";
import UpgradePlan from "../functions/UpgradePlan";
import { useWindowSize } from "../functions/functions";

const BusinessCards = () => {
  const state = useSelector((state) => state.Businesscards);
  const dispatch = useDispatch();
  const size = useWindowSize();
  //Upgrade plan modal based on config settings ------------------------------------
  const [pageLoader, setPageLoader] = useState(true);
  //-------------------------------------------------------------------------------------
  const EXHIBITOR_ID = JSON.parse(localStorage?.getItem("exhibitor_id"));

  const navigate = useNavigate();
  const Goback_fn = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    let payload = {
      exhibitor_id: EXHIBITOR_ID,
    };
    Businesscard_Fetch_API(payload).then((res) => {
      if (res?.status?.localeCompare("success") == 0) {
        dispatch(FetchCards(res?.data));
      }
      setTimeout(() => {
        setPageLoader(false);
      }, 1000);
    });
  }, []);

  return (
    <>
      <div
        className="container-lg  p-0"
        style={{
          backgroundColor: "#5411dc",
          gap: "10px",
          maxWidth: "450px",
          height: size.height,
          overflowY: "hidden",
        }}
      >
        <div className="container " style={{ overflowY: "hidden" }}>
          <div className="d-flex justify-content-between align-items-center text-white px-3 py-3">
            <FaChevronLeft
              size={25}
              onClick={Goback_fn}
              style={{ cursor: "pointer" }}
            />
            <p className="m-0 fw-semibold fs-6">Business Cards</p>
            <p className="m-0 " style={{ width: "20px" }}></p>
          </div>
          <div
            className="container-fluid d-flex justify-content-center align-items-start bg-light  p-3 rounded-2"
            style={{ height: size.height - 100, overflowY: "auto" }}
          >
            {JSON.parse(localStorage.getItem("config_settings"))
              .business_cards_enabled === "No" ? (
              pageLoader ? (
                <>
                  <div
                    className="d-flex w-100 bg-white align-items-center justify-content-center"
                    style={{ height: size.height - 120 }}
                  >
                    <div
                      className="spinner-border text-primary "
                      role="status"
                      style={{ width: "200px", height: "200px" }}
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <UpgradePlan />
              )
            ) : pageLoader ? (
              <>
                <div
                  className="d-flex w-100 bg-white align-items-center justify-content-center"
                  style={{ height: size.height - 120 }}
                >
                  <div
                    className="spinner-border text-primary "
                    role="status"
                    style={{ width: "200px", height: "200px" }}
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            ) : state.DISPLAY === DISPLAY_CARDS ? (
              <DisplayCards
                data={{
                  state: state,
                  dispatch: dispatch,
                }}
              />
            ) : state.DISPLAY === CHOOSE_SPECIFIC_CARD ? (
              <ShowSpecificCard
                data={{
                  state: state,
                  dispatch: dispatch,
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessCards;
