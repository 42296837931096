import React, { useEffect, useReducer } from "react";
import { useWindowSize } from "../functions/functions";
import { AssetsByUserAPI } from "../services/userservices";
import CardsTab from "../screens/Emails/CardsTab";
import AssetsTab from "../screens/Emails/AssetsTab";
import ToursTab from "../screens/Emails/ToursTab";
import CollectionsTab from "../screens/Emails/CollectionsTab";
import { Modal } from "../functions/Modal";
import ShareEmail from "../screens/Emails/components/ShareEmail";
import { ToastContainer } from "react-toastify";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import FullScreenModal from "../functions/FullScreenModal";
import FallbackSocial from "../screens/Emails/components/FallbackSocial";
import { Menu } from "../functions/menu";
import { t } from "i18next";

const BulkEmail = () => {
  const size = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  const { key } = location.state || {};
  const initialBulkEmailState = {
    activeTab: "cards",
    assets: {},
    errors: {},
    loader: false,
    share_email: {
      template: {
        subject: "",
        body: "",
      },
      data: {
        email_instance_id: null,
        source_id: null,
        content_type: null,
      },
    },
    isShareEmailModalOpen: false,
    fallbackSocialShare: {
      url: "",
      isModalOpen: false,
    },
  };

  const BulkEmailReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_TAB":
        return { ...state, activeTab: action.value };

      case "UPDATE_ASSETS":
        return { ...state, assets: action.value };

      case "UPDATE_FETCH_ASSET_ERRORS":
        return { ...state, errors: action.value };

      case "UPDATE_LOADER_STATE":
        return { ...state, loader: action.value };

      case "UPDATE_EMAIL_TEMPLATE": {
        // console.log(action);
        return {
          ...state,
          share_email: {
            template: {
              subject: action.subject,
              body: action.body,
            },
            data: {
              email_instance_id: action.instanceID,
              source_id: action.sourceID,
              content_type: action.contentType,
            },
          },
        };
      }
      case "UPDATE_SHARE_MODAL_STATE":
        return { ...state, isShareEmailModalOpen: action.value };
      case "UPDATE_FALLBACK_STATE":
        return {
          ...state,
          fallbackSocialShare: { isModalOpen: action.state, url: action.url },
        };

      default:
        return state;
    }
  };
  const [BulkEmailState, BulkEmailDispatch] = useReducer(
    BulkEmailReducer,
    initialBulkEmailState
  );

  useEffect(() => {
    async function fetchData() {
      BulkEmailDispatch({ type: "UPDATE_LOADER_STATE", value: true });
      try {
        let AllAssets = await AssetsByUserAPI({
          exhibitor_id: JSON.parse(localStorage.getItem("exhibitor_id")),
        });
        let activePlans = [];
        Object.keys(AllAssets).map((planName) => {
          if (AllAssets[planName]?.isPlanSubscribed === "Yes") {
            if (planName === "assets") {
              activePlans.push("assets");
            } else if (planName === "tours") {
              activePlans.push("tours");
            } else if (planName === "collections") {
              activePlans.push("collections");
            } else if (planName === "cards") {
              activePlans.push("cards");
            }
          }
        });
        // BulkEmailDispatch({ type: "UPDATE_TAB", value: activePlans[0] });
        BulkEmailDispatch({ type: "UPDATE_ASSETS", value: AllAssets });
      } catch (err) {
        BulkEmailDispatch({ type: "UPDATE_FETCH_ASSET_ERRORS", value: err });
      }
      BulkEmailDispatch({ type: "UPDATE_LOADER_STATE", value: false });
    }

    fetchData();
  }, []);
  useEffect(() => {
    if (key == "collections") {
      BulkEmailDispatch({
        type: "UPDATE_TAB",
        value: "collections",
      });
    }
    if (key == "tours") {
      BulkEmailDispatch({
        type: "UPDATE_TAB",
        value: "tours",
      });
    }
    if (key == "cards") {
      BulkEmailDispatch({
        type: "UPDATE_TAB",
        value: "cards",
      });
    }
  }, [key]);

  return (
    <>
      <div className="container-fluid p-0">
        <div
          className="d-flex flex-column  align-items-center"
          style={{ backgroundColor: "#5411dc" }}
        >
          <div className="d-flex w-100 align-items-center justify-content-between p-2">
            <FaChevronLeft
              size={25}
              onClick={() => {
                navigate("/dashboard");
              }}
              className="text-white"
              style={{ cursor: "pointer" }}
            />
            <p className="fw-semibold fs-5 m-0" style={{ color: "#fff" }}>
              {"Cards"}
            </p>
            <span className="d-flex " style={{ width: "20px" }}></span>
          </div>
          <div
            className="d-flex flex-column justify-content-start  bg-white rounded-2 w-100 "
            style={{ height: "90%" }}
          >
            <ul class="nav nav-tabs w-100">
              {Object.keys(BulkEmailState?.assets).length > 0 &&
              BulkEmailState?.assets?.digital_cards?.isPlanSubscribed ===
                "Yes" ? (
                <li
                  class="nav-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/card", { state: { key: "cards" } });
                  }}
                >
                  <a
                    class={`nav-link fw-semibold fs-10 ${
                      BulkEmailState?.activeTab === "cards" ? "active" : null
                    }`}
                    aria-current="page"
                  >
                    {"FILES"}
                  </a>
                </li>
              ) : null}

              {Object.keys(BulkEmailState?.assets).length > 0 &&
              BulkEmailState?.assets?.virtual_tours?.isPlanSubscribed ===
                "Yes" ? (
                <li
                  class="nav-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/card", { state: { key: "tours" } });
                  }}
                >
                  <a
                    class={`nav-link fw-semibold fs-10 ${
                      BulkEmailState?.activeTab === "tours" ? "active" : null
                    }`}
                  >
                    {"PROFILES"}
                  </a>
                </li>
              ) : null}
              {Object.keys(BulkEmailState?.assets).length > 0 &&
              BulkEmailState?.assets?.collections?.isPlanSubscribed ===
                "Yes" ? (
                <li
                  class="nav-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/card", { state: { key: "collections" } });
                  }}
                >
                  <a
                    class={`nav-link fw-semibold fs-10 ${
                      BulkEmailState?.activeTab === "collections"
                        ? "active"
                        : null
                    }`}
                  >
                    {"SHARED LOGS"}
                  </a>
                </li>
              ) : null}
            </ul>
            <div
              className="d-flex flex-column justify-content-start align-items-center overflow-y-auto w-100 "
              style={{ height: size.height }}
            >
              {BulkEmailState?.activeTab === "cards" ? (
                <CardsTab
                  data={BulkEmailState?.assets?.digital_cards}
                  state={BulkEmailState}
                  setState={BulkEmailDispatch}
                />
              ) : null}
              {BulkEmailState?.activeTab === "collections" ? (
                <CollectionsTab />
              ) : null}
              {BulkEmailState?.activeTab === "tours" ? (
                <ToursTab
                  data={BulkEmailState?.assets?.digital_cards}
                  state={BulkEmailState}
                  setState={BulkEmailDispatch}
                />
              ) : null}
              {BulkEmailState?.activeTab === "assets" ? (
                <AssetsTab
                  data={BulkEmailState?.assets?.assets}
                  state={BulkEmailState}
                  setState={BulkEmailDispatch}
                />
              ) : null}
            </div>
          </div>
          {BulkEmailState?.isShareEmailModalOpen === true ? (
            <FullScreenModal>
              <ShareEmail
                dependencies={{
                  state: BulkEmailState,
                  setState: BulkEmailDispatch,
                  data: {
                    ...BulkEmailState?.share_email?.data,
                  },
                  share_email_template: BulkEmailState?.share_email?.template,
                  exhibitor_id: JSON.parse(
                    localStorage.getItem("exhibitor_id")
                  ),
                  bulkEmail: "",
                }}
              />
            </FullScreenModal>
          ) : null}
          {BulkEmailState?.fallbackSocialShare?.isModalOpen === true ? (
            <Modal>
              <FallbackSocial
                data={BulkEmailState?.fallbackSocialShare}
                change={BulkEmailDispatch}
              />
            </Modal>
          ) : null}
        </div>
      </div>
      <Menu />
    </>
  );
};

export default BulkEmail;
