import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import Logo from "../../assets/logoOnly.png";

const Register = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const navigate = useNavigate();

  return (
    <div
      className="d-flex flex-column py-2 align-items-center px-3 "
      style={{
        background:
          "linear-gradient(142deg, rgba(230,230,251,1) 0%, rgba(230,224,246,1) 33%, rgba(232,223,247,1) 66%, rgba(223,206,235,1) 100%)",
      }}
    >
      <div className="d-flex w-100 align-items-center justify-content-between p-2">
        <FaChevronLeft
          size={25}
          onClick={() => {
            navigate("/dashboard");
          }}
          className="text-primary "
          style={{ cursor: "pointer" }}
        />

        <div className="d-flex align-items-center">
          <img
            src={Logo}
            width="auto"
            height="auto"
            style={{ maxWidth: "170px", maxHeight: "70px" }}
          />
          <h3 className="text-primary">VEE CARDS</h3>
        </div>
        <span className="d-flex " style={{ width: "20px" }}></span>
      </div>

      <h4 className="fw-semibold fs-2 pt-4" style={{ color: "#322d43" }}>
        Create your acoount
      </h4>

      <form
        className="d-flex flex-column  mt-2 fs-11 al"
        style={{ gap: "20px" }}
      >
        <p
          className="fw-normal fs-8 
        pt-2 px-1 text-center"
          style={{ color: "#4a455a" }}
        >
          Create an account to view and manage your contacts.
        </p>
        <div className="">
          {/* First Name */}

          <label htmlFor="firstName" className="form-label fs-9">
            {"First Name"}
          </label>
          <input
            className={`form-control mb-2 ${errors.firstName ? "is-invalid" : ""}`}
            id="firstName"
            type="text"
            {...register("firstName", {
              required: "First name is required",
              pattern: {
                value: /^[A-Za-z]+$/,
                message: "First name must contain only letters",
              },
              minLength: {
                value: 2,
                message: "First name must be at least 2 characters long",
              },
            })}
          />
          {errors?.firstName?.message && (
            <div className="invalid-feedback text-start">
              {errors?.firstName?.message}
            </div>
          )}

          {/* Last Name */}

          <label htmlFor="lastName" className="form-label fs-9">
            {"Last Name"}
          </label>
          <input
            className={`form-control mb-2 ${errors.lastName ? "is-invalid" : ""}`}
            id="lastName"
            type="text"
            {...register("lastName", {
              required: "Last name is required",
              pattern: {
                value: /^[A-Za-z]+$/,
                message: "Last name must contain only letters",
              },
              minLength: {
                value: 2,
                message: "Last name must be at least 2 characters long",
              },
            })}
            style={{ borderRadius: "10px" }}
          />
          {errors?.lastName?.message && (
            <div className="invalid-feedback text-start">
              {errors?.lastName?.message}
            </div>
          )}

          {/* Phone Number */}

          <label htmlFor="phone" className="form-label fs-9">
            {"Phone Number"}
          </label>
          <input
            className={`form-control mb-2 ${errors.phone ? "is-invalid" : ""}`}
            id="phone"
            type="tel"
            {...register("phone", {
              required: "Phone number is required",
              pattern: {
                value:
                  /^[+]?[0-9]{1,4}?[ -]?[0-9]{2,3}?[ -]?[0-9]{3,4}?[ -]?[0-9]{4,6}$/,
                message: "Invalid phone number format",
              },
            })}
            style={{ borderRadius: "10px" }}
          />
          {errors?.phone?.message && (
            <div className="invalid-feedback text-start">
              {errors?.phone?.message}
            </div>
          )}

          <label htmlFor="email " className="form-label fs-9 ">
            {"Email"}
          </label>
          <input
            className={`form-control mb-2`}
            type="email"
            id="email"
            {...register("email", {
              required: { value: true, message: "login required" },
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "invalid email",
              },
            })}
            style={{ borderRadius: "10px" }}
          ></input>
          {errors?.email?.message && (
            <div className="invalid-feedback text-start ">
              {errors?.email?.message}
            </div>
          )}

          <label htmlFor="Password" className="form-label fs-9 ">
            {"Password"}
          </label>
          <input
            className={`form-control mb-2`}
            id="password"
            type="password"
            {...register("password", {
              required: "Password is required",
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$%*#?&])[A-Za-z\d@$#*].{8,}$/,
                message:
                  "Password must be at least 8 characters, contain at least one lowercase letter, one uppercase letter, one digit, and one special character.",
              },
            })}
            style={{ borderRadius: "10px" }}
          ></input>
          {errors?.password?.message && (
            <div className="invalid-feedback text-start ">
              {errors?.password?.message}
            </div>
          )}
          <label htmlFor="confirmPassword" className="form-label fs-9 ">
            {"Confirm Password"}
          </label>
          <input
            className={`form-control mb-2`}
            id="confirmPassword"
            type="password"
            {...register("confirmPassword", {
              required: "Please confirm your password",
              validate: (value) =>
                value === register("password").value ||
                "Passwords do not match",
            })}
            style={{ borderRadius: "10px" }}
          ></input>
          {errors?.confirmPasswordl?.message && (
            <div className="invalid-feedback text-start ">
              {errors?.confirmPasswordl?.message}
            </div>
          )}
        </div>

        <button
          className="d-flex align-items-center justify-content-center btn btn-primary  fw-semibold w-100 py-2"
          style={{ borderRadius: "10px" }}
        >
          <Link style={{ textDecoration: "none", color: "#ffff" }} to="/login">
            <p className="m-0 ">{"SUBMIT"}</p>
          </Link>
        </button>
      </form>
    </div>
  );
};

export default Register;
