import React from "react";

const Stats = (props) => {
  return (
    <>
      <div
        class="text-center p-2"
        style={{
          borderRadius: "8px",
          border: " 1px solid #e0e0e0",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "7rem",
        }}
        onClick={props.onClick}
      >
        <div className="p-2"> {props.icon}</div>
        <h4
          id="contactsCount"
          style={{
            fontSize: "1.5rem",
            marginBottom: "5px",
            // color: "#6c757d",
            fontWeight: "bold",
          }}
        >
          {props.number}
        </h4>
        <p style={{ fontSize: "1rem", color: "#6610f2", fontWeight: "bold" }}>
          {props.name}
        </p>
      </div>
    </>
  );
};

export default Stats;
