import Groups from "./pages/Groups";
import AddGroup from "./pages/AddGroup";
//-------------------------State Management-----------------------------//
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import store from "./store";
//-------------------------State Management-----------------------------//

import React, { useState, useEffect, useRef } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Register from "./screens/Login/Register";
/*--------------------Pages Import Section--------------------------*/
import { Login } from "./pages/Login";
import Addleads from "./pages/Addleads";
import Displayleads from "./pages/Displayleads";
import Dashboard from "./pages/Dashboard";
import Editleads from "./pages/Editleads";
import { ViewContact } from "./pages/ViewContact";
import { QR } from "./pages/QR";
import BusinessCards from "./pages/BusinessCards";
import CreateCard from "./screens/Emails/components/CreateCard";
import NextPage from "./screens/Emails/components/NextPage";
/*--------------------Pages Import Section--------------------------*/

//API SERVICES
import {
  CreateNewLead_API,
  EditLeads_API,
  DisplayLeads_API,
  EventList_API,
  List_API,
  ContactManagerConfig,
  NewBusinessCard_API,
} from "./services/userservices";
import LeadCapture from "./pages/Ocr/LeadCapture";
import VirtualPhotoBooth from "./pages/Ocr/photobooth";
import { Stories } from "./pages/Stories";
import { OCR } from "./pages/OCR";
import BulkEmail from "./pages/BulkEmail";
import ListForm from "./pages/ListForm";
import FileDetails from "./screens/Emails/components/FileDetails";
import GroupDetails from "./pages/GroupDetails";
import DetailedProfile from "./screens/Emails/components/DetailedProfile";
import Samplefile from "./screens/Emails/components/Samplefile";
import BulkShare from "./screens/Emails/components/BulkShare";
import Settings from "./pages/Settings";
import ShareDate from "./pages/ShareDate";
import ShareCalander from "./pages/ShareCalander";
import MeetingStatus from "./pages/MeetingStatus";
import EventBadge from "./screens/Emails/components/EventBadge";

export let loginPageCheck =
  (JSON.parse(localStorage.getItem("user_logged_in")) !== null &&
    JSON.parse(localStorage.getItem("user_logged_in")) == false) ||
  JSON.parse(localStorage.getItem("user_logged_in")) === null;
export let OtherPagesCheck =
  JSON.parse(localStorage.getItem("user_logged_in")) !== null &&
  JSON.parse(localStorage.getItem("user_logged_in")) == true;
let persistor = persistStore(store);
const App = () => {
  const BASE_NAME = "/";
  /*---------------------------Interval function - check ( localstorage data & online is present or not)-----------------------------*/
  useEffect(() => {
    const interval = setInterval(() => {
      let EditItemsData = "",
        EditItemsDataArr = [],
        EditCount = 0;

      if (window.navigator.onLine) {
        ContactManagerConfig({
          exhibitor_id: JSON.parse(localStorage.getItem("exhibitor_id")),
        }).then((res) => {
          // console.log(res);
          localStorage.setItem("config_settings", JSON.stringify(res));
        });
        //-------------------------------------------------Insert Items------------------------------------------------//
        if (JSON.parse(localStorage.getItem("InsertItems")) != null) {
          let InsertItemsData = JSON.parse(localStorage.getItem("InsertItems"));
          if (Object.keys(InsertItemsData).length > 0) {
            let InsertItemsDataPayload = { user: [] };

            Object.keys(InsertItemsData).map((leads) => {
              InsertItemsDataPayload["user"].push(InsertItemsData[leads]);
            });

            CreateNewLead_API(InsertItemsDataPayload).then((res) => {
              if (Array.isArray(res)) {
                res.map(function (object, index) {
                  if (object.status.localeCompare("success") == 0) {
                    let _InsertITM = JSON.parse(
                      localStorage.getItem("InsertItems")
                    );
                    let _obj = JSON.parse(localStorage.getItem("DisplayLeads"));

                    let keyv = object.user_email;
                    _obj[keyv] = {};
                    _obj[keyv] = _InsertITM[object.user_email];
                    localStorage.setItem("DisplayLeads", JSON.stringify(_obj));

                    _InsertITM[object.user_email].insert_status = true;
                    localStorage.setItem(
                      "InsertItems",
                      JSON.stringify(_InsertITM)
                    );
                  }
                });
              }
            });
          }
        }
        //-------------------------------------------------Insert Items------------------------------------------------//

        //-------------------------------------------------Edit Items------------------------------------------------//

        const Edit_Fn1 = (ProcessCount) => {
          if (ProcessCount < EditCount) {
            Object.keys(EditItemsDataArr[ProcessCount]).map((leads) => {
              let payload =
                "id=104&contact_id=" +
                EditItemsDataArr[ProcessCount][leads].contact_id +
                "&first_name=" +
                EditItemsDataArr[ProcessCount][leads].first_name +
                "&last_name=" +
                EditItemsDataArr[ProcessCount][leads].last_name +
                "&company=" +
                EditItemsDataArr[ProcessCount][leads].company +
                "&phone_no=" +
                EditItemsDataArr[ProcessCount][leads].phone_no +
                "&email_address=" +
                EditItemsDataArr[ProcessCount][leads].email_address +
                "&country=India&desigination=" +
                EditItemsDataArr[ProcessCount][leads].desigination +
                "&status=1&event_id=" +
                EditItemsDataArr[ProcessCount][leads].event_id +
                "&source=leads-ReactApp";
              EditLeads_API(payload).then((res) => {
                if (res.data.Status.localeCompare("success") == 0) {
                  let _Edititm = JSON.parse(localStorage.getItem("EditItems"));
                  delete _Edititm[leads];
                  localStorage.setItem("EditItems", JSON.stringify(_Edititm));

                  // console.log('payload', payload);
                  // console.log('Edit', ProcessCount);

                  ProcessCount++;
                  Edit_Fn2(ProcessCount);
                }
              });
            });
          } else {
            // console.log('Process Finished');
          }
        };

        const Edit_Fn2 = (ProcessCount) => {
          if (ProcessCount < EditCount) {
            Object.keys(EditItemsDataArr[ProcessCount]).map((leads) => {
              let payload =
                "id=104&contact_id=" +
                EditItemsDataArr[ProcessCount][leads].contact_id +
                "&first_name=" +
                EditItemsDataArr[ProcessCount][leads].first_name +
                "&last_name=" +
                EditItemsDataArr[ProcessCount][leads].last_name +
                "&company=" +
                EditItemsDataArr[ProcessCount][leads].company +
                "&phone_no=" +
                EditItemsDataArr[ProcessCount][leads].phone_no +
                "&email_address=" +
                EditItemsDataArr[ProcessCount][leads].email_address +
                "&country=India&desigination=" +
                EditItemsDataArr[ProcessCount][leads].desigination +
                "&status=1&event_id=" +
                EditItemsDataArr[ProcessCount][leads].event_id +
                "&source=leads-ReactApp";
              EditLeads_API(payload).then((res) => {
                if (res.data.Status.localeCompare("success") == 0) {
                  let _Edititm = JSON.parse(localStorage.getItem("EditItems"));
                  delete _Edititm[leads];
                  localStorage.setItem("EditItems", JSON.stringify(_Edititm));

                  // console.log('payload', payload);
                  // console.log('Edit', ProcessCount);

                  ProcessCount++;
                  Edit_Fn1(ProcessCount);
                }
              });
            });
          } else {
            // console.log('Process Finished');
          }
        };

        if (JSON.parse(localStorage.getItem("EditItems")) != null) {
          EditItemsData = JSON.parse(localStorage.getItem("EditItems"));
          Object.keys(EditItemsData).map((leads) => {
            let _obj = {};
            _obj[leads] = EditItemsData[leads];
            EditItemsDataArr.push(_obj);
          });

          EditCount = EditItemsDataArr.length;
          if (EditCount > 0) {
            Edit_Fn1(0);
          }
        }
        //-------------------------------------------------Edit Items------------------------------------------------//

        //-------------------------------------------------Display Leads - Fetch------------------------------------------------//
        let Dpayload = {
          id: JSON.parse(localStorage?.getItem("exhibitor_id")),
        };

        DisplayLeads_API(Dpayload).then((res) => {
          if (res.status.localeCompare("success") == 0) {
            let leads_data = {};
            res.data.map((leads) => {
              leads_data[leads.data.email_address] = leads.data;
            });
            // console.log('displayleads', leads_data);
            localStorage.setItem("DisplayLeads", JSON.stringify(leads_data));
          }
        });
        //-------------------------------------------------Display Leads - Fetch------------------------------------------------//

        /*------------------------------------------------Fetch Lists-----------------------------------------------*/
        let ELpayload = {
          exhibitor_id: JSON.parse(localStorage?.getItem("exhibitor_id")),
        };

        List_API(ELpayload).then((res) => {
          if (res.data !== null && res.data !== "") {
            // console.log(res);
            localStorage.setItem("Lists", JSON.stringify(res));
          } else {
            localStorage.setItem("Lists", JSON.stringify([]));
          }
        });

        const PayloadFormData = new FormData();
        PayloadFormData.append("action", "get_business_card_tags");
        PayloadFormData.append(
          "exhibitor_id",
          JSON.parse(localStorage?.getItem("exhibitor_id"))
        );
        NewBusinessCard_API(PayloadFormData).then((res) => {
          // console.log(res);
          localStorage.setItem("Tags", JSON.stringify(res));
        });
        /*------------------------------------------------Fetch Lists-----------------------------------------------*/
      }
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  /*---------------------------Interval function - check ( localstorage data & online is present or not)-----------------------------*/
  return (
    <Provider store={store}>
      <BrowserRouter basename={BASE_NAME}>
        <div className="App">
          <div className="custom-container">
            <Routes>
              {/*------------Page Routing-----------------------*/}
              <Route
                exact
                path="/"
                element={
                  loginPageCheck ? (
                    <Navigate to="/login" replace={true} />
                  ) : (
                    <Navigate to="/dashboard" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/login"
                element={
                  loginPageCheck ? (
                    <Login />
                  ) : (
                    <Navigate to="/dashboard" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/dashboard"
                element={
                  OtherPagesCheck ? (
                    <Dashboard />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route exact path="/register" element={<Register />}></Route>
              <Route path="/profile/:id" element={<DetailedProfile />} />
              <Route path="/create-card" element={<CreateCard />}></Route>
              <Route path="/nextpage" element={<NextPage />}></Route>
              <Route path="/event" element={<EventBadge />}></Route>
              <Route
                exact
                path="/leads"
                element={
                  OtherPagesCheck ? (
                    <Addleads />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>

              <Route
                exact
                path="/list"
                element={
                  OtherPagesCheck ? (
                    <ListForm />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/showleads"
                element={
                  OtherPagesCheck ? (
                    <Displayleads />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/editleads"
                element={
                  OtherPagesCheck ? (
                    <Editleads />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/profile"
                element={
                  OtherPagesCheck ? (
                    <ViewContact />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/businesscards"
                element={
                  OtherPagesCheck ? (
                    <BusinessCards />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/ocr"
                element={
                  OtherPagesCheck ? (
                    <OCR />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route path="/bulk-share" element={<BulkShare />} />
              <Route
                exact
                path="/card"
                element={
                  OtherPagesCheck ? (
                    <BulkEmail />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route path="/file-details/:fileName" element={<FileDetails />} />
              <Route path="/sample-file" element={<Samplefile />} />

              <Route
                exact
                path="/qr"
                element={
                  OtherPagesCheck ? (
                    <QR />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/stories"
                element={
                  OtherPagesCheck ? (
                    <Stories />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/booth"
                element={
                  OtherPagesCheck ? (
                    <VirtualPhotoBooth />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/groups"
                element={
                  OtherPagesCheck ? (
                    <Groups />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/addgroups"
                element={
                  OtherPagesCheck ? (
                    <AddGroup />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/settings"
                element={
                  OtherPagesCheck ? (
                    <Settings />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/groupdetails"
                element={
                  OtherPagesCheck ? (
                    <GroupDetails />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/sharedate"
                element={
                  OtherPagesCheck ? (
                    <ShareDate />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/meetingstatus"
                element={
                  OtherPagesCheck ? (
                    <MeetingStatus />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/sharecalander"
                element={
                  OtherPagesCheck ? (
                    <ShareCalander />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              {/*------------Page Routing-----------------------*/}
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
