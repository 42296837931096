import React, { useState } from "react";
import { SetPresentDisplay } from "../../state_services/actions/businesscard/CardsActions";
import { DISPLAY_CARDS } from "../../state_services/actionTypes/businesscard/CardsActionTypes";
//---------------- -------------------------//
import { EmailQRTab } from "./components/EmailQrTab";
import { AiOutlineClose } from "react-icons/ai";

export const ShowSpecificCard = (props) => {
  const GoBack_fn = () => {
    props?.data?.dispatch(SetPresentDisplay(DISPLAY_CARDS));
  };

  const [loader, setLoader] = useState(true);
  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center"
          style={{ position: "absolute" }}
        >
          <div className="spinner-border">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : null}
      <div
        className="d-flex flex-column w-100 h-100 bg-none rounded-2"
        style={{ gap: "10px" }}
      >
        <div className="d-flex w-100  justify-content-end ">
          <button type="button" className="btn btn-primary" onClick={GoBack_fn}>
            <AiOutlineClose size={20} className="text-white" />
          </button>
        </div>
        <embed
          type="text/html"
          className="border border-2 rounded-2 p-1 w-100"
          src={props?.data?.state?.ProfileUrl}
          width="100%"
          height="600px"
          onLoad={() => setLoader(false)}
        ></embed>
      </div>
    </>
  );
};
