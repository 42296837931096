import React, { useState, useEffect } from "react";
import {
  FaFacebookF,
  FaTiktok,
  FaLine,
  FaSkype,
  FaSignal,
  FaApple,
  FaBehance,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
  FaInfoCircle,
  FaTag,
  FaGlobe,
  FaVideo,
  FaDiscord,
  FaDribbble,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaPatreon,
  FaPaypal,
  FaPinterest,
  FaPlaystation,
  FaSnapchat,
  FaSoundcloud,
  FaSpotify,
  FaTelegram,
  FaTwitter,
  FaWeixin,
  FaWhatsapp,
  FaXbox,
  FaXing,
  FaYelp,
  FaYoutube,
  FaDollarSign,
  FaTwitch,
  FaVimeo,
  FaTrash,
} from "react-icons/fa";
import { BsFillPencilFill } from "react-icons/bs";
import { SocialLink_API } from "../../services/userservices";
import SocialProfileModal from "./components/SocialProfileModal"; // Adjust the path if necessary

const SocialScreen = (data) => {
  const [defaultSocials, setDefaultSocials] = useState([]);
  const [state, setState] = useState([]);
  const [socials, setSocials] = useState([]);
  const [fields, setFields] = useState([]);
  const [existingFields, setExistingFields] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const payload = {
      action: "fetch_master_templates",
    };
    SocialLink_API(payload).then((res) => {
      setDefaultSocials(res?.data);
      setLoader(false);
    });
  }, []);

  useEffect(() => {
    const payload = {
      action: "fetch_links",
      contact_id: data?.data?.contactinfo?.contact_id,
    };
    SocialLink_API(payload).then((res) => {
      if (res?.data != null) {
        setSocials(res?.data);
        const existingKeys = Object.keys(res.data).filter((key) =>
          defaultSocials.some(
            (social) => social.name === key && res.data[key] !== null
          )
        );

        setFields(
          existingKeys.map((key) => {
            const defaultField = defaultSocials.find(
              (social) => social.name === key
            );
            return {
              ...defaultField,
              name: key,
              value: res.data[key],
            };
          })
        );

        // Track existing fields
        setExistingFields(existingKeys);
      }
    });
  }, [defaultSocials, state]);

  const handleAddField = (field) => {
    setFields((prevFields) => {
      const filteredFields = prevFields.filter((f) => f.name !== field.name);
      return [...filteredFields, field];
    });
    setExistingFields((prev) => [...prev, field.name]);
  };

  const handleRemoveField = (fieldName) => {
    setFields(fields.filter((field) => field.name !== fieldName));
    setExistingFields((prev) => prev.filter((name) => name !== fieldName));
  };

  const handleInputChange = (event, fieldName) => {
    const updatedFields = fields.map((field) => {
      if (field.name === fieldName) {
        return { ...field, value: event.target.value };
      }
      return field;
    });
    setFields(updatedFields);
  };

  const submit = () => {
    const payload = {
      contact_id: data?.data?.contactinfo?.contact_id,
      exhibitor_id: JSON.parse(localStorage.getItem("exhibitor_id")),
      ...fields.reduce((acc, field) => {
        if (field.name && field.value !== null) {
          acc[field.name] = field.value;
        }
        return acc;
      }, {}),
    };
    SocialLink_API(payload).then((res) => {
      setState([]);
    });
  };

  const icons = {
    facebook: <FaFacebookF size={20} />,
    tiktok: <FaTiktok size={20} />,
    line: <FaLine size={20} />,
    skype: <FaSkype size={20} />,
    signalapp: <FaSignal size={20} />,
    applemusic: <FaApple size={20} />,
    behance: <FaBehance size={20} />,
    calendly: <FaCalendarAlt size={20} />,
    card_address: <FaMapMarkerAlt size={20} />,
    card_email: <FaEnvelope size={20} />,
    card_headline: <FaInfoCircle size={20} />,
    card_phone: <FaPhone size={20} />,
    card_status: <FaInfoCircle size={20} />,
    card_tag: <FaTag size={20} />,
    card_website_url: <FaGlobe size={20} />,
    cards_video_url: <FaVideo size={20} />,
    cashapp: null,
    discord: <FaDiscord size={20} />,
    dribbble: <FaDribbble size={20} />,
    github: <FaGithub size={20} />,
    instagram: <FaInstagram size={20} />,
    linkedin: <FaLinkedin size={20} />,
    patreon: <FaPatreon size={20} />,
    paypal: <FaPaypal size={20} />,
    pinterest: <FaPinterest size={20} />,
    psn: <FaPlaystation size={20} />,
    snapchat: <FaSnapchat size={20} />,
    soundcloud: <FaSoundcloud size={20} />,
    spotify: <FaSpotify size={20} />,
    telegram: <FaTelegram size={20} />,
    twitch: <FaTwitch size={20} />,
    twitter: <FaTwitter size={20} />,
    venmo: <FaVimeo size={20} />,
    vimeo: <FaVimeo size={20} />,
    wechat: <FaWeixin size={20} />,
    whatsapp: <FaWhatsapp size={20} />,
    xbox: <FaXbox size={20} />,
    xing: <FaXing size={20} />,
    yelp: <FaYelp size={20} />,
    youtube: <FaYoutube size={20} />,
    zelle: <FaDollarSign size={20} />,
  };

  const excludedKeys = ["created_at", "cards_id", "contact_id", "exhibitor_id"];

  return (
    <div className="w-100 m-2">
      {loader ? (
        <div
          className="d-flex w-100 bg-white align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <div
            className="spinner-border text-primary "
            role="status"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {state?.AddNotes?.Notes === "" ? (
            <div
              className="d-flex w-100 justify-content-center "
              style={{ gap: "10px" }}
            >
              <p className="fst-italic fs-11 fw-semibold text-secondary">
                Add Links
              </p>
              <BsFillPencilFill
                style={{ cursor: "pointer" }}
                type="button"
                onClick={() => {
                  setState({
                    type: "ADD_NOTES_MODAL_STATE",
                    value: true,
                  });
                }}
              />
            </div>
          ) : (
            <div
              className="d-flex flex-column w-100 justify-content-center rounded  px-3 py-3"
              style={{
                gap: "10px",
              }}
            >
              {socials.length == 0 ? (
                <div className="d-flex justify-content-center w-100 align-items-center">
                  <p className="fst-italic fs-11 fw-semibold text-secondary me-1 mb-0">
                    Add Profile
                  </p>
                  <BsFillPencilFill
                    style={{ cursor: "pointer" }}
                    type="button"
                    onClick={() => {
                      setState({
                        type: "ADD_NOTES_MODAL_STATE",
                        value: true,
                      });
                    }}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-end w-100 align-items-center">
                  <BsFillPencilFill
                    style={{ cursor: "pointer" }}
                    type="button"
                    onClick={() => {
                      setState({
                        type: "ADD_NOTES_MODAL_STATE",
                        value: true,
                      });
                    }}
                  />
                </div>
              )}
              <p className="w-100 fst-italic text-secondary mb-0">
                {socials === [] ? null : (
                  <>
                    {Object.keys(socials).map((key) => {
                      if (!excludedKeys.includes(key) && socials[key]) {
                        const value = String(socials[key]); // Ensure the value is a string
                        const defaultSocial = defaultSocials.find(
                          (social) => social.name === key
                        );
                        return (
                          <div key={key} className="mb-3">
                            <p className="mb-1 ps-0">
                              <span className="badge text-black">
                                {defaultSocial?.label_name}
                              </span>
                            </p>
                            <div className="d-flex align-items-center mb-2">
                              <span className="me-2">
                                <span className="rounded-circle bg-primary text-white p-1">
                                  {icons[key]}
                                </span>
                              </span>
                              <a
                                href={
                                  value.startsWith("http")
                                    ? value
                                    : `https://${value}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-decoration-none"
                              >
                                {value}
                              </a>
                            </div>
                            <hr></hr>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </>
                )}
              </p>
            </div>
          )}
          {state?.type === "ADD_NOTES_MODAL_STATE" && (
            <SocialProfileModal
              defaultSocials={defaultSocials}
              existingFields={existingFields}
              icons={icons}
              fields={fields}
              handleAddField={handleAddField}
              handleRemoveField={handleRemoveField}
              handleInputChange={handleInputChange}
              submit={submit}
              setState={setState}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SocialScreen;
