import React, { useEffect, useState } from "react";
import { FaCopy, FaInfo, FaQrcode } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiShareForwardFill } from "react-icons/ri";
import { TiBusinessCard } from "react-icons/ti";
import { useCustomPagination, useWindowSize } from "../../functions/functions";
import SearchBykeyword from "./SearchBykeyword";
import {
  Businesscard_Fetch_API,
  getEmailTemplate,
} from "../../services/userservices";
import SpecificCard from "./components/SpecificCard";
import { AiOutlineArrowRight } from "react-icons/ai";
import { toast } from "react-toastify";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
// import InfoIcon from '@mui/icons-material/Info';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import VCard from "vcard-creator";
import QRCode from "qrcode.react";
import { t } from "i18next";
import FileUpload from "./components/FileUpload";

const CardsTab = ({ data, state, setState }) => {
  const size = useWindowSize();
  const datas = data?.data;
  const isPlanSubscribed = data?.isPlanSubscribed;
  const [keyword, setKeyword] = useState(null);
  const [businesscards, setBusinessCards] = useState(null);
  const [specificData, setSpecificData] = useState(null);
  const [view, setView] = useState("ALL"); //ALL or SPECIFIC  without white space
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    // bgcolor: 'background.paper',
    border: "2px solid #5411dc",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const [vcf, setVcf] = React.useState(null);
  const handleOpen = (data) => {
    setOpen(true);
    setVcf(data);
  };
  const handleClose = () => setOpen(false);
  function getWidth() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1100) {
      return "25%";
    } else if (screenWidth >= 768 && screenWidth < 1100) {
      return "40%";
    } else {
      return "100%";
    }
  }
  // Filter data based on the entered keyword
  const filteredData = keyword
    ? data?.data.filter(
        (item) =>
          item &&
          item?.card_name &&
          item?.card_name.toLowerCase().includes(keyword.toLowerCase())
      )
    : data?.data || {};
  console.log(filteredData, "filteredData");
  console.log(data, "data?.data");
  const { Paginator, pageWiseData, currentPage, setCurrentPage } =
    useCustomPagination({
      datas: filteredData,
      pageSize: 20,
      siblingCount: 1,
    });

  // console.log(pageWiseData, currentPage);

  useEffect(() => {
    Businesscard_Fetch_API({
      exhibitor_id: JSON.parse(localStorage.getItem("exhibitor_id")),
    }).then((res) => {
      if (res?.status?.localeCompare("success") == 0) {
        setBusinessCards(res?.data);
      }
    });
  }, []);
  function formatDate(dateString) {
    console.log(dateString, "dateString");
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const hours = `0${date.getHours()}`.slice(-2);
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const seconds = `0${date.getSeconds()}`.slice(-2);

    return `${year}${month}${day}T${hours}${minutes}${seconds}`;
  }

  return (
    <>
      <SearchBykeyword state={keyword} setState={setKeyword} />
      <div
        className="  w-100  h-100 "
        style={{
          scrollbarWidth: "thin",
          scrollbarGutter: "stable",
          margin: 0,
          overflowY: "auto",
        }}
      >
        <div>
          <FileUpload />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center bg-primary rounded-2 px-2 py-1">
        <Paginator />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <QRCode
            value={vcf}
            size={250} // min 150
            level="L"
            renderAs="canvas"
            includeMargin={true}
            bgColor="#fff"
            fgColor="#5411dc"
            style={{ borderRadius: "10px" }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default CardsTab;
