import React, { memo, useEffect, useReducer, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchByName from "./components/SearchByName";
import Filterby from "./Filterby";
import { toast } from "react-toastify";
import { FaMailBulk, FaRegEye, FaShare } from "react-icons/fa";
import { BiFilterAlt, BiSelectMultiple } from "react-icons/bi";
import { useInfiniteScroll, useWindowSize } from "../../functions/functions";
import {
  NewBusinessCard_API,
  getEmailTemplate,
} from "../../services/userservices";
import { MdCancel, MdFilterNone } from "react-icons/md";
import { t } from "i18next";

const ContactsTab = memo(
  ({
    bulkEmail,
    setBulkEmail,
    showLeads,
    setShowLeads,
    pageWiseData,
    currentPage,
    setCurrentPage,
    showRecentLeads,
    state,
    setState,
    fromList,
    setFromList,
  }) => {
    const navigate = useNavigate();
    const size = useWindowSize();
    const CONTACTS_BLOCK_START = useRef(null);
    // console.log(pageWiseData);
    let initialState = {
      active_filters: {
        tags: [],
        lists: [],
        search_keyword: "",
      },
    };

    const ContactsTabReducer = (state, action) => {
      switch (action.type) {
        case "UPDATE_LISTS_FILTER": {
          if (action.event === "checked") {
            return {
              ...state,
              active_filters: {
                ...state?.active_filters,
                lists: [...state?.active_filters?.lists, action.value],
              },
            };
          } else {
            const isFound = state?.active_filters?.lists.some((filter) => {
              if (filter === action.value) {
                return true;
              }
              return false;
            });
            if (isFound) {
              let newArr = state?.active_filters?.lists.reduce((acc, cur) => {
                if (cur !== action.value) {
                  acc.push(cur);
                }
                return acc;
              }, []);
              return {
                ...state,
                active_filters: {
                  ...state?.active_filters,
                  lists: [...newArr],
                },
              };
            }
          }
        }
        case "UPDATE_TAGS_FILTER": {
          if (action.event === "checked") {
            return {
              ...state,
              active_filters: {
                ...state?.active_filters,
                tags: [...state?.active_filters?.tags, action.value],
              },
            };
          } else {
            const isFound = state?.active_filters?.tags.some((filter) => {
              if (filter === action.value) {
                return true;
              }
              return false;
            });
            if (isFound) {
              let newArr = state?.active_filters?.tags.reduce((acc, cur) => {
                if (cur !== action.value) {
                  acc.push(cur);
                }
                return acc;
              }, []);
              return {
                ...state,
                active_filters: {
                  ...state?.active_filters,
                  tags: [...newArr],
                },
              };
            }
          }
        }
        case "UPDATE_SEARCH_KEYWORD": {
          return {
            ...state,
            active_filters: {
              ...state?.active_filters,
              search_keyword: action.value,
            },
          };
        }
        case "CLEAR_ALL_FILTERS": {
          return {
            ...state,
            active_filters: {
              tags: [],
              lists: [],
              search_keyword: "", // Optionally, clear search keyword as well
            },
          };
        }
      }
    };
    const [selectedEmails, setSelectedEmails] = useState([]);
    console.log(selectedEmails, "selectedEmails");
    const [ContactsTabState, ContactsTabDispatcher] = useReducer(
      ContactsTabReducer,
      initialState,
    );
    const FILTER_OPTION_BLOCK = useRef(null);

    let EFFECT_ACTIVE_FILTERS = ContactsTabState?.active_filters;

    useEffect(() => {
      if (
        ContactsTabState?.active_filters?.lists.length > 0 ||
        ContactsTabState?.active_filters?.tags.length > 0 ||
        ContactsTabState?.active_filters?.search_keyword
      ) {
        setCurrentPage(1);
        let Filterby = new FormData();
        Filterby.append("action", "search_or_filter_contact");
        Filterby.append("lists", [...ContactsTabState?.active_filters?.lists]);
        Filterby.append("tags", [...ContactsTabState?.active_filters?.tags]);
        Filterby.append(
          "keyword",
          ContactsTabState?.active_filters?.search_keyword,
        );
        Filterby.append(
          "exhibitor_id",
          JSON.parse(localStorage?.getItem("exhibitor_id")),
        );
        NewBusinessCard_API(Filterby).then((res) => {
          setShowLeads(res);
        });
        if (CONTACTS_BLOCK_START !== null) {
          CONTACTS_BLOCK_START.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      } else {
        setCurrentPage(1);
        setShowLeads(JSON.parse(localStorage.getItem("DisplayLeads")));
      }
    }, [EFFECT_ACTIVE_FILTERS]);

    let EFFECT_FILTER_APPLIED = bulkEmail?.selected_contacts?.length;
    useEffect(() => {
      let updated_count = [];
      let updatedEmails = [];
      bulkEmail?.selected_contacts?.map((id) => {
        Object.keys(showLeads).map((element) => {
          if (showLeads[element]?.contact_id == id) {
            updated_count.push(id);
            updatedEmails.push(showLeads[element]?.email_address);
          }
        });
      });
      setSelectedEmails(updatedEmails);
      setBulkEmail((previousState) => ({
        ...previousState,
        selected_contacts: updated_count,
      }));
    }, [EFFECT_FILTER_APPLIED]);
    //     useEffect(() => {
    // if (fromList.filter == true) {
    //   FILTER_OPTION_BLOCK.current.classList.remove('d-none');
    //   FILTER_OPTION_BLOCK.current.classList.add('d-flex');
    // }
    //     }, []);
    const handleClearFilters = () => {
      ContactsTabDispatcher({
        type: "CLEAR_ALL_FILTERS",
      });
      setFromList({ filter: null, listId: null });
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      FILTER_OPTION_BLOCK.current.classList.remove("d-flex");
      FILTER_OPTION_BLOCK.current.classList.add("d-none");
    };
    const Editleads_fn = (leads_id) => {
      let _obj = JSON.parse(localStorage.getItem("DisplayLeads"));
      let _Eobj = { data: _obj[leads_id], editsystem: "edit", refId: leads_id };
      localStorage.setItem("EditLeads", JSON.stringify(_Eobj));
      navigate("/profile");
    };

    const EditRecentleads_fn = (leads_id, insert_status) => {
      let _obj = JSON.parse(localStorage.getItem("InsertItems"));
      if (insert_status) {
        let _Eobj = {
          data: _obj[leads_id],
          editsystem: "edit",
          refId: leads_id,
        };
        localStorage.setItem("EditLeads", JSON.stringify(_Eobj));
      } else {
        let _Eobj = {
          data: _obj[leads_id],
          editsystem: "new",
          refId: leads_id,
        };
        localStorage.setItem("EditLeads", JSON.stringify(_Eobj));
      }
      navigate("/profile");
    };

    return (
      <>
        <div
          className="d-flex flex-column align-items-center w-100 justify-content-start  bg-white    px-2 py-4 "
          style={{ overflowY: "auto", height: size.height }}
        >
          {/* <p
            className="px-2 mb-0  text-start fw-semibold fs-10"
            style={ { color: '#5411dc' } }>
            Contacts{ ' ' }
            { Object.keys( showLeads ).length +
              Object.keys( showRecentLeads ).length }
          </p> */}

      

          <div className="d-flex container align-items-center w-100 justify-content-center mb-3">
          <button
              className="d-flex align-items-center  btn btn-light  border me-2 "
              // style={{ gap: "5px", height: "50px" }}
              onClick={(evt) => {
                let leftOverContacts = [];
                // Check if all contacts are already selected
                const allSelected =
                  bulkEmail?.selected_contacts?.length ===
                  Object.keys(showLeads).length;

                // If all contacts are selected, deselect all, otherwise select all
                if (allSelected) {
                  setBulkEmail((previousState) => ({
                    selected_contacts: [],
                  }));
                } else {
                  Object.keys(showLeads).map((lead) => {
                    if (
                      !bulkEmail?.selected_contacts?.includes(
                        showLeads[lead]?.contact_id,
                      )
                    ) {
                      leftOverContacts.push(showLeads[lead]?.contact_id);
                    }
                  });
                  setBulkEmail((previousState) => ({
                    selected_contacts: [
                      ...previousState?.selected_contacts,
                      ...leftOverContacts,
                    ],
                  }));
                }
              }}
            >
              <BiSelectMultiple size={20} />
              {/* <p className="mb-0 fs-11 fw-semibold">SELECT ALL</p> */}
            </button>
            <div className="d-flex" style={{ gap: "10px" }}>
              <SearchByName
                state={ContactsTabState}
                setState={ContactsTabDispatcher}
              />

              <button
                className="d-flex align-items-center justify-content-center btn btn-light border"
                style={{ gap: "10px" }}
                onClick={() => {
                  if (
                    FILTER_OPTION_BLOCK.current.classList.contains("d-flex")
                  ) {
                    FILTER_OPTION_BLOCK.current.classList.remove("d-flex");
                    FILTER_OPTION_BLOCK.current.classList.add("d-none");
                  } else {
                    FILTER_OPTION_BLOCK.current.classList.remove("d-none");
                    FILTER_OPTION_BLOCK.current.classList.add("d-flex");
                  }
                }}
              >
                <BiFilterAlt className="text-secondary" />
                {/* <p className="mb-0 fw-semibold fs-11 text-secondary">FILTER</p> */}
              </button>
                {ContactsTabState?.active_filters?.lists.length > 0 ||
            ContactsTabState?.active_filters?.tags.length > 0 ||
            ContactsTabState?.active_filters?.search_keyword ? (
              <button
              className="d-flex align-items-center justify-content-center btn btn btn-outline-danger"
              style={{ gap: "10px" }}
              onClick={handleClearFilters}
            >
              {" "}
              <MdCancel />
              {/* <p className="mb-0 fw-semibold fs-11 text-dander">
                {" "}
                Clear
              </p> */}
            </button>
            ) : null}
            </div>
          </div>
          <Filterby
            reference={FILTER_OPTION_BLOCK}
            showLeads={showLeads}
            state={ContactsTabState}
            setState={ContactsTabDispatcher}
            fromList={fromList}
          />
    <div
            ref={CONTACTS_BLOCK_START}
            className="d-flex w-100 container justify-content-start align-items-center my-3 "
            style={{ gap: "10px" }}
          >
           
            {bulkEmail?.selected_contacts.length > 0 &&<>
            <button
              className="d-flex align-items-center btn btn-primary w-auto "
              style={{ gap: "10px", height: "50px" }}
              onClick={() => {
                if (bulkEmail?.selected_contacts.length > 0) {
                  setBulkEmail((previousState) => ({
                    ...previousState,
                    isBulkEmailModalOpen: true,
                  }));
                } else {
                  toast.warning("Select contacts to send bulk share");
                }
              }}
            >
              <FaShare size={20} />
              <p className="mb-0 fs-11  fw-semibold"> {t('contactsMenu.bulkShare')}</p>
              {bulkEmail?.selected_contacts.length > 0 && (
                <span
                  className="d-flex align-items-center justify-content-center bg-success text-white fw-semibold fs-11  px-2 py-2 rounded-circle"
                  style={{
                    position: "relative",
                    top: "0px",
                    right: "0px",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  {bulkEmail?.selected_contacts.length}
                </span>
              )}
            </button>
            <button
              className="d-flex align-items-center btn btn-primary w-auto "
              style={{ gap: "10px", height: "50px" }}
              // onClick={() => {
              //   if (bulkEmail?.selected_contacts.length > 0) {
              //     setBulkEmail((previousState) => ({
              //       ...previousState,
              //       isBulkEmailModalOpen: true,
              //     }));
              //   } else {
              //     toast.warning('Select contacts to send bulk email');
              //   }
              // }}
              onClick={async (evt) => {
                if (bulkEmail?.selected_contacts.length > 0) {
                  evt.preventDefault();
                  let payload = new FormData();
                  payload.append(
                    "exhibitor_id",
                    JSON.parse(localStorage.getItem("exhibitor_id")),
                  );
                  payload.append("email_instance_id", 18);

                  await getEmailTemplate(payload).then((res) => {
                    if (
                      res?.subject &&
                      res?.body &&
                      typeof res?.subject !== "undefined" &&
                      typeof res?.body !== "undefined"
                    ) {
                      // setState({
                      //   type: 'UPDATE_EMAIL_TEMPLATE',
                      //   subject: res?.subject,
                      //   body: res?.body,
                      //   instanceID: 18,
                      // });
                      setState({
                        type: "UPDATE_EMAIL_TEMPLATE",
                        subject: "preset subject here",
                        body: "<p>Enter your text here</p>",
                        instanceID: 18,
                      });
                    } else {
                      setState({
                        type: "UPDATE_EMAIL_TEMPLATE",
                        subject: "preset subject here",
                        body: "<p>Enter your text here</p>",
                        instanceID: 18,
                      });
                    }
                  });

                  setState({
                    type: "UPDATE_SHARE_MODAL_STATE",
                    value: true,
                  });
                  setBulkEmail((previousState) => ({
                    ...previousState,
                    selectedEmails: selectedEmails,
                  }));
                } else {
                  toast.warning("Select contacts to send mass email");
                }
              }}
            >
              <FaMailBulk size={20} />
              <p className="mb-0 fs-11  fw-semibold">{t('contactsMenu.massEmail')}</p>
              {bulkEmail?.selected_contacts.length > 0 && (
                <span
                  className="d-flex align-items-center justify-content-center bg-success text-white fw-semibold fs-11  px-2 py-2 rounded-circle"
                  style={{
                    position: "relative",
                    top: "0px",
                    right: "0px",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  {bulkEmail?.selected_contacts.length}
                </span>
              )}
            </button></>}
          </div>
          {Object.keys(pageWiseData).length > 0 &&
          typeof pageWiseData[currentPage] !== "undefined" ? (
            Object.keys(pageWiseData[currentPage]["data"]).length > 0 ? (
              <>
                {Object.keys(pageWiseData[currentPage]["data"]).map((leads) => (
                  <>
                    <div class="d-flex align-items-center w-100 row  justify-content-start">
                      <div class="d-flex align-items-center cols-lg-2">
                        <div class="form-check m-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            readOnly={true}
                            checked={
                              bulkEmail?.selected_contacts.includes(
                                pageWiseData[currentPage]["data"][leads]
                                  ?.contact_id,
                              )
                                ? true
                                : false
                            }
                            style={{ width: "25px", height: "25px" }}
                            id={`contact_${pageWiseData[currentPage]["data"][leads]?.contact_id}`}
                            onClick={(evt) => {
                              if (
                                bulkEmail?.selected_contacts.includes(
                                  pageWiseData[currentPage]["data"][leads]
                                    .contact_id,
                                )
                              ) {
                                let removedArray = [];
                                removedArray =
                                  bulkEmail?.selected_contacts.reduce(
                                    (acc, curr) => {
                                      if (
                                        curr !==
                                        pageWiseData[currentPage]["data"][leads]
                                          .contact_id
                                      ) {
                                        acc.push(curr);
                                      }
                                      return acc;
                                    },
                                    [],
                                  );

                                setBulkEmail((previousState) => ({
                                  ...previousState,
                                  selected_contacts: removedArray,
                                }));
                              } else {
                                setBulkEmail((previousState) => ({
                                  ...previousState,
                                  selected_contacts: [
                                    ...previousState?.selected_contacts,
                                    pageWiseData[currentPage]["data"][leads]
                                      .contact_id,
                                  ],
                                }));
                              }
                            }}
                          />
                        </div>
                        <div
                          className="d-flex  mb-1 rounded-2 border  shadow w-100"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            Editleads_fn(leads);
                            // console.log(leads);
                          }}
                        >
                          <div className="d-flex px-2 py-2 w-100 ">
                            {/* <div
                          className="d-flex flex-nowrap align-items-center justify-content-center  rounded-circle p-1 shadow-sm"
                          style={{
                            minWidth: '55px',
                            height: '55px',
                            backgroundColor: `#DCDCDC`,
                            // border: '1px solid black',
                          }}>
                          <p className="mb-0 fw-bold fs-8 text-secondary fst-italic">
                            {finalData != ''
                              ? finalData[leads]?.first_name
                                  .trim()
                                  .charAt(0)
                                  .toUpperCase() +
                                ' ' +
                                (finalData[leads]?.last_name !== null
                                  ? finalData[leads]?.last_name
                                      .trim()
                                      .charAt(0)
                                      .localeCompare('') == 0
                                    ? finalData[leads]?.first_name
                                        .trim()
                                        .charAt(1)
                                        .toUpperCase()
                                    : finalData[leads]?.last_name
                                        .trim()
                                        .charAt(0)
                                        .toUpperCase()
                                  : finalData[leads]?.first_name
                                      .trim()
                                      .charAt(1)
                                      .toUpperCase())
                              : null}
                          </p>
                        </div> */}
                            <div className="d-flex flex-column flex-fill text-start justify-content-center ">
                              <p
                                className="fw-semibold fs-9 m-0 lh-sm"
                                style={{ color: "#322d43" }}
                              >
                                {pageWiseData[currentPage]["data"][leads]
                                  ?.first_name +
                                  " " +
                                  pageWiseData[currentPage]["data"][leads]
                                    ?.last_name}
                              </p>
                              <p
                                className="fw-semibold fs-11 m-0 text-secondary"
                                style={{ color: "#322d43" }}
                              >
                                {pageWiseData[currentPage]["data"][leads]
                                  ?.company || "-"}
                              </p>

                              <p
                                className="fw-normal fs-11 m-0 text-secondary"
                                style={{ color: "#322d43" }}
                              >
                                {pageWiseData[currentPage]["data"][leads]
                                  ?.desigination || "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                  </>
                ))}
              </>
            ) : (
              <>
                <div
                  className="d-flex w-100 bg-white align-items-center justify-content-center"
                  style={{ height: size.height }}
                >
                  <div
                    className="spinner-border text-primary "
                    role="status"
                    style={{ width: "200px", height: "200px" }}
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            )
          ) : (
            <div className="alert alert-warning">
              <strong>No contacts available..</strong>
            </div>
          )}

          {Object.keys(showRecentLeads).length > 0 ? (
            <>
              {Object.keys(showRecentLeads).map((leads) => (
                <>
                  <div
                    className="d-flex w-100   mb-1 rounded-1  shadow-lg"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      EditRecentleads_fn(
                        leads,
                        showRecentLeads[leads]?.insert_status,
                      )
                    }
                  >
                    <div className="d-flex px-3 py-2 w-100 ">
                      {/* <div
                        className="d-flex  align-items-center justify-content-center  rounded-circle p-1 shadow-sm"
                        style={{
                          width: '55px',
                          height: '55px',
                          backgroundColor: `gray`,
                          // border: '1px solid black',
                        }}>
                        <p className="mb-0 fw-bold fs-8 text-secondary fst-italic">
                          {showRecentLeads != ''
                            ? showRecentLeads[leads].first_name
                                .trim()
                                .charAt(0)
                                .toUpperCase() +
                              ' ' +
                              (showRecentLeads[leads].last_name !== null
                                ? showRecentLeads[leads].last_name
                                    .trim()
                                    .charAt(0)
                                    .localeCompare('') == 0
                                  ? showRecentLeads[leads].first_name
                                      .trim()
                                      .charAt(1)
                                      .toUpperCase()
                                  : showRecentLeads[leads].last_name
                                      .trim()
                                      .charAt(0)
                                      .toUpperCase()
                                : showRecentLeads[leads].first_name
                                    .trim()
                                    .charAt(1)
                                    .toUpperCase())
                            : null}
                        </p>
                      </div> */}
                      <div className="d-flex flex-column flex-fill text-start justify-content-center ">
                        <p
                          className="fw-semibold fs-10 m-0 "
                          style={{ color: "#322d43" }}
                        >
                          {showRecentLeads[leads].first_name +
                            " " +
                            showRecentLeads[leads].last_name}
                        </p>
                        <p
                          className="fw-semibold fs-12 m-0 "
                          style={{ color: "#322d43" }}
                        >
                          {showRecentLeads[leads]?.company}
                        </p>

                        <p
                          className="fw-normal fs-12 m-0 "
                          style={{ color: "#322d43" }}
                        >
                          {showRecentLeads[leads].desigination}
                        </p>
                        {showLeads.hasOwnProperty(leads) ? (
                          <p className="fw-semibold fs-12 mt-1 text-danger ">
                            Given Email id already exists. Please Change.
                          </p>
                        ) : null}
                      </div>
                      <div className="d-flex  justify-content-end align-items-center text-secondary">
                        <FaRegEye
                          size={35}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            EditRecentleads_fn(
                              leads,
                              showRecentLeads[leads].insert_status,
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : null}

          {/* {Object.keys(showLeads).length == 0 &&
          Object.keys(showRecentLeads).length == 0 ? (
            <div className="alert alert-warning">
              <strong>No contacts available..</strong>
            </div>
          ) : null} */}
        </div>
      </>
    );
  },
);

export default ContactsTab;
