import React, { memo, useCallback, useEffect, useState } from "react";
import {
  AssetsByUserAPI,
  Businesscard_Fetch_API,
} from "../../../services/userservices";

const BulkEmailCards = memo(
  ({ dependencies: { sendBulkEmail, setSendBulkEmail } }) => {
    const [assetsByUser, setAssetsByUser] = useState({});

    useEffect(() => {
      AssetsByUserAPI({
        exhibitor_id: JSON.parse(localStorage?.getItem("exhibitor_id")),
      }).then((res) => {
        // console.log(res);
        setAssetsByUser(res);
      });
    }, []);

    return (
      <>
        {Object.keys(assetsByUser).length > 0 ? (
          <div
            className="d-flex w-100 border bg-light rounded-2 p-3 mb-3 mt-2"
            style={{ maxHeight: "350px", overflowY: "auto" }}
          >
            <ul className="mb-0 p-0" style={{ listStyle: "none" }}>
              <li>
                <p className="mb-0 fw-semibold fs-10 px-2 py-1 rounded-2 border border-2 bg-white mb-1">
                  DIGITAL CARDS
                </p>

                <ul
                  style={{
                    listStyle: "none",
                    overflowY: "auto",
                    height: "100%",
                  }}
                >
                  {assetsByUser?.digital_cards?.isPlanSubscribed === "Yes" ? (
                    <>
                      {assetsByUser?.digital_cards.data.length > 0 ? (
                        assetsByUser?.digital_cards.data.map((card) => {
                          return (
                            <>
                              <div key={card?.card_id} class="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id={`${card?.card_name}_${card?.card_id}`}
                                  onClick={(evt) => {
                                    if (evt.target.checked) {
                                      setSendBulkEmail((previousState) => ({
                                        ...previousState,
                                        digital_cards: [
                                          ...previousState?.digital_cards,
                                          card?.card_id,
                                        ],
                                      }));
                                    } else {
                                      if (
                                        sendBulkEmail?.digital_cards.includes(
                                          card?.card_id,
                                        )
                                      ) {
                                        setSendBulkEmail((previousState) => {
                                          let newArr =
                                            previousState?.digital_cards.reduce(
                                              (acc, cur) => {
                                                if (
                                                  !cur.includes(card?.card_id)
                                                ) {
                                                  acc.push(cur);
                                                }
                                                return acc;
                                              },
                                              [],
                                            );
                                          return {
                                            ...previousState,
                                            digital_cards: [...newArr],
                                          };
                                        });
                                      }
                                    }
                                  }}
                                />
                                <label
                                  className="form-check-label fw-normal fs-11"
                                  for={`${card?.card_name}_${card?.card_id}`}
                                >
                                  {card?.card_name}
                                </label>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">
                          No cards to add
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">
                        No subscription found
                      </p>
                    </>
                  )}
                </ul>
              </li>
              <li>
                <p className="mb-0 fw-semibold fs-10 px-2 py-1 rounded-2 border border-2 bg-white mb-1">
                  COLLECTIONS
                </p>

                <ul
                  style={{
                    listStyle: "none",
                    overflowY: "auto",
                    height: "100%",
                  }}
                >
                  {assetsByUser?.collections?.isPlanSubscribed === "Yes" ? (
                    <>
                      {assetsByUser?.collections?.data?.length > 0 ? (
                        assetsByUser?.collections?.data
                          ?.filter(
                            (collection) => collection.published_status === "2",
                          )
                          .map((collection) => {
                            return (
                              <>
                                <div
                                  key={collection?.collection_id}
                                  class="form-check"
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={`${collection?.collection_name}_${collection?.collection_id}`}
                                    onClick={(evt) => {
                                      if (evt.target.checked) {
                                        setSendBulkEmail((previousState) => ({
                                          ...previousState,
                                          collections: [
                                            ...previousState?.collections,
                                            collection?.collection_id,
                                          ],
                                        }));
                                      } else {
                                        if (
                                          sendBulkEmail?.collections.includes(
                                            collection?.collection_id,
                                          )
                                        ) {
                                          setSendBulkEmail((previousState) => {
                                            let newArr =
                                              previousState?.collections.reduce(
                                                (acc, cur) => {
                                                  if (
                                                    !cur.includes(
                                                      collection?.collection_id,
                                                    )
                                                  ) {
                                                    acc.push(cur);
                                                  }
                                                  return acc;
                                                },
                                                [],
                                              );
                                            return {
                                              ...previousState,
                                              collections: [...newArr],
                                            };
                                          });
                                        }
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label fw-normal fs-11"
                                    for={`${collection?.collection_name}_${collection?.collection_id}`}
                                  >
                                    {collection?.collection_name}
                                  </label>
                                </div>
                              </>
                            );
                          })
                      ) : (
                        <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">
                          No collections to add
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">
                        No subscription found
                      </p>
                    </>
                  )}
                </ul>
              </li>
              <li>
                <p className="mb-0 fw-semibold fs-10 px-2 py-1 rounded-2 border border-2 bg-white mb-1">
                  VIRTUAL TOURS
                </p>

                <ul
                  style={{
                    listStyle: "none",
                    overflowY: "auto",
                    height: "100%",
                  }}
                >
                  {assetsByUser?.virtual_tours?.isPlanSubscribed === "Yes" ? (
                    <>
                      {assetsByUser?.virtual_tours?.data?.length > 0 ? (
                        assetsByUser?.virtual_tours?.data
                          ?.filter((tour) => tour.published_status === "active")
                          .map((tour) => {
                            return (
                              <>
                                <div key={tour?.tour_id} class="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={`${tour?.tour_name}_${tour?.tour_id}`}
                                    onClick={(evt) => {
                                      if (evt.target.checked) {
                                        setSendBulkEmail((previousState) => ({
                                          ...previousState,
                                          virtual_tours: [
                                            ...previousState?.virtual_tours,
                                            tour?.tour_id,
                                          ],
                                        }));
                                      } else {
                                        if (
                                          sendBulkEmail?.virtual_tours.includes(
                                            tour?.tour_id,
                                          )
                                        ) {
                                          setSendBulkEmail((previousState) => {
                                            let newArr =
                                              previousState?.virtual_tours.reduce(
                                                (acc, cur) => {
                                                  if (
                                                    !cur.includes(tour?.tour_id)
                                                  ) {
                                                    acc.push(cur);
                                                  }
                                                  return acc;
                                                },
                                                [],
                                              );
                                            return {
                                              ...previousState,
                                              virtual_tours: [...newArr],
                                            };
                                          });
                                        }
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label fw-normal fs-11"
                                    for={`${tour?.tour_name}_${tour?.tour_id}`}
                                  >
                                    {tour?.tour_name}
                                  </label>
                                </div>
                              </>
                            );
                          })
                      ) : (
                        <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">
                          No tours to add
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">
                        No subscription found
                      </p>
                    </>
                  )}
                </ul>
              </li>
              <li>
                <p className="mb-0 fw-semibold fs-10 px-2 py-1 rounded-2 border border-2 bg-white mb-1">
                  ASSETS
                </p>

                <ul
                  style={{
                    listStyle: "none",
                    overflowY: "auto",
                    height: "100%",
                  }}
                >
                  {assetsByUser?.assets?.isPlanSubscribed === "Yes" ? (
                    <>
                      {assetsByUser?.assets?.data.length > 0 ? (
                        assetsByUser?.assets?.data
                          ?.filter((asset) => asset.published_status === "2")
                          .map((asset) => {
                            // console.log(sendBulkEmail,"return asset");
                            return (
                              <>
                                <div key={asset?.asset_id} class="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={`${asset?.asset_name}_${asset?.asset_id}`}
                                    onClick={(evt) => {
                                      if (evt.target.checked) {
                                        setSendBulkEmail((previousState) => ({
                                          ...previousState,
                                          assets: [
                                            ...previousState?.assets,
                                            asset?.asset_id,
                                          ],
                                        }));
                                      } else {
                                        if (
                                          sendBulkEmail?.assets.includes(
                                            asset?.asset_id,
                                          )
                                        ) {
                                          setSendBulkEmail((previousState) => {
                                            let newArr =
                                              previousState?.assets.reduce(
                                                (acc, cur) => {
                                                  if (
                                                    !cur.includes(
                                                      asset?.asset_id,
                                                    )
                                                  ) {
                                                    acc.push(cur);
                                                  }
                                                  return acc;
                                                },
                                                [],
                                              );
                                            return {
                                              ...previousState,
                                              assets: [...newArr],
                                            };
                                          });
                                        }
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label fw-normal fs-11"
                                    for={`${asset?.asset_name}_${asset?.asset_id}`}
                                  >
                                    {asset?.asset_name}
                                  </label>
                                </div>
                              </>
                            );
                          })
                      ) : (
                        <p className="mb-0 fw-normal mb-1 fs-11 fst-italic ">
                          No assets to add
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">
                        No subscription found
                      </p>
                    </>
                  )}
                </ul>
              </li>
            </ul>
          </div>
        ) : null}
      </>
    );
  },
);

export default BulkEmailCards;
