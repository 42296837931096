import React from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import "./FileUpload.css";
import { FaChevronLeft, FaFileDownload, FaCopy } from "react-icons/fa";
import { Menu } from "../../../functions/menu";
import { IoShareSocialSharp } from "react-icons/io5";

const FileDetails = () => {
  const { fileName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const fileDetails = location.state;

  if (!fileDetails) {
    return (
      <div className="file-details-container">
        <h2>No File Details Available</h2>
        <Link to="/card">Go Back</Link>
      </div>
    );
  }
  console.log(fileDetails);

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} bytes`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  };

  const fileUrl = URL.createObjectURL(
    new Blob([fileDetails], { type: fileDetails.file.type })
  );
  const profileUrl = window.location.href;
  return (
    <div className="file-details-container ">
      <div
        className="d-flex w-100 align-items-center justify-content-between p-2"
        style={{ backgroundColor: "#5411dc", color: "#ffff" }}
      >
        <FaChevronLeft
          size={25}
          onClick={() => {
            navigate("/card");
          }}
          className="text-white"
          style={{ cursor: "pointer" }}
        />
        <p className="fw-semibold fs-5 m-0"> {"File Details"}</p>
        <span className="d-flex " style={{ width: "20px" }}></span>
      </div>
      <div
        className="d-flex flex-column justify-content-start  bg-white rounded-2 w-100 "
        style={{ height: "90%" }}
      >
        {/* <div
              className="d-flex flex-column justify-content-start align-items-center overflow-y-auto w-100 "
              style={{ height: size.height }}
            >
              {BulkEmailState?.activeTab === "cards" ? (
                <CardsTab
                  data={BulkEmailState?.assets?.digital_cards}
                  state={BulkEmailState}
                  setState={BulkEmailDispatch}
                />
              ) : null}
              {BulkEmailState?.activeTab === "collections" ? (
                <CollectionsTab
                  data={BulkEmailState?.assets?.collections}
                  state={BulkEmailState}
                  setState={BulkEmailDispatch}
                />
              ) : null}
              {BulkEmailState?.activeTab === "tours" ? (
                <ToursTab
                  data={BulkEmailState?.assets?.virtual_tours}
                  state={BulkEmailState}
                  setState={BulkEmailDispatch}
                />
              ) : null}
              {BulkEmailState?.activeTab === "assets" ? (
                <AssetsTab
                  data={BulkEmailState?.assets?.assets}
                  state={BulkEmailState}
                  setState={BulkEmailDispatch}
                />
              ) : null}
            </div> */}
      </div>
      <div className="d-flex flex-column  align-items-center mt-5 vh-100">
        <p className="text-break text-center">
          <strong>Name:</strong> {fileDetails.file.name}
        </p>
        <p className="text-break text-center">
          <strong>Type:</strong> {fileDetails.file.type || "Unknown"}
        </p>
        <p>
          <strong>Size:</strong> {formatFileSize(fileDetails.file.size)}
        </p>
        <div className="qr-code-container">
          <QRCode value={fileUrl} />
        </div>
        <div className="d-flex gap-1">
          <div className="d-flex align-items-center justify-content-center btn btn-primary mt-3  fw-semibold ">
            <Link style={{ textDecoration: "none", color: "#ffff" }} to="/card">
              <FaCopy />
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-center btn btn-primary mt-3  fw-semibold ">
            <Link style={{ textDecoration: "none", color: "#ffff" }} to="/card">
              PASSWORD
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-center btn btn-primary mt-3  fw-semibold ">
            <Link style={{ textDecoration: "none", color: "#ffff" }} to="/card">
              <FaFileDownload />
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-center btn btn-primary mt-3  fw-semibold ">
            <Link style={{ textDecoration: "none", color: "#ffff" }} to="/card">
              <IoShareSocialSharp />
            </Link>
          </div>
        </div>
      </div>
      <Menu />
    </div>
  );
};

export default FileDetails;
