import React, {
  useState,
  useEffect,
  memo,
  useMemo,
  useRef,
  useReducer,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";

//API SERVICES
import { AssetsByUserAPI, DisplayLeads_API } from "../services/userservices";
import { Menu } from "../functions/menu";
import { AiOutlinePlus } from "react-icons/ai";
import BulkEmailModal from "../screens/Displayleads/BulkEmailModal";

import { ToastContainer, toast } from "react-toastify";
import { useCustomPagination, useWindowSize } from "../functions/functions";
import UpgradePlan from "../functions/UpgradePlan";
import ContactsTab from "../screens/Displayleads/ContactsTab";
import ListTab from "../screens/Displayleads/ListTab";
import ShareEmail from "../screens/Emails/components/ShareEmail";
import FullScreenModal from "../functions/FullScreenModal";
import { t } from "i18next";

const Displayleads = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { key } = location.state || {};
  const size = useWindowSize();
  const initialBulkEmailState = {
    activeTab: "cards",
    assets: {},
    errors: {},
    loader: false,
    share_email: {
      template: {
        subject: "",
        body: "",
      },
      data: {
        email_instance_id: null,
        source_id: null,
        content_type: null,
      },
    },
    isShareEmailModalOpen: false,
    fallbackSocialShare: {
      url: "",
      isModalOpen: false,
    },
  };

  const BulkEmailReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_TAB":
        return { ...state, activeTab: action.value };

      case "UPDATE_ASSETS":
        return { ...state, assets: action.value };

      case "UPDATE_FETCH_ASSET_ERRORS":
        return { ...state, errors: action.value };

      case "UPDATE_LOADER_STATE":
        return { ...state, loader: action.value };

      case "UPDATE_EMAIL_TEMPLATE": {
        // console.log(action);
        return {
          ...state,
          share_email: {
            template: {
              subject: action.subject,
              body: action.body,
            },
            data: {
              email_instance_id: action.instanceID,
              source_id: action.sourceID,
              content_type: action.contentType,
            },
          },
        };
      }
      case "UPDATE_SHARE_MODAL_STATE":
        return { ...state, isShareEmailModalOpen: action.value };
      case "UPDATE_FALLBACK_STATE":
        return {
          ...state,
          fallbackSocialShare: { isModalOpen: action.state, url: action.url },
        };

      default:
        return state;
    }
  };
  const [BulkEmailState, BulkEmailDispatch] = useReducer(
    BulkEmailReducer,
    initialBulkEmailState
  );

  useEffect(() => {
    if (key == "List") {
      setActiveTab("lists");
    }
    async function fetchData() {
      BulkEmailDispatch({ type: "UPDATE_LOADER_STATE", value: true });
      try {
        let AllAssets = await AssetsByUserAPI({
          exhibitor_id: JSON.parse(localStorage.getItem("exhibitor_id")),
        });
        let activePlans = [];
        Object.keys(AllAssets).map((planName) => {
          if (AllAssets[planName]?.isPlanSubscribed === "Yes") {
            if (planName === "assets") {
              activePlans.push("assets");
            } else if (planName === "tours") {
              activePlans.push("tours");
            } else if (planName === "collections") {
              activePlans.push("collections");
            } else if (planName === "cards") {
              activePlans.push("cards");
            }
          }
        });
        BulkEmailDispatch({ type: "UPDATE_TAB", value: activePlans[0] });
        BulkEmailDispatch({ type: "UPDATE_ASSETS", value: AllAssets });
      } catch (err) {
        BulkEmailDispatch({ type: "UPDATE_FETCH_ASSET_ERRORS", value: err });
      }
      BulkEmailDispatch({ type: "UPDATE_LOADER_STATE", value: false });
    }

    fetchData();
  }, []);
  const [pageLoader, setPageLoader] = useState(true);
  const [activeTab, setActiveTab] = useState("contacts");
  const [showLeads, setShowLeads] = useState("");
  const [fromList, setFromList] = useState({ filter: false, listId: null });
  const { Paginator, pageWiseData, currentPage, setCurrentPage } =
    useCustomPagination({
      datas: showLeads,
      pageSize: 20,
      siblingCount: 1,
    });

  const [bulkEmail, setBulkEmail] = useState({
    selected_contacts: [],
    selectedEmails: [],
    isBulkEmailModalOpen: false,
  });

  const [showRecentLeads, setShowRecentLeads] = useState({});
  const Goback_fn = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    setTimeout(() => {
      setPageLoader(false);
    }, 1000);

    //--------------------------------------------------Online Work---------------------------------------------------------//
    if (window.navigator.onLine) {
      let payload = {
        id: JSON.parse(localStorage?.getItem("exhibitor_id")),
      };

      DisplayLeads_API(payload).then((res) => {
        if (res.status.localeCompare("success") == 0) {
          let EditItmData = "";
          if (JSON.parse(localStorage.getItem("EditItems")) != null) {
            EditItmData = JSON.parse(localStorage.getItem("EditItems"));
          }

          let InsertItmData = "";
          if (JSON.parse(localStorage.getItem("InsertItems")) != null) {
            InsertItmData = JSON.parse(localStorage.getItem("InsertItems"));
          }

          let leads_data = {};
          res.data.map((leads) => {
            if (EditItmData != "") {
              if (
                EditItmData.hasOwnProperty(leads.data.email_address) &&
                EditItmData[leads.data.email_address].edit_status == false
              ) {
                leads_data[leads.data.email_address] =
                  EditItmData[leads.data.email_address];
              } else {
                leads_data[leads.data.email_address] = leads.data;
              }
            } else {
              leads_data[leads.data.email_address] = leads.data;
            }

            if (InsertItmData != "") {
              if (
                InsertItmData.hasOwnProperty(leads.data.email_address) &&
                InsertItmData[leads.data.email_address].insert_status
              ) {
                delete InsertItmData[leads.data.email_address];
                localStorage.setItem(
                  "InsertItems",
                  JSON.stringify(InsertItmData)
                );
              }
            }
          });

          localStorage.setItem("DisplayLeads", JSON.stringify(leads_data));

          JSON.parse(localStorage.getItem("DisplayLeads"));
          setShowLeads(JSON.parse(localStorage.getItem("DisplayLeads")));

          if (JSON.parse(localStorage.getItem("InsertItems")) == null) {
            setShowRecentLeads({});
          } else {
            setShowRecentLeads(JSON.parse(localStorage.getItem("InsertItems")));
          }
        }
      });
    }
    //--------------------------------------------------Online Work---------------------------------------------------------//

    //--------------------------------------------------Offline Work---------------------------------------------------------//
    if (window.navigator.onLine == false) {
      if (JSON.parse(localStorage.getItem("DisplayLeads")) != null) {
        let _obj = JSON.parse(localStorage.getItem("DisplayLeads"));
        setShowLeads(_obj);
      } else {
        localStorage.setItem("DisplayLeads", JSON.stringify({}));
        setShowLeads(JSON.parse(localStorage.getItem("DisplayLeads")));
      }

      if (JSON.parse(localStorage.getItem("InsertItems")) == null) {
        setShowRecentLeads({});
      } else {
        setShowRecentLeads(JSON.parse(localStorage.getItem("InsertItems")));
      }
    }
    //--------------------------------------------------Offline Work---------------------------------------------------------//
  }, []);

  return (
    <>
      <>
        {/*------------------------------------------Display Leads---------------------------------------------------*/}

        <div
          className="d-flex flex-column p-0  mx-auto"
          style={{
            overflowY: "hidden",
            height: size.height,
          }}
        >
          <div
            className="d-flex justify-content-between flex-column  align-items-center "
            style={{
              backgroundColor: "#5411dc",
              maxHeight: size.height,
              overflowY: "auto",
            }}
          >
            <div
              className="d-flex w-100 text-start align-items-center justify-content-between py-3 px-3"
              style={{ color: "white", fontSize: "2em" }}
            >
              <FaChevronLeft
                size={25}
                onClick={Goback_fn}
                style={{ cursor: "pointer" }}
              />
              <p className="m-0 fw-semibold fs-4">
                {t("contactsMenu.contacts")}
              </p>
              {JSON.parse(localStorage.getItem("config_settings"))
                .contact_manager_enabled === "Yes" ? (
                <p className="m-0 " style={{ width: "20px" }}>
                  <AiOutlinePlus
                    className="text-white"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/leads");
                    }}
                  />
                </p>
              ) : null}
            </div>
            <div className="d-flex w-100 bg-white rounded-top   px-2 pt-3 pb-1 ">
              <ul className="nav nav-tabs">
                <li
                  className="nav-item"
                  style={{ cursor: "pointer" }}
                  onClick={(evt) => {
                    evt.preventDefault();
                    setActiveTab("contacts");
                  }}
                >
                  <a
                    className={`nav-link  fw-semibold fs-10 ${
                      activeTab === "contacts" ? "active" : null
                    }`}
                    id="contact_tab"
                    aria-current="page"
                  >
                    {t("contacts")}
                  </a>
                </li>
                <li
                  className="nav-item"
                  style={{ cursor: "pointer" }}
                  onClick={(evt) => {
                    evt.preventDefault();
                    setActiveTab("lists");
                  }}
                >
                  <a
                    className={`nav-link  fw-semibold fs-10 ${
                      activeTab === "lists" ? "active" : null
                    }`}
                    id="list_tab"
                  >
                    {t("contactsMenu.listsCaps")}
                  </a>
                </li>
              </ul>
            </div>
            {JSON.parse(localStorage.getItem("config_settings"))
              .contact_manager_enabled === "No" ? (
              pageLoader ? (
                <>
                  <div
                    className="d-flex w-100 bg-white align-items-center justify-content-center"
                    style={{ height: size.height }}
                  >
                    <div
                      className="spinner-border text-primary "
                      role="status"
                      style={{ width: "200px", height: "200px" }}
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <UpgradePlan />
              )
            ) : activeTab === "contacts" ? (
              <>
                {showLeads !== "" ? (
                  <>
                    <ContactsTab
                      bulkEmail={bulkEmail}
                      setBulkEmail={setBulkEmail}
                      showLeads={showLeads}
                      setShowLeads={setShowLeads}
                      pageWiseData={pageWiseData}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      showRecentLeads={showRecentLeads}
                      setShowRecentLeads={setShowRecentLeads}
                      state={BulkEmailState}
                      setState={BulkEmailDispatch}
                      fromList={fromList}
                      setFromList={setFromList}
                    />
                    <Paginator />
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex w-100 bg-white align-items-center justify-content-center"
                      style={{ height: size.height }}
                    >
                      <div
                        className="spinner-border text-primary "
                        role="status"
                        style={{ width: "200px", height: "200px" }}
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {JSON.parse(localStorage.getItem("Lists")) ? (
                  <ListTab
                    lists={JSON.parse(localStorage.getItem("Lists"))}
                    setActiveTab={setActiveTab}
                    setFromList={setFromList}
                  />
                ) : (
                  <>
                    <div
                      className="d-flex w-100 bg-white align-items-center justify-content-center"
                      style={{ height: size.height }}
                    >
                      <div
                        className="spinner-border text-primary "
                        role="status"
                        style={{ width: "200px", height: "200px" }}
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <Menu />
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        {/*------------------------------------------Display Leads---------------------------------------------------*/}
      </>

      {bulkEmail?.isBulkEmailModalOpen && (
        <BulkEmailModal
          dependencies={{ bulkEmail: bulkEmail, setBulkEmail: setBulkEmail }}
        />
      )}
      {BulkEmailState?.isShareEmailModalOpen === true ? (
        <FullScreenModal>
          <ShareEmail
            dependencies={{
              state: BulkEmailState,
              setState: BulkEmailDispatch,
              data: {
                ...BulkEmailState?.share_email?.data,
              },
              share_email_template: BulkEmailState?.share_email?.template,
              exhibitor_id: JSON.parse(localStorage.getItem("exhibitor_id")),
              bulkEmail: bulkEmail,
            }}
          />
        </FullScreenModal>
      ) : null}
    </>
  );
});
export default Displayleads;
