import React, { useState } from "react";
import { FaChevronLeft, FaPencilAlt, FaPlus } from "react-icons/fa";
import { Menu } from "../functions/menu";
import { useNavigate } from "react-router-dom";

function AddGroup() {
  const [image, setImage] = useState("https://via.placeholder.com/50");
  const [promptSelected, setPromptSelected] = useState(false);
  const [questionSelected, setQuestionSelected] = useState(false);
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handlePromptChange = (e) => {
    setPromptSelected(e.target.value !== "");
  };

  const handleQuestionChange = (e) => {
    setQuestionSelected(e.target.value !== "");
  };

  return (
    <div className="d-flex flex-column justify-content-between" style={{ minHeight: "100vh" }}>
      <div
        className="d-flex w-100 justify-content-between align-items-center"
        style={{
          color: "white",
          fontSize: "2em",
          padding: "1%",
          backgroundColor: "#5411dc",
        }}
      >
        <FaChevronLeft
          size={25}
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        />
        <p className="m-0 fw-semibold fs-4">Add Group</p>
        <p></p>
      </div>

      <form className="m-3">
        <div className="mb-3">
          <div className="position-relative">
            <input
              className="form-control d-none"
              type="file"
              id="groupImage"
              onChange={handleImageChange}
            />
            <div className="image-upload-wrapper d-flex justify-content-center border-2">
              {image ? (
                <img
                  src={image}
                  alt="Group"
                  className="img-fluid rounded-circle"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                  }}
                  onClick={() => document.getElementById("groupImage").click()}
                />
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center rounded-circle bg-light"
                  style={{ width: "150px", height: "150px", cursor: "pointer" }}
                  onClick={() => document.getElementById("groupImage").click()}
                >
                  <FaPencilAlt size={24} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="groupName" className="form-label text-primary">
            Group Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="groupName"
            placeholder="Enter group name"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="groupType" className="form-label text-primary">
            Group Visibility <span className="text-danger">*</span>
          </label>
          <select className="form-select" id="groupType">
            <option value="">Select group visibility</option>
            <option value="study">Public</option>
            <option value="work">Private</option>
            <option value="friends">Invite Only</option>
            
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="groupType" className="form-label text-primary">
            Group Type <span className="text-danger">*</span>
          </label>
          <select className="form-select" id="groupType">
            <option value="">Select group type</option>
            <option value="study">General</option>
            <option value="study">Event (People met at event)</option>
            <option value="work">Association (Association members)</option>
            <option value="friends">Location (People in a region)</option>
            <option value="friends">Community</option>
            
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="promptSelect" className="form-label text-primary">
            Add prompt <span className="text-danger">*</span>
          </label>
          <select className="form-select border-0 mb-2" id="promptSelect" onChange={handlePromptChange}>
            <option value="">Select prompt</option>
            <option value="study">Areas we focus on ?</option>
            <option value="work">what we can help with ?</option>
            <option value="friends">what we need most right now ?</option>
            
          </select>
          {promptSelected && (
            <input
              type="text"
              className="form-control mt-2"
              id="prompt"
              placeholder="Enter prompt"
            />
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="questionSelect" className="form-label text-primary">
            Add question
          </label>
          <select className="form-select border-0 mb-2" id="questionSelect" onChange={handleQuestionChange}>
            <option value="">Select question</option>
            <option value="study">Areas we focus on or deal with ?</option>
            <option value="work">what we can help with to solve ?</option>
            <option value="friends">what we need most right now ?</option>
          </select>
          {questionSelected && (
            <input
              type="text"
              className="form-control mt-2"
              id="question"
              placeholder="Enter question"
            />
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="groupDescription" className="form-label text-primary">
            Group Description
          </label>
          <textarea
            className="form-control"
            id="groupDescription"
            rows="3"
            placeholder="Enter group description"
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">
          Create Group
        </button>
      </form>
      <div>
        <Menu />
      </div>
    </div>
  );
}

export default AddGroup;
