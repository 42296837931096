import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { OtherPagesCheck } from "../App";
import { Menu } from "../functions/menu";
import { RiMenu3Fill, RiSendPlaneFill } from "react-icons/ri";

import frenchflag from "../../src/assets/flags/french_flag.jpg";
import germanyflag from "../../src/assets/flags/germany_flag.jpg";
import arabic from "../../src/assets/flags/Arabic-Language-Flag.svg";
import engFlag from "../../src/assets/flags/us_flag.jpg";
import inFlag from "../../src/assets/flags/in.svg";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { RiLogoutBoxLine } from "react-icons/ri";
import brandLogo from "../../src/assets/logoOnly.png";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";
import Stats from "./Stats";
import { RiContactsBook2Fill, RiQrScan2Line } from "react-icons/ri";
import { FaRegHandshake } from "react-icons/fa";
import { VscListSelection } from "react-icons/vsc";
import { FaRegShareSquare } from "react-icons/fa";
import { CiImageOn } from "react-icons/ci";
import { MdSettings } from "react-icons/md";
const Dashboard = () => {
  const navigate = useNavigate();

  const Navigate = (path) => {
    navigate("/" + path);
  };
  const orginMap = {
    en: "English",
    fr: "Français",
    de: "Deutsch",
    ar: "عربي",
    ta: "தமிழ்",
    hi: "हिन्दी",
  };
  const getInitialLanguage = () => {
    const savedLanguage = localStorage.getItem("language") || "en";
    const flagMap = {
      en: engFlag,
      fr: frenchflag,
      de: germanyflag,
      ar: arabic,
      ta: inFlag,
      hi: inFlag,
    };

    return {
      name: savedLanguage,
      flag: flagMap[savedLanguage],
      orgin: orginMap[savedLanguage],
    };
  };

  const [selectedLanguage, setSelectedLanguage] = useState(getInitialLanguage);

  useEffect(() => {
    changeLanguage(selectedLanguage.name);
  }, []);
  const handleLanguageChange = (language, flag) => {
    setSelectedLanguage({ name: language, flag, orgin: orginMap[language] });
    changeLanguage(language);
  };
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  return (
    <>
      {/*------------------------------------------Display Leads---------------------------------------------------*/}
      <Navbar
        expand="false"
        className="bg-primary rounded-bottom sticky-top "
        style={{
          background:
            "linear-gradient(142deg, rgb(230, 230, 251) 0%, rgb(230, 224, 246) 33%, rgb(232, 223, 247) 66%, rgb(223, 206, 235) 100%)",
        }}
      >
        <Container fluid>
          {/* <Navbar.Toggle aria-controls="navbarScroll" className="py-2"/> */}
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="custom-toggler py-2"
          >
            {/* <TiThMenuOutline className="text-primary" /> */}
            <RiMenu3Fill
              className="text-primary border border-primary rounded"
              size={30}
            />
          </Navbar.Toggle>
          <Navbar.Brand
            href="#"
            className="py-2 position-absolute top-0 start-50 translate-middle-x"
          >
            <div style={{ backgroundColor: "#fff" }} className="rounded-circle">
              <img src={brandLogo} height={50} width={50} className="p-1" />
            </div>
          </Navbar.Brand>

          <Navbar.Brand className="d-flex align-items-center ms-2 me-0">
            <Link to={"/settings"}>
              <Avatar
                src="/static/images/avatar/1.jpg"
                sx={{ width: "35px !important", height: "35px !important" }}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              // style={{ maxHeight: '200px' }}
              navbarScroll
            >
              <Nav.Link
                onClick={() => {
                  navigate("/showleads");
                }}
              >
                Contacts
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/card");
                }}
              >
                Cards
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/groups");
                }}
              >
                Dates
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/stories");
                }}
              >
                Stories
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/qr");
                }}
              >
                QR
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/ocr");
                }}
              >
                Card Scanner
              </Nav.Link>

              <Nav.Link
                onClick={() => {
                  navigate("/settings");
                }}
              >
                Account
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  localStorage.setItem("user_logged_in", JSON.stringify(false));
                  window.location = `${window.location.origin}/login`;
                }}
              >
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="container-fluid min-vh-100 my-auto p-0">
        <div
          className="d-flex flex-column align-items-center w-100 min-vh-100 "
          style={{ height: "100%" }}
        >
          {/* <div className="d-flex w-100 align-items-center justify-content-between p-3  sticky-top glass-effect rounded-bottom"
          style={{  height: "100%",backgroundColor: "#5411dc", }}>
            <div style={{ backgroundColor: "#fff" }} className="rounded-circle"> 
          <img src={brandLogo} height={50} width={50} className="p-1"/>
          
            </div>
           
            <p className="fw-semibold fs-5 mx-2 my-0 " style={{ color: "#fff" }}>
          
              {t('dashboard')}
            </p>
          

          </div> */}

          <div className="d-flex gap-2 my-3 flex-wrap justify-content-center">
            <Stats
              name="Contacts"
              number={140}
              icon={<RiContactsBook2Fill size={30} color="6610f2" />}
              onClick={() => {
                navigate("/showleads");
              }}
            />
            <Stats
              name="Meetings"
              number={45}
              icon={<FaRegHandshake size={30} color="6610f2" />}
              onClick={() => {
                navigate("/groups", { state: { key: "Remainder" } });
              }}
            />
            <Stats
              name="Lists"
              number={10}
              icon={<VscListSelection size={30} color="6610f2" />}
              onClick={() => {
                navigate("/showleads", { state: { key: "List" } });
              }}
            />
            <Stats
              name="Shares"
              number={60}
              icon={<FaRegShareSquare size={30} color="6610f2" />}
              onClick={() => {
                navigate("/card", { state: { key: "collections" } });
              }}
            />
            <Stats
              name="QR Scans"
              number={6}
              icon={<RiQrScan2Line size={30} color="6610f2" />}
              onClick={() => {
                navigate("/qr", { state: { key: "History" } });
              }}
            />
            <Stats
              name="Stories"
              number={40}
              icon={<CiImageOn size={30} color="6610f2" />}
              onClick={() => {
                navigate("/stories");
              }}
            />
          </div>
        </div>
      </div>
      <Menu />
      {/*------------------------------------------Display Leads---------------------------------------------------*/}
    </>
  );
};
export default Dashboard;
