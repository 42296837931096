import React, { memo } from "react";
import { Modal } from "../../../functions/Modal";
import { AiOutlineCloseSquare } from "react-icons/ai";

//API SERVICES
import { HistoryForQrcode_API } from "../../../services/userservices";

export const DeleteConformationModal = memo(
  ({ dependencies: { state, setState, EXHIBITOR_ID } }) => {
    const Cancel_Fn = () => {
      setState({
        type: "HISTROY_DELETE_MODAL_SHOW_STATUS",
        HistroyDelete: {
          ModalStatus: false,
          DeleteId: -1,
        },
      });
    };

    const Submit_Fn = () => {
      const PayloadFormData = new FormData();
      PayloadFormData.append("action", "deleted_contact_qr_code");
      PayloadFormData.append("exhibitor_id", EXHIBITOR_ID);
      PayloadFormData.append("qr_code_id", state?.HistroyDelete?.DeleteId);
      PayloadFormData.append("delete_data_by", "individual");
      HistoryForQrcode_API(PayloadFormData).then((res) => {
        if (res?.status?.localeCompare("success") == 0) {
          setState({
            type: "FETCH_HISTORY_FROM_API",
            ScannerHistory: res?.data,
          });
          setState({
            type: "HISTROY_DELETE_MODAL_SHOW_STATUS",
            HistroyDelete: {
              ModalStatus: false,
              DeleteId: -1,
            },
          });
        }
      });
    };
    return (
      <>
        <Modal>
          <div className="d-flex justify-content-between align-items-center  py-2 px-2">
            <p className="fst-italic fs-10 fw-semibold text-dark mb-0">
              Are you sure want to delete the histroy?
            </p>
          </div>
          <div className="d-flex justify-content-between py-2 px-2">
            <button
              type="button"
              className="d-flex justify-content-center align-items-center flex-fill btn btn-light rounded-0 fs-11"
              onClick={Cancel_Fn}
            >
              No
            </button>
            <button
              type="button"
              className="d-flex justify-content-center  align-items-center flex-fill btn btn-primary  rounded-0 fs-11"
              onClick={Submit_Fn}
            >
              Yes am sure
            </button>
          </div>
        </Modal>
      </>
    );
  },
);
