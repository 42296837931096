import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import SocialScreen from "./SocialScreen";

const Register = () => {
  const location = useLocation();
  const key = location.state || {};
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    navigate("/profile/1");
  };

  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column align-items-center">
      <div
        className="d-flex w-100 align-items-center mb-2 py-3 justify-content-between"
        style={{ backgroundColor: "#5411dc", color: "#fff" }}
      >
        <FaChevronLeft
          size={25}
          onClick={() => navigate("/card", { state: { key: "tours" } })}
          className="text-white"
          style={{ cursor: "pointer" }}
        />
        <p className="fw-semibold fs-5 m-0">Add Card Information</p>
        <span className="d-flex" style={{ width: "20px" }}></span>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column mt-2 fs-11"
      >
        <SocialScreen />
        <div className="d-flex justify-content-center">
          {" "}
          <button
            className="d-flex align-items-center justify-content-center m-4 btn btn-primary fw-semibold  py-2"
            style={{ borderRadius: "10px", color: "#fff" }}
            type="submit"
            onClick={() => {
              navigate("/card", { state: { key: "tours" } });
            }}
          >
            <p className="m-0">Submit</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Register;
