// src/components/DetailedProfile.js
import React from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import "./DetailedProfile.css";
import { FaChevronLeft, FaCopy } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";
import { Menu } from "../../../functions/menu";
import { FaEdit } from "react-icons/fa";
import SocialScreen from "./SocialScreen";

const DetailedProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = location.state || {};

  if (!profile) {
    return <div>Profile not found</div>;
  }
  console.log(profile);

  const profileUrl = window.location.href; // The current page URL

  return (
    <div className="file-details-container ">
      <div
        className="d-flex w-100 align-items-center justify-content-between   p-2"
        style={{ backgroundColor: "#5411dc", color: "#ffff" }}
      >
        <FaChevronLeft
          size={25}
          onClick={() => {
            navigate("/card", { state: { key: "tours" } });
          }}
          className="text-white"
          style={{
            cursor: "pointer",
            /* IE and Edge */
          }}
        />
        <p className="fw-semibold fs-5 m-0"> {"Profile Details"}</p>
        <span className="d-flex" style={{ width: "20px" }}></span>
      </div>

      <div
        className=" detailed-profile-card h-100 p-2 border-rounded vh-100"
        style={{ overflow: "scroll" }}
      >
        <div className="d-flex justify-content-end ">
          {" "}
          <button
            className="d-flex align-items-center justify-content-center  btn  fw-semibold  "
            style={{ borderRadius: "10px", color: "black" }}
            type="submit"
            onClick={() => {
              navigate("/create-card", { state: profile });
            }}
          >
            <FaEdit size={24} />
          </button>
          {/* <button
            className="d-flex align-items-center justify-content-center  btn  fw-semibold  "
            style={{ borderRadius: "10px", backgroundColor: "#6610f2" }}
            type="submit"
            onClick={() => {
              navigate("/event", { state: profile });
            }}
          >
            <p className="m-0 fs-10 text-white">Create Event page</p>
          </button> */}
        </div>
        <div className="profile-header text-center p-1">
          <img
            src={profile.card_thumbnail_image}
            alt={profile.card_profile_data.card_name}
            className="dprofile-image "
          />
          <h2 className="pro-name mt-1">
            {profile.card_profile_data.card_name}
          </h2>
          <p className="fs- mb-1 color">
            {profile.card_profile_data.card_job_title}
          </p>
        </div>
        <div className="profile-details">
          <p className="fw-light text-start" style={{ letterSpacing: "1px" }}>
            {profile.card_profile_data.card_headline}
          </p>
          <div class="container text-start p-0">
            <hr></hr>
            <div class="row ">
              <div class="col color fw-semibold">Email:</div>
              <div class="col-8 p-0">
                {profile.card_profile_data.card_email}
              </div>
            </div>
            <hr></hr>
            <div class="row ">
              <div class="col color fw-semibold">Phone:</div>
              <div class="col-8 p-0">
                {profile.card_profile_data.card_phone}
              </div>
            </div>
            <hr></hr>
            <div class="row ">
              <div class="col color fw-semibold">Location:</div>
              <div class="col-8 text-break p-0">
                {profile.card_profile_data.card_address}
              </div>
            </div>
            <hr></hr>
            <div class="row ">
              <div class="col color fw-semibold">Website:</div>
              <a
                class="col-8 text-break p-0"
                href={profile.card_profile_data.card_website_url}
                style={{ textDecoration: "none" }}
              >
                {profile.card_profile_data.card_website_url}
              </a>
            </div>
            <hr></hr>

            <div class="row ">
              <div class="col color fw-semibold">twitter:</div>
              <a
                class="col-8 text-break p-0"
                href={profile.card_profile_data.card_website_url}
                style={{ textDecoration: "none" }}
              >
                {"https://x.com/"}
              </a>
            </div>
            <hr></hr>
            <div class="row ">
              <div class="col color fw-semibold">Instagram:</div>
              <a
                class="col-8 text-break p-0"
                href={"https://www.instagram.com/"}
                style={{ textDecoration: "none" }}
              >
                {"https://www.instagram.com/"}
              </a>
            </div>
            <hr></hr>
            <div class="row ">
              <div class="col color fw-semibold">linkedin:</div>
              <a
                class="col-8 text-break p-0"
                href={"https://in.linkedin.com/"}
                style={{ textDecoration: "none" }}
              >
                {"https://in.linkedin.com/"}
              </a>
            </div>
            <hr></hr>
            <div class="row ">
              <div class="col color fw-semibold">facebook:</div>
              <a
                class="col-8 text-break p-0"
                href={"https://www.facebook.com/"}
                style={{ textDecoration: "none" }}
              >
                {"https://www.facebook.com/"}
              </a>
            </div>
          </div>
        </div>
      </div>

      <Menu />
    </div>
  );
};

export default DetailedProfile;
