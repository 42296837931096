import React, { useState, useEffect } from "react";
import { ProfileInfo } from "../screens/ViewContact/ProfileInfo";
import { ProfileOptions } from "../screens/ViewContact/ProfileOptions";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { NewBusinessCard_API } from "../services/userservices";
import { t } from "i18next";
export const ViewContact = () => {
  const navigate = useNavigate();
  const Goback_fn = () => {
    navigate(-1);
  };

  let [profiledata, setProfileData] = useState("");
  const EXHIBITOR_ID = JSON.parse(localStorage.getItem("exhibitor_id"));

  useEffect(() => {
    if (profiledata == "") {
      let _obj = JSON.parse(localStorage.getItem("EditLeads"));
      //------------------------Tags---------------------------//
      let TagArr = new Array();
      let TagIndexArr = new Array();

      let ListArr = new Array();
      let ListArrIDs = new Array();

      _obj.data.tagged_contacts.map((contacts) => {
        TagIndexArr.push(contacts.tag_id);
        TagArr.push(contacts.tag_name);
      });

      _obj.data.contacts_lists.map((lists) => {
        ListArr.push(lists);
        ListArrIDs.push(lists?.list_id);
      });

      //------------------------Tags---------------------------//

      //---------------------------------------Dispaly Tags API Calling----------------------------------------//
      const PayloadFormData = new FormData();
      PayloadFormData.append("action", "get_business_card_tags");
      PayloadFormData.append("exhibitor_id", EXHIBITOR_ID);
      NewBusinessCard_API(PayloadFormData).then((res) => {
        //------------------------Existing Tags---------------------------//
        let ExistingTagArr = new Array();
        let ExistingTagIndexArr = new Array();

        res.map((contacts) => {
          ExistingTagIndexArr.push(contacts.tag_id);
          ExistingTagArr.push(contacts.tag_name);
        });
        //------------------------Existing Tags---------------------------//

        setProfileData({
          contactinfo: _obj.data,
          notes: _obj.data.notes,
          profiletags: TagArr,
          profiletags_id: TagIndexArr,
          existing_tags: ExistingTagArr,
          existing_tags_id: ExistingTagIndexArr,
          Lists: ListArr,
          ListsIDs: ListArrIDs,
        });
      });
      //---------------------------------------Dispaly Tags API Calling----------------------------------------//
    }
  }, [profiledata]);

  return (
    <>
      <div
        className="container-fluid min-vh-100 p-0"
        style={{ backgroundColor: "#5411dc", gap: "10px" }}
      >
        <div className="container-fluid ">
          <div className="d-flex justify-content-between align-items-center text-white px-3 py-3">
            <FaChevronLeft
              size={25}
              onClick={Goback_fn}
              style={{ cursor: "pointer" }}
            />
            <p className="m-0 fw-semibold fs-6">{t("contactsMenu.viewContact")}</p>
            <p className="m-0 " style={{ width: "20px" }}></p>
          </div>
          {profiledata != "" ? (
            <>
              <ProfileInfo data={profiledata} />
              <ProfileOptions data={profiledata} />
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
