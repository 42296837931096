import React, { useRef, useState } from "react";
import { Modal } from "../../../functions/Modal";
import { BsFillPencilFill } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { Stories_API } from "../../../services/userservices";
import { EditStory } from "./EditStory";
import { useForm } from "react-hook-form";
import moment from "moment";

export const ViewStory = ({ dependencies: { state, setState } }) => {
  const [editMode, setEditMode] = useState(false);
  console.log(state, "state===========");
  const [addedTags, setAddedTags] = useState([]);
  let StoryImageRef = useRef();
  let story = state?.ViewStory;
  const [editedTags, setEditedTags] = useState([...story?.tagged_users]);
  const convertDate = (dateStr) => {
    // Use moment library to parse and format the date
    const formattedDate = moment(dateStr).format("DD-MMM-YYYY");
    return formattedDate;
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      story_name: story?.name,
      story_date: story?.date_added,
    },
  });

  async function UpdateStory(data, selectedTags, story, date) {
    let finalArray = [];
    if (editedTags.length > 0) {
      for (let i of editedTags) {
        finalArray.push(i?.tag_id);
      }
    }

    let UpdateForm = new FormData();
    UpdateForm.append("action", "update_story");
    UpdateForm.append(
      "exhibitor_id",
      JSON.parse(localStorage.getItem("exhibitor_id")),
    );
    UpdateForm.append("user_id", JSON.parse(localStorage.getItem("user_id")));
    UpdateForm.append("name", data?.story_name);
    UpdateForm.append("date_added", date);

    if (story?.story_img === StoryImageRef.current.src) {
      UpdateForm.append("old_story_image", story?.story_img_name);
    } else {
      UpdateForm.append("old_story_image", null);
    }
    if (story?.story_img === StoryImageRef.current.src) {
      UpdateForm.append("new_story_image", null);
    } else {
      UpdateForm.append("new_story_image", state?.FileUploaded);
    }
    UpdateForm.append(
      "tagged_users",
      selectedTags.map((item, index) => item.tag_id),
    );
    UpdateForm.append("story", story?.story_id);

    await Stories_API(UpdateForm).then((res) => {
      setEditMode(false);
      setState({ type: "UPDATE_VIEW_MODAL_OPEN", view: false });
      setState({
        type: "UPDATE_ACTION",
        action: "update",
        id: Math.floor(Math.random() * 578543),
      });
    });
  }
  async function DeleteStory() {
    let DeleteForm = new FormData();
    DeleteForm.append("action", "delete_story");
    DeleteForm.append("story", story?.story_id);
    DeleteForm.append(
      "exhibitor_id",
      JSON.parse(localStorage.getItem("exhibitor_id")),
    );
    DeleteForm.append("user_id", JSON.parse(localStorage.getItem("user_id")));
    await Stories_API(DeleteForm).then((res) => {
      setEditMode(false);
      setState({ type: "UPDATE_VIEW_MODAL_OPEN", view: false });
      setState({
        type: "UPDATE_ACTION",
        action: "delete",
        id: Math.floor(Math.random() * 578543),
      });
    });
  }

  async function DeleteTag(tagID, storyID) {
    //     action:remove_story_tag,
    // tag:1,
    // story:1,
    let RemoveTagForm = new FormData();
    RemoveTagForm.append("action", "remove_story_tag");
    RemoveTagForm.append("tag", tagID);
    RemoveTagForm.append("story", storyID);
    await Stories_API(RemoveTagForm).then((res) => {
      setState({
        type: "UPDATE_ACTION",
        action: "update",
        id: Math.floor(Math.random() * 578543),
      });
    });
  }

  return (
    <>
      <Modal>
        {!editMode ? (
          <div
            className="d-flex flex-column px-1 pb-3 pt-0"
            style={{ gap: "10px" }}
          >
            <div
              className="d-flex justify-content-between align-items-center py-1"
              style={{ gap: "20px", position: "sticky", top: 0 }}
            >
              <BsFillPencilFill
                size={15}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditMode(true);
                }}
              />

              <IoMdClose
                size={35}
                style={{ cursor: "pointer" }}
                className="text-primary py-1"
                onClick={() => {
                  setState({ type: "UPDATE_VIEW_MODAL_OPEN", view: false });
                }}
              />
            </div>
            <p className="mb-0 text-secondary fw-semibold fs-11 ">
              {convertDate(story?.date_added)}
            </p>
            <div className="d-flex w-100 justify-content-center ">
              <img
                className="rounded-2 "
                src={story?.story_img}
                width="auto"
                height="auto"
                style={{
                  maxWidth: "100%",
                  maxHeight: "250px",
                  objectFit: "cover",
                }}
              />
            </div>
            <p className="mb-0 text-secondary fw-semibold fs-11 ">
              {story?.name}
            </p>
            {story?.tagged_users.length > 0 ? (
              <div className="d-flex flex-wrap gap-1 pt-2">
                {story?.tagged_users?.map((tag, index) => {
                  return (
                    <div
                      className="d-flex bg-primary rounded-2 justify-content-center align-items-center px-2 py-1"
                      key={index}
                    >
                      <p className=" mb-0 fs-10 fw-normal text-white">
                        {tag?.tag_name}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <button
              className="d-flex  align-items-center justify-content-center btn btn-outline-primary"
              style={{ gap: "5px" }}
              onClick={() => {
                DeleteStory();
              }}
            >
              <MdDeleteForever size={20} />
              <p className="mb-0 fw-semibold fs-10">DELETE</p>
            </button>
          </div>
        ) : (
          <EditStory
            dependencies={{
              state: state,
              setState: setState,
            }}
            editedTags={editedTags}
            addedTags={addedTags}
            setAddedTags={setAddedTags}
            setEditedTags={setEditedTags}
            UpdateStory={UpdateStory}
            StoryImageRef={StoryImageRef}
            setEditMode={setEditMode}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
          />
        )}
      </Modal>
    </>
  );
};
