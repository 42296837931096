import React from "react";
import { IoMdClose } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { FaTrash } from "react-icons/fa";
import { Modal } from "../../../functions/Modal";

const SocialProfileModal = ({
  defaultSocials,
  existingFields,
  icons,
  fields,
  handleAddField,
  handleRemoveField,
  handleInputChange,
  submit,
  setState,
}) => {
  console.log(existingFields);
  return (
    <Modal>
      <div
        className="d-flex w-100 justify-content-between align-items-center py-2 px-1 "
        style={{
          gap: "20px",
          position: "sticky",
          top: "0px",
          zIndex: 4,
        }}
      >
        <IoMdClose
          size={30}
          style={{ cursor: "pointer" }}
          className="text-secondary"
          onClick={() => {
            setState([]);
          }}
        />
        <p className="mb-0 fw-bold fst-italic fs-8 text-secondary">
          Social Profile
        </p>
        <button
          type="submit"
          className="rounded-2"
          style={{
            outline: "none",
            border: "none",
            backgroundColor: "none",
            width: "auto",
          }}
          form="edit-form"
        >
          <TiTick
            size={30}
            style={{ cursor: "pointer" }}
            className="text-primary"
            onClick={() => submit()}
          />
        </button>
      </div>
      <div className="social-link-manager">
        <div className="button-container m-2 d-flex flex-wrap">
          {defaultSocials
            .filter((icon) => !existingFields.includes(icon.name))
            .map((icon, index) => (
              <button
                key={index}
                onClick={() => handleAddField(icon)}
                className="btn btn-outline-primary m-2 d-flex flex-column justify-content-center align-items-center"
              >
                <i style={{ textAlign: "center" }}>{icons[icon.name]}</i>
                <small>{icon.label_name}</small>
              </button>
            ))}
        </div>
        <div id="optional_fields">
          {fields.map((field, index) => (
            <div key={index} className="form-group" id={`div_${field.name}`}>
              <label>
                {field.label_name}{" "}
                <small className="text-primary">{field.label_prefix}</small>
              </label>
              <table>
                <tbody>
                  <tr>
                    <td width="80%">
                      <input
                        type={field.field_type}
                        required
                        id={field.name}
                        className="form-control"
                        name={field.name}
                        value={field.value || ""}
                        placeholder={field.placeholder}
                        onChange={(event) =>
                          handleInputChange(event, field.name)
                        }
                      />
                    </td>
                    <td width="10%" style={{ padding: "10px" }}>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => handleRemoveField(field.name)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default SocialProfileModal;
