import React, { useState } from "react";
import { Modal } from "../../functions/Modal";
import BulkEmailCards from "./components/BulkEmailCards";
import BulkEmailMessage from "./components/BulkEmailMessage";
import { MdArrowBack, MdSend } from "react-icons/md";
import { BulkEmailSend } from "../../services/userservices";
import { ToastContainer, toast } from "react-toastify";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const BulkEmailModal = ({ dependencies: { bulkEmail, setBulkEmail } }) => {
  const [apiLoader, setApiLoader] = useState("none");
  const [sendBulkEmail, setSendBulkEmail] = useState({
    exhibitor_id: JSON.parse(localStorage?.getItem("exhibitor_id")),
    contact_id: [...bulkEmail?.selected_contacts],
    assets: [],
    collections: [],
    digital_cards: [],
    virtual_tours: [],
    message: "",
  });
  let TOAST_CONFIG = {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  async function sendEmail() {
    await BulkEmailSend({
      exhibitor_id: sendBulkEmail?.exhibitor_id,
      contact_id: JSON.stringify(sendBulkEmail?.contact_id),
      assets: JSON.stringify(sendBulkEmail?.assets),
      collections: JSON.stringify(sendBulkEmail?.collections),
      digital_cards: JSON.stringify(sendBulkEmail?.digital_cards),
      virtual_tours: JSON.stringify(sendBulkEmail?.virtual_tours),
      message: sendBulkEmail?.message,
    })
      .then((res) => {
        if (res.data) {
          // console.log(res?.data);
          setApiLoader("success");
        }
        setTimeout(() => {
          setApiLoader("none");
          setBulkEmail({ selected_contacts: [], isBulkEmailModalOpen: false });
        }, 1000);
      })
      .catch((err) => {
        // console.log(err);
      });
  }
  return (
    <>
      <Modal>
        <div className="d-flex w-100 align-items-center justify-content-between mt-2 mb-2">
          <button
            className="d-flex align-items-center justify-content-center btn btn-warning px-3 py-2"
            style={{ gap: "10px" }}
            onClick={(evt) => {
              evt.preventDefault();
              setBulkEmail((previousState) => ({
                ...previousState,
                isBulkEmailModalOpen: false,
              }));
            }}
          >
            <MdArrowBack size={15} />{" "}
            <p className="mb-0 fw-semibold fs-11">GO BACK</p>
          </button>
          <button
            className="d-flex align-items-center justify-content-center btn btn-primary border px-3 py-2"
            style={{ gap: "10px" }}
            onClick={(evt) => {
              evt.preventDefault();
              setApiLoader("loading");
              if (
                (sendBulkEmail?.assets.length > 0 ||
                  sendBulkEmail?.collections.length > 0 ||
                  sendBulkEmail?.digital_cards.length > 0 ||
                  sendBulkEmail?.virtual_tours.length > 0) &&
                sendBulkEmail?.message.length > 0
              ) {
                sendEmail();
              } else if (
                (sendBulkEmail?.assets.length > 0 ||
                  sendBulkEmail?.collections.length > 0 ||
                  sendBulkEmail?.digital_cards.length > 0 ||
                  sendBulkEmail?.virtual_tours.length > 0) &&
                sendBulkEmail?.message.length == 0
              ) {
                toast.warning(
                  "A Message is needed to send bulk email to all the emails mentioned",
                  TOAST_CONFIG,
                );
                setApiLoader("none");
              } else if (
                (sendBulkEmail?.assets.length == 0 ||
                  sendBulkEmail?.collections.length == 0 ||
                  sendBulkEmail?.digital_cards.length == 0 ||
                  sendBulkEmail?.virtual_tours.length == 0) &&
                sendBulkEmail?.message.length > 0
              ) {
                toast.warning(
                  "Attach atleast one type of content to send bulk email",
                  TOAST_CONFIG,
                );
                setApiLoader("none");
              } else if (
                (sendBulkEmail?.assets.length == 0 ||
                  sendBulkEmail?.collections.length == 0 ||
                  sendBulkEmail?.digital_cards.length == 0 ||
                  sendBulkEmail?.virtual_tours.length == 0) &&
                sendBulkEmail?.message.length == 0
              ) {
                toast.warning(
                  "Both message and attachment of atleast one type of content is needed to send bulk email",
                  TOAST_CONFIG,
                );
                setApiLoader("none");
              }
            }}
          >
            <p className="mb-0 fw-semibold fs-11">
              {apiLoader === "success" ? "SENT" : "SEND"}
            </p>{" "}
            {apiLoader === "none" ? <MdSend size={15} /> : null}
            {apiLoader === "loading" ? (
              <div
                class="spinner-border text-warning"
                role="status"
                style={{ width: "20px", height: "20px" }}
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : null}
            {apiLoader === "success" ? (
              <>
                <IoMdCheckmarkCircleOutline size={25} color="green" />
              </>
            ) : null}
          </button>
        </div>
        <div
          style={
            apiLoader === "loading" || apiLoader === "success"
              ? { opacity: 0.5, pointerEvents: "none" }
              : null
          }
        >
          <BulkEmailCards
            dependencies={{
              sendBulkEmail: sendBulkEmail,
              setSendBulkEmail: setSendBulkEmail,
            }}
          />

          <BulkEmailMessage
            dependencies={{
              sendBulkEmail: sendBulkEmail,
              setSendBulkEmail: setSendBulkEmail,
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default BulkEmailModal;
