
import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import { BsPersonFill, BsCheck } from 'react-icons/bs';
import { AiOutlineCalendar } from 'react-icons/ai';
import { MdLocationOn } from 'react-icons/md';
import { IoMdTime } from 'react-icons/io';
import { FaMailBulk, FaRegStickyNote } from 'react-icons/fa';
import { FaChevronLeft, FaPencilAlt, FaPlus } from "react-icons/fa";
import { Menu } from "../functions/menu";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarGroup } from '@mui/material';
import { BiCopy, BiDownload, BiShareAlt } from "react-icons/bi";
import MuiAutoComplete from "./MuiAutoComplete";

const ShareCalander = () => {
    const [image, setImage] = useState("https://via.placeholder.com/50");
    const navigate = useNavigate();

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
    };

    return (
        <div className="container-fluid d-flex flex-column justify-content-between" style={{ minHeight: "100vh" }}>
            <div className="d-flex justify-content-between align-items-center bg-primary text-white p-3">
                <FaChevronLeft size={25} onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
                <p className="m-0 fw-bold fs-4">Share Calander</p>
                <p></p>
            </div>

            <Form className="p-4">
                <Form.Group controlId="formBasicWho" className="mb-3">
                    <div className="d-flex align-items-center">
                        {/* <AvatarGroup total={24}>
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                            <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                            <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                        </AvatarGroup> */}
                        <span className="ms-2 text-primary">Contacts shared the calander</span>
                    </div>
                    <MuiAutoComplete/>
                </Form.Group>

                <div className="p-3 d-flex flex-column justify-content-center align-items-center mb-5">
              <img
                src="https://cdn.pixabay.com/photo/2013/07/12/14/45/qr-code-148732_1280.png"
                alt="QR Code"
                className="img-fluid rounded mb-3"
                style={{ width: "150px", height: "150px" }}
              />
              <div class="input-group mb-3">
  <input type="text" disabled class="form-control" placeholder="https://cdn.pixabay.com/photo/2013/07/12/14/45/qr-code-148732_1280" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <div class="input-group-append">
    <button class="btn btn-primary" type="button">copy</button>
  </div>
</div>
              <div>
             
                <button className="btn btn-primary me-2">
                  <FaMailBulk />{" "}
                </button>
                <button className="btn btn-primary  me-2">
                  <BiShareAlt />{" "}
                </button>
                <button className="btn btn-primary">
                  <BiDownload />{" "}
                </button>
              </div>
            </div>

                
            </Form>

            <div>
                <Menu />
            </div>
        </div>
    );
};

export default ShareCalander;
